import React from "react";
import styled, { css } from "styled-components";

export interface PbTextProps {
    fontSize?: number;
    bold?: boolean;
    underline?: boolean;
}

const PbTextStyled = styled.div<PbTextProps>`
    font-size: ${(props) => props.fontSize}rem;
    padding-left: 0.2rem;

    ${(props) =>
        props.bold &&
        css`
            font-weight: bold;
        `}
`;

const PbText: React.SFC<PbTextProps> = ({
    children,
    bold,
    fontSize = 1.4,
    underline,
}) => {
    return (
        <PbTextStyled bold={bold} fontSize={fontSize} underline={underline}>
            {children}
        </PbTextStyled>
    );
};

export default PbText;
