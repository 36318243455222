import { put, takeEvery, call, select } from "redux-saga/effects";
import { default as messageAPI } from "../../api/message";
import {
    encodeQuery,
    encodeObject,
    objectKeys,
    hasOwnProperty,
    isEmpty
} from "../../lib/utils";

import {
    messageCleanup,
    fetchMessageAsync,
    fetchMessageViewAsync,
    fetchMessageSendAsync,
    fetchMessageCancelAsync,
    fetchMessageSearchAsync,
    fetchMessagePersnalAsync,
    fetchMessageReceiverAsync,
    fetchMessageUpdateLockrAsync
} from "../message";

import { SendType } from "../../lib/staticValue";
import { StringBool } from "../../lib/types";
import timeflow from "../../lib/dr.strange";
import { userCleanup } from "../user";
import { history } from "..";
import { rateCleanup } from "../rate";
import { alertToggle } from "../base";

function* fetchMessage() {
    const state = yield select();

    try {
        let filters: { [k: string]: string } = {};
        const school_id = state.school.myschool.id;
        const { page, limit, filter } = state.message;

        const obj = encodeObject(filter);

        objectKeys(obj).forEach((key) => {
            if (hasOwnProperty(filter[key], "value")) {
                // has value key
                filters[key] = filter[key].value;
            } else {
                // just value
                filters[key] = filter[key];
            }
        });

        const response = yield call(
            messageAPI.getMessage,
            encodeQuery({
                school_id,
                page,
                limit,
                ...filters
            })
        );

        yield put(fetchMessageAsync.success(response));
    } catch (error) {
        yield put(fetchMessageAsync.failure(error.message));
    }
}

function* fetchMessageView(
    action: ReturnType<typeof fetchMessageViewAsync.request>
) {
    const param = encodeQuery({ message_request_id: action.payload });

    try {
        const reponse = yield call(messageAPI.getMessageView, param);
        yield put(fetchMessageViewAsync.success(reponse));
    } catch (error) {
        yield put(fetchMessageViewAsync.failure(error.message));
    }
}

function* fetchMessageSearch(
    action: ReturnType<typeof fetchMessageSearchAsync.request>
) {
    const state = yield select();

    try {
        const { spage, slimit } = state.message;
        const school_id = state.school.myschool.id;

        const response = yield call(
            messageAPI.getMessage,
            encodeQuery({
                school_id,
                page: spage,
                limit: slimit,
                request_user: action.payload
            })
        );

        yield put(fetchMessageSearchAsync.success(response));
    } catch (error) {
        yield put(fetchMessageSearchAsync.failure(error.message));
    }
}

function* fetchMessageSend(
    action: ReturnType<typeof fetchMessageSendAsync.request>
) {
    const state = yield select();
    const message_type = 1; // 1 is message
    const body = new FormData();
    const attach_files: number[] = [];

    let files = state.message.files;
    let { sender } = state.school;
    const { data } = action.payload;

    try {
        // attach_file id
        for (let i in files) {
            body.append("file", files[i]);
            const response = yield call(messageAPI.getFileRequestId, body);
            attach_files.push(response.data.id as number);
            body.delete("file");
        }

        const {
            approval_user,
            send_reserve,
            message,
            byte,
            approval,
            receivers,
            sendType,
            send_date,
            is_lock
        } = data;

        const { id: school_id, tel } = state.school.myschool;

        sender = isEmpty(sender) ? tel : sender.tel;

        yield call(messageAPI.sendMessage, {
            approval: approval.toString() as StringBool,
            approval_user,
            attach_files: JSON.stringify(attach_files) as string,
            byte,
            files: JSON.stringify([]) as string,
            message,
            message_type,
            receiver: JSON.stringify(receivers) as string,
            school_id,
            send_date: send_date,
            send_reserve: send_reserve.toString() as StringBool,
            send_type: sendType.toString() as SendType,
            sender,
            is_lock: is_lock.toString() as StringBool
        });

        yield put(fetchMessageSendAsync.success());
        yield put(userCleanup("after-send"));
        yield put(messageCleanup("messages"));

        yield put(
            alertToggle({
                type: "SUCCESS",
                content: "메시지가 정상적으로 발송되었습니다."
            })
        );

        setTimeout(() => {
            history.push("/message");
        }, 300);
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message
            })
        );
        yield put(fetchMessageSendAsync.failure(error.message));
    }
}

function* fetchMessageCancel(
    action: ReturnType<typeof fetchMessageCancelAsync.request>
) {
    const message_id = action.payload;
    try {
        yield call(messageAPI.cancelMessage, { message_id });
        yield put(fetchMessageCancelAsync.success(message_id));
        yield put(
            alertToggle({
                type: "SUCCESS",
                content: "메시지 취소가 완료되었습니다."
            })
        );
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message
            })
        );

        yield put(fetchMessageCancelAsync.failure(error.message));
    }
}

function* fetchPersnalMessage(
    action: ReturnType<typeof fetchMessagePersnalAsync.request>
) {
    const state = yield select();
    const message_type = 4; // 1 is message
    const { data } = action.payload;
    try {
        const { id: school_id, tel: sender } = state.school.myschool;
        const { approval, approval_user, receivers } = data;

        yield (call as any)(messageAPI.sendPersnalMessage, {
            approval: approval.toString(),
            approval_user,
            attach_files: "[]",
            message_type,
            receiver: JSON.stringify(receivers),
            send_date: timeflow().format("yyyy-MM-DD H:i:s"),
            send_reserve: "0",
            send_type: "0",
            school_id,
            sender
        });

        yield put(fetchMessagePersnalAsync.success());

        // insert to message
        yield put(rateCleanup("insert"));
        yield put(
            alertToggle({
                type: "SUCCESS",
                content: "메시지가 정상적으로 발송되었습니다."
            })
        );
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message
            })
        );
        yield put(fetchMessagePersnalAsync.failure(error.message));
    }
}

function* fetchMessageReceiver(
    action: ReturnType<typeof fetchMessageReceiverAsync.request>
) {
    const state = yield select();

    try {
        const { id: school_id } = state.school.myschool;

        const { data } = yield call(
            messageAPI.getReceiver,
            encodeQuery({
                school_id,
                search_word: action.payload
            })
        );

        yield put(fetchMessageReceiverAsync.success(data));
    } catch (error) {
        yield put(fetchMessageReceiverAsync.failure(error.message));
    }
}

function* fetchMessageUpdateLock(
    action: ReturnType<typeof fetchMessageUpdateLockrAsync.request>
) {
    try {
        const data = action.payload;
        yield call(messageAPI.putMessageLock, data);
        yield put(
            fetchMessageUpdateLockrAsync.success({
                mid: data.message_request_id,
                lock: data.is_lock === "0" ? 0 : 1
            })
        );
        yield put(
            alertToggle({
                type: "SUCCESS",
                content: `보안설정이 ${
                    data.is_lock === "1" ? "설정" : "해지"
                } 되었습니다.`
            })
        );
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message
            })
        );
        yield put(fetchMessageUpdateLockrAsync.failure(error.message));
    }
}

export default [
    takeEvery(fetchMessageAsync.request, fetchMessage),
    takeEvery(fetchMessageViewAsync.request, fetchMessageView),
    takeEvery(fetchMessageSendAsync.request, fetchMessageSend),
    takeEvery(fetchMessageCancelAsync.request, fetchMessageCancel),
    takeEvery(fetchMessageSearchAsync.request, fetchMessageSearch),
    takeEvery(fetchMessagePersnalAsync.request, fetchPersnalMessage),
    takeEvery(fetchMessageReceiverAsync.request, fetchMessageReceiver),
    takeEvery(fetchMessageUpdateLockrAsync.request, fetchMessageUpdateLock)
];
