import fetch from "./configure";

export default {
    getMySchool: (session: string) =>
        fetch.get("/school/myschools", {
            headers: { "x-session-manager": session },
        }),

    /**
     * ! 더이상 사용하지 않음
     */
    getClass: (params: string) => fetch.get(`/class/message_class?${params}`),

    // 학교 전체 전화번호
    getSchoolTels: (params: string) => fetch.get(`/school/tel?${params}`),

    // 학급정보
    getClassNew: (params: string) => fetch.get(`class/class?${params}`),

    getClassGroup: (params: string) =>
        fetch.get(`/student/message_students_type?${params}`),

    /**
     * [GET] 받는 분 기초 설정 값
     * @param school_id number
     */
    getReceiverDefaultConfig: (params: string) =>
        fetch.get(`/school/rating_set?${params}`),
};
