import React, { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

import FaIcon, { FaIconProps } from "../common/FaIcon";
import { GlobalSize } from "../style/theme";
import { InputKeyupEvent, InputChangeEvent } from "../lib/types";

export type InputIconType = {
    align: "left" | "right";
    onClick?: () => void;
} & FaIconProps;

export interface PbInputProps extends InputHTMLAttributes<HTMLInputElement> {
    defaultValue?: string | number | string[] | undefined;
    value?: string | number | string[] | undefined;
    placeholder?: string;
    disabeld?: boolean;
    name?: string;
    readOnly?: boolean;
    style?: any;
    type?: string;
    forwardRef?: any;
    onlyExp?: RegExp | string;
    onKeyDown?: (ev: InputKeyupEvent & InputChangeEvent) => void;
    onKeyPress?: (ev: InputKeyupEvent & InputChangeEvent) => void;
    onKeyUp?: (ev: InputKeyupEvent & InputChangeEvent) => void;
    onEnter?: (ev: InputKeyupEvent) => void;
}

export interface PbInputStyleProps {
    bulk?: GlobalSize;
    mode?: "select" | undefined;
    width?: number;
    icon?: InputIconType;
    ghost?: boolean;
}

export interface PbInputBlockProps {
    bulk?: GlobalSize;
    icon?: InputIconType;
}

const PbInputBlock = styled.div<PbInputBlockProps>`
    display: block;
    position: relative;
    box-sizing: content-box;
    width: 100%;
    z-index: 9;

    i {
        transition: all 0.35s;
        display: block;
    }

    .input-icon {
        position: absolute;
        top: 0;
        font-size: 1.6rem;
        color: ${(props) => props.theme.color["skyblue-1"]};
        z-index: 99;

        ${(props) => {
            switch (props.bulk) {
                case "small":
                    return css`
                        width: ${props.theme.inputSize.small};
                        height: ${props.theme.inputSize.small};
                        line-height: ${props.theme.inputSize.small};
                    `;
                case "large":
                    return css`
                        width: ${props.theme.inputSize.large};
                        height: ${props.theme.inputSize.large};
                        line-height: ${props.theme.inputSize.large};
                    `;
                default:
                    return css`
                        width: ${props.theme.inputSize.default};
                        height: ${props.theme.inputSize.default};
                        line-height: ${props.theme.inputSize.default};
                    `;
            }
        }}

        ${(props) =>
            props.icon && props.icon.align === "left"
                ? css`
                      left: 0;
                  `
                : css`
                      right: 0;
                  `}
        ${(props) => props.theme.withFlex("center", "center")};
    }

    .select-input {
        font-size: 1.4rem;
        color: #262626;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(50%, -50%);
    }

    input.pb-input {
        height: ${(props) => props.theme.inputSizeFactory(props.bulk)};

        ${(props) => {
            switch (props.bulk) {
                case "small":
                    return css`
                        font-size: 1.3rem;
                        padding: 0 12px;
                    `;
                case "large":
                    return css`
                        font-size: 1.6rem;
                        padding: 0 16px;
                    `;
                default:
                    return css`
                        font-size: 1.35rem;
                        padding: 0 16px;
                    `;
            }
        }}

        ${(props) =>
            props.icon && props.icon.align === "left"
                ? css`
                      padding-left: ${props.theme.inputSizeFactory(props.bulk)};
                  `
                : css`
                      padding-right: ${props.theme.inputSizeFactory(
                          props.bulk
                      )};
                  `}
    }
`;

const PbInputStyled = styled.input<PbInputStyleProps>`
    display: inline-block;
    width: 100%;
    outline: none;

    // ios input shadow remove
    -webkit-appearance: none;

    &::placeholder {
        color: ${(props) => props.theme.color["skyblue-1"]};
    }

    &:focus {
        border-color: ${(props) => props.theme.color.primary};
    }

    &:disabled {
        ${(props) => props.theme.disabled}
    }

    ${(props) =>
        !props.ghost
            ? css`
                  border: 1px solid #dcdfec;
                  border-radius: 5px;
                  box-shadow: inset 0 0 0 #fff;
              `
            : css`
                  background-color: transparent;
                  border: none;
                  border-bottom: 1px solid ${(props) => props.theme.color.line};

                  // ios border-radius remove
                  -webkit-border-radius: 0;
              `}
`;

const PbInput: React.SFC<PbInputProps & PbInputStyleProps> = ({
    defaultValue = "",
    id,
    value,
    bulk = "default",
    name,
    ghost,
    placeholder,
    readOnly = false,
    style,
    type = "text",
    width,
    forwardRef,
    onlyExp,
    mode,
    icon,
    disabeld,
    className,
    onEnter,
    onKeyUp,
    onKeyPress,
    onChange = () => {},
    ...events
}) => {
    const changeHandler = (ev: InputChangeEvent) => {
        if (onlyExp) {
            // 정규식 input
            const result = new RegExp(onlyExp, "g").test(ev.target.value);
            if (!ev.target.value) return onChange(ev);
            if (result) return onChange(ev);
        } else {
            if (onChange) return onChange(ev);
        }
    };

    const keyHandler = (ev: InputKeyupEvent) => {
        // press enter for Keyboard Event
        if (onEnter && ev.which === 13) return onEnter(ev);
    };

    return (
        <PbInputBlock className="pb-input-block" icon={icon} bulk={bulk}>
            <PbInputStyled
                className="pb-input"
                id={id}
                type={type}
                value={value || defaultValue}
                style={style}
                name={name}
                ref={forwardRef}
                placeholder={placeholder}
                width={width}
                mode={mode}
                disabled={disabeld}
                readOnly={readOnly}
                icon={icon}
                ghost={ghost}
                onKeyUp={onKeyUp}
                onKeyPress={onKeyPress || keyHandler}
                onChange={changeHandler}
                onMouseDown={events.onMouseDown && events.onMouseDown}
                {...events}
            />

            {icon && (
                <div
                    className="input-icon"
                    onTouchStart={icon.onTouchStart && icon.onTouchStart}
                    onMouseDown={icon.onMouseDown && icon.onMouseDown}
                    onClick={icon.onClick && icon.onClick}
                >
                    <FaIcon prefix={icon.prefix} name={icon.name} />
                </div>
            )}
        </PbInputBlock>
    );
};

export default React.forwardRef(
    (props: PbInputProps & PbInputStyleProps, ref) => (
        <PbInput {...props} forwardRef={ref} />
    )
);
