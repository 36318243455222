import React, {
    TextareaHTMLAttributes,
    ChangeEvent,
    KeyboardEvent,
    useCallback
} from "react";
import styled, { css } from "styled-components";

type EventCompose = KeyboardEvent<HTMLTextAreaElement> &
    ChangeEvent<HTMLTextAreaElement>;

export interface PbTextareaProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    onChange?: (ev: EventCompose) => void;
    onKeyUp?: (ev: EventCompose) => void;
}

export interface PbTextareaStyleProps {
    maxHeight?: string;
    minHeight?: string;
    border?: boolean;
}

const PbTextareaStyled = styled.textarea.attrs({
    className: "pb-textarea"
})<PbTextareaStyleProps>`
    width: 100%;
    resize: none;
    overflow-y: auto;
    outline: none;
    font-size: 1.4rem;
    padding: 12px 8px;
    border: none;

    &::placeholder {
        color: ${props => props.theme.color["skyblue-1"]};
    }

    ${props =>
        props.minHeight &&
        css`
            height: ${props.minHeight};
        `}

    ${props =>
        props.maxHeight &&
        css`
            height: ${props.maxHeight};
        `}

    ${props =>
        props.border &&
        css`
            border: 1px solid #dcdfec;

            &:focus {
                border-color: ${props => props.theme.color.primary};
            }
        `}
`;

const PbTextarea: React.SFC<PbTextareaProps & PbTextareaStyleProps> = ({
    children,
    defaultValue,
    value,
    className,
    minHeight,
    maxHeight,
    border,
    onChange,
    onKeyUp,
    ...events
}) => {
    const changeHandler = useCallback(
        (ev: EventCompose) => {
            const event = onChange || onKeyUp;

            if (event) {
                event(ev);
            }
        },
        [onChange, onKeyUp]
    );

    return (
        <PbTextareaStyled
            defaultValue={defaultValue}
            value={value}
            border={border}
            minHeight={minHeight}
            maxHeight={maxHeight}
            onChange={changeHandler}
            {...events}
        ></PbTextareaStyled>
    );
};

export default PbTextarea;
