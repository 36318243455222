import {
    SendType,
    SendPhoneType,
    sendRequestStatusType,
    SEND_PHONE_TYPE,
    ReceiverTypeKey,
} from "./staticValue";
import { FReceiverType } from "../modules/user";
import { ReceiverSearchType } from "../modules/message";
import { SchoolType } from "../modules/school";

export const isEmpty = (value: any): boolean =>
    value === undefined || value === "" || isNull(value) || value === "null";

export const isZero = (value: number): boolean => value === 0;

export const isNull = (value: any): boolean =>
    typeof value === "object" && value === null;

export const isString = (value: any): boolean =>
    Object.prototype.toString.call(value) === "[object String]";
export const isNumber = (value: any): boolean =>
    Object.prototype.toString.call(value) === "[object Number]";
export const isBoolean = (value: any): boolean =>
    Object.prototype.toString.call(value) === "[object Boolean]";
export const isArray = (value: any): boolean =>
    Object.prototype.toString.call(value) === "[object Array]";

export const hasOwnProperty = (obj: object, value: string): boolean => {
    if (isEmpty(obj)) {
        return false;
    }

    return Object.prototype.hasOwnProperty.call(obj, value);
};

export const objectKeys = (obj: object): string[] => Object.keys(obj);
export const objectValues = <T = any>(obj: object): T[] => Object.values(obj);
export const objectKeyHeadToUppercase = (
    keys: string[],
    values: any[]
): { [key in typeof keys[0]]: string } => {
    let result = {};
    keys.forEach((key, i) => {
        const upKey = toUpperCaseHeader(key);
        result = { ...result, [upKey]: values[i] };
    });

    return result;
};

export const toUpperCaseHeader = (value: string): string => {
    const part = value.split("");
    part[0] = part[0].toUpperCase();
    return part.join("");
};

export const getLocationQuery = (name: string, url?: string): string => {
    if (!url) url = window.location.href;

    name = name.replace(/[\[\]]/g, "\\$&");

    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);

    if (!results) return "";
    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * @method threeComma
 * @param value
 */

export const threeComma = (value: string | number): string =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * @method encodeQuery
 * @param obj
 */
export const encodeQuery = (obj: object): string => {
    let query = "";
    const pure = encodeObject(obj);
    const length = Object.keys(pure).length - 1;

    Object.keys(pure).forEach((key, index) => {
        let param = encodeURIComponent(pure[key]);

        if (length === index) {
            query += `${key}=${param}`;
        } else {
            query += `${key}=${param}&`;
        }
    });

    return query;
};

/**
 * @method encodeObject
 * @param ogj
 *
 * ex) encodeObject<>() || encodeObject()
 */
export const encodeObject = <T = any>(obj: { [k: string]: T }) => {
    const not_include_null: typeof obj = {};

    Object.keys(obj).forEach((key) => {
        if (!isEmpty(obj[key])) {
            not_include_null[key] = obj[key];
        }
    });

    return not_include_null;
};

/**
 * @method otv2Array (object true value to array)
 * @param obj
 */
export const otv2Array = (obj: { [k: string]: any }): string[] =>
    objectKeys(obj).filter((v) => obj[v]);

export const obj2Cls = (value: any, hard: string): string => {
    let str = isEmpty(hard) ? " " : `${hard} `;

    for (let key in value) {
        str += value[key] ? `${key} ` : " ";
    }

    return str;
};

export type AgentEventType = "touchend" | "click";
export const agentEvent = (event: AgentEventType): AgentEventType =>
    /(mobile)/g.test(window.navigator.userAgent.toLocaleLowerCase())
        ? "touchend"
        : "click";

export type Platform = "android" | "ios" | "iphone" | "ipad" | "macintosh";
export const flatform: Array<Platform> = [
    "android",
    "ios",
    "iphone",
    "ipad",
    "macintosh",
];
export const whichFlatform = (): Platform => {
    let agent = window.navigator.userAgent.toLocaleLowerCase();
    let index = flatform.findIndex((v) => {
        let pattern = new RegExp(`(${v})`, "g");
        return pattern.test(agent);
    });

    if (flatform[index] === "android") {
        return "android";
    } else {
        return "ios";
    }
};

export const sleep = (wait: number): Promise<boolean> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, wait);
    });
};

export const getByteLength = (s: string | number | string[]): number => {
    let len = 0;
    let str = s.toString();

    for (let i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length === 6) {
            len++;
        }
        len++;
    }

    return len;
};

/**
 * @method loopFilter
 * @param og
 * @param find
 *
 * ex) loopFilter<{ key: value}, "key">(Array, Object include key of 'key')
 */
export const loopFilter = <T, K extends keyof T>(
    og: Array<T>,
    find: { [k in K]: any }
): Array<T> => {
    let origin = [...og];

    for (let key in find) {
        let compare = find[key];
        origin = origin.filter((value) => value[key] === compare);
    }

    return origin;
};

export function debounce(func: Function, wait: number) {
    let timer: any = null;
    return function () {
        // return function argument
        let args = arguments;
        if (timer !== null) clearTimeout(timer);

        timer = setTimeout(() => {
            func.apply(debounce, args);
        }, wait);
    };
}

export const phonemat = (number: string | number) => {
    if (isEmpty(number)) {
        return null;
    }

    let num = number.toString();

    if (/^(02)/.test(num)) {
        return num.replace(/(\d{2})(\d{3,4})(\d{4})/g, "$1-$2-$3");
    } else {
        return num.replace(/(\d{3})(\d{3,4})(\d{4})/g, "$1-$2-$3");
    }
};

export const pathMatch = (path: string, fullPath: string): boolean => {
    const reg = new RegExp(`\/${path}\/?`, "gi");
    return reg.test(fullPath);
};

export type ExtractTypeForByteReturn = {
    type: SendPhoneType;
    number: string;
    student_id?: number;
    name?: string;
    receiver_type?: ReceiverTypeKey;
};

// byte 에 따른 SMS, LMS 자동전환 (APP 무시)
export const extractTypeForByte = (
    items: Array<FReceiverType | ReceiverSearchType>,
    sendType: SendType,
    byte: number
) => {
    return items.map(
        ({
            id,
            phone,
            app,
            name,
            receiver_type,
        }): ExtractTypeForByteReturn | null => {
            let result: ExtractTypeForByteReturn = {
                type: "APP",
                number: "",
                student_id: id,
                name,
                receiver_type,
            };

            result.type =
                byte > 90
                    ? sendType === "2"
                        ? "LMS"
                        : app
                        ? "APP"
                        : "LMS"
                    : sendType === "2"
                    ? "SMS"
                    : app
                    ? "APP"
                    : "SMS";
            result.number = phone;

            return result;
        }
    );
};

export const isApproval = (
    status: number | null,
    sendStatus: number,
    option?: boolean
) => {
    if (!isEmpty(option)) {
        return option
            ? status > 0 || isEmpty(status)
                ? sendRequestStatusType[sendStatus + 2]
                : status === 0
                ? "결재 대기중"
                : "취소"
            : sendRequestStatusType[sendStatus + 2];
    } else {
        return status > 0 || isEmpty(status)
            ? sendRequestStatusType[sendStatus + 2]
            : status === 0
            ? "결재 대기중"
            : "취소";
    }
};

export const uniqueKey = (): string => {
    let keyMap = ["", "", ""];

    let uid = keyMap.map(() =>
        (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    );

    return uid.join("-");
};

// key 에 해당하는 최근 값 1개만 가져온다. array = ArrayObject
export const unique = (arr: [] = [], key: string) =>
    arr.filter((f, i) => arr.findIndex((s) => f[key] === s[key]) === i);

// array object에 있는 값중 중복되는 값을 제거한다.
export const onlyKey = <T, K extends keyof T>(arr: Array<T>, key: K) => {
    let temp: Array<any> = [];

    arr.forEach((item) => {
        if (temp.findIndex((t) => t === item[key]) === -1) {
            temp.push(item[key]);
        }
    });

    return temp;
};

// array object에 있는 값 중 1개 이상의 key의 중복된 value를 제거한다.
export type OnlyKeyV2ReturnType<T extends string> = Array<{ [k in T]: any }>;
export const onlyKeyV2 = <T, K extends keyof T & string>(
    arr: Array<T>,
    key: Array<K>
): OnlyKeyV2ReturnType<K> => {
    let temp: OnlyKeyV2ReturnType<K> = [];

    arr.forEach((item) => {
        let compare: any = {};
        key.forEach((k, i) => {
            compare[k] = item[k];
        });

        // compate item to compare
        if (
            temp.findIndex(
                (item) => JSON.stringify(item) === JSON.stringify(compare)
            ) === -1
        ) {
            temp.push(compare);
        }
    });

    return temp;
};

export const jsonEqual = (target: any, compare: any): boolean =>
    JSON.stringify(target) === JSON.stringify(compare);

export type LimitSize = "kb" | "mb" | "gb" | null | undefined;
export const shortFileSize = (
    size: number,
    limit: LimitSize = "kb"
): number => {
    let short: number = size;

    if (limit === "kb") {
        short = Math.ceil(short / 1024);
    }

    if (limit === "mb") {
        short = Math.ceil(short / 1024);
    }

    if (limit === "gb") {
        short = Math.ceil(short / 1024);
    }

    return short;
};

/**
 * @method rmHyper
 * @param phone
 * @description Remove hyper(-) in phone string
 */
export const rmHyper = (phone: string) => phone.replace(/\-/g, "");

/**
 * @method reform
 * @param receivers
 * @param sendType
 * @param byte
 */
export const reform = (
    receivers: Array<FReceiverType | ReceiverSearchType>,
    sendType: SendType,
    byte: number
): Array<ExtractTypeForByteReturn> => {
    let newReceivers: Array<ExtractTypeForByteReturn> = [];

    receivers.forEach((receiver) => {
        let _receiver = hasOwnProperty(receiver, "members")
            ? receiver.members
            : [receiver];

        let __receiver = _receiver.map(({ phone, ...rest }) => ({
            ...rest,
            phone: rmHyper(phone),
        }));

        let result = extractTypeForByte(__receiver, sendType, byte);

        newReceivers = [...newReceivers, ...result];
    });

    if (sendType === "1") {
        newReceivers = newReceivers.filter(({ type }) => type === "APP");
    }

    return newReceivers;
};

/**
 * @method rmOverlapNumber
 * @param arr
 * @param key keyof T
 */
export const rmOverlapNumber = <
    T extends { number?: string; phone?: string } = any
>(
    arr: Array<T>
): { unique: Array<T>; overlap_count: number } => {
    let overlap_count: number = 0;
    let unique: Array<T> = [];

    arr.forEach((item) => {
        let [type, value] = hasOwnProperty(item, "number")
            ? ["number", item.number]
            : ["phone", item.phone];

        let index = unique.findIndex(
            (uniq: { [k in typeof type]: string }) => uniq[type] === value
        );

        if (index === -1) {
            unique.push(item);
        } else {
            overlap_count++;
        }
    });

    return {
        unique,
        overlap_count,
    };
};

/**
 * @method typeCounting
 * @type TypeCounting
 * @param datas:Array<ExtractTypeForByteReturn>
 */
export type TypeCounting = { [k in SendPhoneType]: number };
export const typeCounting = (
    datas: Array<ExtractTypeForByteReturn>
): TypeCounting & { TOTAL: number } => {
    const typeObject: TypeCounting = {
        APP: 0,
        SMS: 0,
        LMS: 0,
    };

    SEND_PHONE_TYPE.forEach((t) => {
        typeObject[t] = datas.filter(({ type }) => type === t).length;
    });

    return {
        ...typeObject,
        TOTAL: datas.length,
    };
};

/**
 * @method pureTypeCounting
 * @type TypeCounting
 * @param datas:[]
 */
export const pureTypeCounting = <
    T extends { type: SendPhoneType; sms_type: "SMS" | "LMS" } = any
>(
    datas: T[]
) => {
    const typeObject: TypeCounting = {
        APP: 0,
        SMS: 0,
        LMS: 0,
    };

    SEND_PHONE_TYPE.forEach((t) => {
        typeObject[t] = datas.filter(({ type }) => type === t).length;
    });

    return {
        ...typeObject,
        TOTAL: datas.length,
    };
};

// scroll stop
export const disableScroll = (id?: string) => {
    // Get the current page scroll position

    const scrollElement: HTMLDivElement | any = isEmpty(id)
        ? window
        : document.querySelector(id);

    let scrollTop = window.pageYOffset || scrollElement.scrollTop;
    // if any scroll is attempted,
    // set this to the previous value
    scrollElement.onscroll = function () {
        scrollElement.scrollTo(0, scrollTop);
    };
};

// scroll run
export const enableScroll = (id?: string) => {
    const scrollElement: HTMLDivElement | any = isEmpty(id)
        ? window
        : document.querySelector(id);

    let scrollTop = window.pageYOffset || scrollElement.scrollTop;

    scrollElement.onscroll = function () {};
    setTimeout(() => {
        scrollElement.scrollTo(0, scrollTop);
    }, 0);
};

export const returnAllowSchool = (schools: Array<SchoolType>): number =>
    schools
        .map(
            (item: SchoolType) =>
                hasOwnProperty(item, "permission") && item.permission > 0
        )
        .findIndex((bool: boolean) => bool === true);
