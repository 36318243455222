import {
    createReducer,
    createAsyncAction,
    ActionType,
    createAction
} from "typesafe-actions";

import { NumBool } from "../lib/types";
import {
    hasOwnProperty,
    loopFilter,
    encodeObject,
    isZero,
    ExtractTypeForByteReturn,
    uniqueKey,
    phonemat
} from "../lib/utils";
import { ReceiverTypeKey, SendType } from "../lib/staticValue";
import produce from "immer";
import { PersnalType } from "../api/message";
import { FReceiverType, ShareType } from "./user";

export type MessageReducerState = {
    date_start: string | null;
    message_type: string | null;
    approval_status: string | null;
    send_status: string | null;
};

export type MessageReducerAction =
    | { type: "SET_DATE_START"; payload: any }
    | { type: "SET_MESSAGE_TYPE"; payload: any }
    | { type: "SET_APPROVAL_STATUS"; payload: any }
    | { type: "SET_SEND_STATUS"; payload: any }
    | { type: "reset" }
    | { type: "init"; payload: any };

export type SendStatus = -2 | -1 | 0 | 1;

export type MessageItemType = {
    split_date?: string;
    approval_status?: number;
    created_at: string;
    message: string;
    message_read_count: number;
    message_request_id: number;
    message_send_count: number;
    message_type: 0 | 1 | 2 | 3;
    name: string;
    request_user: number;
    send_date: string;
    send_status: number;
    send_type: number;
    is_lock: 0 | 1;
};

export type MessageViewType = {
    created_at: Date;
    id: number;
    message_content: string;
    message_request_id: number;
    phone: string | number;
    read_status: NumBool;
    receiver_type: string;
    result_id?: string;
    school_id: number;
    send_date?: string;
    send_status: SendStatus;
    student_class: string;
    student_grade: number;
    student_name: string;
    student_number: number;
    type: string;
    updated_at: Date;
};

export type MessageSimpleType = {
    sendType: string;
    phone: string | number;
    read: NumBool;
    status: SendStatus;
    student_class: string;
    student_grade: number;
    student_name: string;
    student_number: number;
    receiver_type: string;
};

export type MessageViewCoreType = {
    app: number;
    request_user: number;
    approval_name: null;
    approval_position: null;
    approval_status: number;
    approval_user: number;
    created_at: string;
    fail: number;
    lms: number;
    message: string;
    message_request_id: number;
    message_type: number;
    name: string;
    new_survey: number;
    not_read: number;
    not_read_app: number;
    old_survey: number;
    read: number;
    reserved_date?: string;
    send_date?: string;
    send_number: string | number;
    send_status: number;
    send_type: number;
    sms: number;
    success: number;
    total: number;
    wating: number;
    is_lock: 0 | 1;
};

export type MessageSendPrototype = {
    receivers: Array<ExtractTypeForByteReturn>;
    message: string;
    sendType: SendType;
    byte: number;
    approval: 0 | 1;
    approval_user: string | number;
    send_reserve: 0 | 1;
    send_date: string | Date;
    is_lock: 0 | 1;
};

export type MessagePersnalPrototype = {
    receivers: Array<PersnalType>;
    approval: 0 | 1;
    approval_user: string | number;
};

export type ReceiverSearchType = {
    id?: number;
    name: string;
    type?: ShareType;
    phone?: string;
    grade?: number;
    class_name?: string;
    number?: number;
    app?: boolean;
    receiver_type?: ReceiverTypeKey;

    // for group
    members?: Array<FReceiverType>;
    count?: number[];
    checked?: boolean;
    uid?: string;
    group_name?: string;
    chipType?: 0 | 1 | 2 | 3 | 4;
    is_shared?: boolean;
};

export type MessageFile = {
    filename: string;
    id: number;
};

export type MessageLock = {
    message_request_id: number;
    is_lock: "0" | "1"; //  unlock | lock
};

export type Filter =
    | "message_type"
    | "send_status"
    | "search_word"
    | "approval_status"
    | "date_start"
    | "date_end";

// message filter change type
export type MFCType = { name: Filter; value: any };
// message clean type
export type MCLType =
    | "all"
    | "messages"
    | "view"
    | "write"
    | "search"
    | "newSearch"
    | "filter";

export const SEARCH_TOGGLE = "message/SEARCH_TOGGLE";
export const MESSAGE_CLEANUP = "message/MESSAGE_CLEANUP";
export const FILTER_RECEIVERS = "message/FILTER_RECEIVERS";
export const READY_FILE = "message/READY_FILE";
export const DELETE_READY_FILE = "message/DELETE_READY_FILE";
export const CHANGE_FILTER = "message/CHANGE_FILTER";
export const SET_FILTER = "message/SET_FILTER";
export const CHANGE_PAGE = "message/CHANGE_PAGE";

export const searchToggle = createAction(
    SEARCH_TOGGLE,
    (action) => () => action()
);

export const messageCleanup = createAction(
    MESSAGE_CLEANUP,
    (action) => (key: MCLType) => action(key)
);

export const filterReceivers = createAction(
    FILTER_RECEIVERS,
    (action) => (status: any, read: any) => action({ status, read })
);

export const readyFile = createAction(
    READY_FILE,
    (action) => (file: FileList) => action(file)
);

export const deleteReadyFile = createAction(
    DELETE_READY_FILE,
    (action) => (index: number) => action(index)
);

export const changeFilter = createAction(
    CHANGE_FILTER,
    (action) => (nv: MFCType) => action(nv)
);

export const setFilter = createAction(
    SET_FILTER,
    (action) => (filter: MessageReducerState) => action(filter)
);

export const changePage = createAction(
    CHANGE_PAGE,
    (action) => (page: { key: "spage" | "page"; page?: number }) => action(page)
);

export const fetchMessageAsync = createAsyncAction(
    "message/FETCH_MESSAGE_REQUEST",
    "message/FETCH_MESSAGE_SUCCESS",
    "message/FETCH_MESSAGE_FAILURE"
)<boolean, { count: number; data: Array<MessageItemType> }, Error>();
// boolean initialize type

export const fetchMessageViewAsync = createAsyncAction(
    "message/FETCH_MESSAGE_VIEW_REQUEST",
    "message/FETCH_MESSAGE_VIEW_SUCCESS",
    "message/FETCH_MESSAGE_VIEW_FAILURE"
)<
    string,
    {
        count: number;
        data: [Array<MessageViewType>, []];
        message_data: MessageViewCoreType;
    },
    Error
>();

export const fetchMessageSendAsync = createAsyncAction(
    "message/FETCH_MESSAGE_SEND_REQUEST",
    "message/FETCH_MESSAGE_SEND_SUCCESS",
    "message/FETCH_MESSAGE_SEND_FAILURE"
)<{ data: MessageSendPrototype }, null, Error>();

export const fetchMessageCancelAsync = createAsyncAction(
    "message/FETCH_MESSAGE_CANCLE_REQUEST",
    "message/FETCH_MESSAGE_CANCLE_SUCCESS",
    "message/FETCH_MESSAGE_CANCLE_FAILURE"
)<number, number, Error>();

export const fetchMessageSearchAsync = createAsyncAction(
    "message/FETCH_MESSAGE_SEARCH_REQUEST",
    "message/FETCH_MESSAGE_SEARCH_SUCCESS",
    "message/FETCH_MESSAGE_SEARCH_FAILURE"
)<string | number, { count: number; data: Array<MessageItemType> }, Error>();

export const fetchMessagePersnalAsync = createAsyncAction(
    "message/FETCH_MESSAGE_PERSNAL_REQUEST",
    "message/FETCH_MESSAGE_PERSNAL_SUCCESS",
    "message/FETCH_MESSAGE_PERSNAL_FAILURE"
)<{ data: MessagePersnalPrototype }, null, Error>();

export const fetchMessageReceiverAsync = createAsyncAction(
    "message/FETCH_MESSAGE_RECEIVER_REQUEST",
    "message/FETCH_MESSAGE_RECEIVER_SUCCESS",
    "message/FETCH_MESSAGE_RECEIVER_FAILURE"
)<string, Array<ReceiverSearchType>, Error>();

export const fetchMessageUpdateLockrAsync = createAsyncAction(
    "message/FETCH_MESSAGE_UPDATE_LOCK_REQUEST",
    "message/FETCH_MESSAGE_UPDATE_LOCK_SUCCESS",
    "message/FETCH_MESSAGE_UPDATE_LOCK_FAILURE"
)<MessageLock, { mid: number; lock: 0 | 1 }, Error>();

const actions = {
    searchToggle,
    filterReceivers,
    readyFile,
    deleteReadyFile,
    changePage,
    changeFilter,
    setFilter,
    messageCleanup,
    fetchMessageAsync,
    fetchMessageViewAsync,
    fetchMessageSendAsync,
    fetchMessageCancelAsync,
    fetchMessageSearchAsync,
    fetchMessagePersnalAsync,
    fetchMessageReceiverAsync,
    fetchMessageUpdateLockrAsync
};

export type MessageAction = ActionType<typeof actions>;

export type MessageState = {
    // list
    messages: MessageItemType[];
    loading: boolean;
    empty: boolean;
    ing_send: boolean;
    count: number;
    page: number;
    limit: number;
    filter: {
        [k in Filter]: number | null | string;
    };

    // view
    view: {
        count: number;
        receivers?: Array<MessageSimpleType>;
        view?: MessageViewCoreType;
        file?: Array<MessageFile>;
    };

    // write
    tempReceiver: Array<MessageSimpleType>;
    files: Array<File>;
    searchReceiver: Array<ReceiverSearchType>;

    // search
    searchs: MessageItemType[];
    searchWord: string;
    spopup: boolean;
    sloading: boolean;
    sempty: boolean;
    scount: number;
    spage: number;
    slimit: number;

    // common
    failure: boolean;
};

const initialState: MessageState = {
    messages: [],
    loading: false,
    ing_send: false,
    empty: false,
    count: 0,
    page: 1,
    limit: 20,
    filter: {
        message_type: null,
        send_status: null,
        approval_status: null,
        date_start: null,
        date_end: null,
        search_word: null
    },

    view: {
        count: 0,
        file: []
    },

    tempReceiver: [],
    files: [],
    searchReceiver: [],

    searchs: [],
    searchWord: "",
    spopup: false,
    sloading: false,
    sempty: false,
    scount: 0,
    spage: 1,
    slimit: 20,
    failure: false
};

export default createReducer<MessageState, MessageAction>(initialState, {
    "message/SEARCH_TOGGLE": (state) => ({ ...state, spopup: !state.spopup }),

    "message/FILTER_RECEIVERS": (state, action) => {
        const { status, read } = action.payload;

        if (hasOwnProperty(state.view, "receivers")) {
            const search = encodeObject<number>({ status, read }) as {
                status: number;
                read: number;
            };

            const filter = loopFilter<MessageSimpleType, "status" | "read">(
                state.tempReceiver,
                search
            );

            return {
                ...state,
                view: { ...state.view, receivers: [...filter] }
            };
        }

        return state;
    },

    "message/READY_FILE": (state, action) => {
        let files = Object.values(action.payload).map((file) => {
            return file;
        });

        return {
            ...state,
            files: [...state.files, ...files]
        };
    },

    "message/DELETE_READY_FILE": (state, action) => {
        return produce(state, (draft) => {
            draft.files = draft.files.filter((f, i) => i !== action.payload);
        });
    },

    "message/CHANGE_PAGE": (state, action) => {
        const { key, page } = action.payload;

        return produce(state, (draft) => {
            draft[key] = page ? page : draft[key] + 1;
        });
    },

    "message/CHANGE_FILTER": (state, action) => {
        let { name, value }: MFCType = action.payload;

        return produce(state, (draft) => {
            if (name === "search_word") {
                draft.searchWord = value as string;
                // 검색어 입력시 not found 해제
                draft.sempty = false;
            } else {
                draft.filter[name] = value;
            }
        });
    },

    "message/SET_FILTER": (state, action) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                ...action.payload
            }
        };
    },

    "message/MESSAGE_CLEANUP": (state, action) => {
        switch (action.payload) {
            case "all":
                return {
                    ...state,
                    messages: [],
                    page: 1,
                    filter: {
                        approval_status: null,
                        date_end: null,
                        date_start: null,
                        message_type: null,
                        search_word: null,
                        send_status: null
                    }
                };
            case "messages":
                return { ...state, messages: [], page: 1 };
            case "view":
                return {
                    ...state,
                    view: {
                        count: 0
                    }
                };
            case "write":
                return { ...state, files: [] };
            case "search":
                return {
                    ...state,
                    searchWord: "",
                    searchs: [],
                    spage: 1,
                    sloading: false
                };
            case "newSearch":
                return {
                    ...state,
                    searchs: [],
                    spage: 1
                };
            case "filter":
                return {
                    ...state,
                    searchWord: "",
                    filter: {
                        approval_status: null,
                        date_end: null,
                        date_start: null,
                        message_type: null,
                        search_word: null,
                        send_status: null
                    }
                };
            default:
                return state;
        }
    },

    // messages
    "message/FETCH_MESSAGE_FAILURE": (state, action) => {
        return {
            ...state,
            failure: true,
            loading: false
        };
    },

    "message/FETCH_MESSAGE_REQUEST": (state, action) => {
        return {
            ...state,
            failure: false,
            loading: true
        };
    },

    "message/FETCH_MESSAGE_SUCCESS": (state, action) => {
        let { data, count } = action.payload;

        return {
            ...state,
            messages: [...state.messages, ...data],
            count,
            failure: false,
            loading: false,
            empty: isZero(count)
        };
    },

    "message/FETCH_MESSAGE_VIEW_SUCCESS": (state, action) => {
        const { count, data, message_data } = action.payload;

        const receivers: Array<MessageSimpleType> = data[0].map(
            ({
                phone,
                read_status,
                send_status,
                type,
                student_class,
                student_grade,
                student_name,
                student_number,
                receiver_type
            }): MessageSimpleType => {
                return {
                    sendType: type,
                    phone: phone,
                    read: read_status,
                    status: send_status,
                    student_class,
                    student_grade,
                    student_name,
                    student_number,
                    receiver_type
                };
            }
        );

        return {
            ...state,
            view: { count, receivers, view: message_data, file: data[1] },
            tempReceiver: receivers
        };
    },

    "message/FETCH_MESSAGE_SEND_FAILURE": (state) => {
        return {
            ...state,
            ing_send: false
        };
    },

    "message/FETCH_MESSAGE_SEND_REQUEST": (state) => {
        return {
            ...state,
            ing_send: true
        };
    },

    "message/FETCH_MESSAGE_SEND_SUCCESS": (state) => {
        return {
            ...state,
            ing_send: false
        };
    },

    "message/FETCH_MESSAGE_SEARCH_FAILURE": (state) => {
        return {
            ...state,
            sloading: false,
            sempty: false
        };
    },

    "message/FETCH_MESSAGE_SEARCH_REQUEST": (state) => {
        return {
            ...state,
            sloading: true,
            sempty: false
        };
    },

    "message/FETCH_MESSAGE_CANCLE_SUCCESS": (state, action) => {
        const previous = state.view.view;
        const mid = action.payload;
        return produce(state, (draft) => {
            const updateIndex = draft.messages.findIndex(
                ({ message_request_id }) => message_request_id === mid
            );

            // view 및 리스트 취소 만들기
            draft.view.view = {
                ...previous,
                send_status: -1,
                approval_user: 0,
                approval_name: null,
                approval_position: null,
                fail: previous.total
            };

            draft.messages[updateIndex].send_status = -1;
            draft.messages[updateIndex].approval_status = null;
        });
    },

    "message/FETCH_MESSAGE_SEARCH_SUCCESS": (state, action) => {
        let { data, count } = action.payload;

        // 새로운 검색일때는 겹치면 안댐
        let combined = state.spage === 1 ? data : [...state.searchs, ...data];

        return {
            ...state,
            searchs: combined,
            scount: count,
            sloading: false,
            sempty: isZero(combined.length)
        };
    },

    "message/FETCH_MESSAGE_RECEIVER_SUCCESS": (state, action) => {
        const data = action.payload;

        if (isZero(data.length)) {
            return {
                ...state,
                searchReceiver: []
            };
        }

        const searchReceiver: Array<ReceiverSearchType> = data.map(
            ({
                id,
                name,
                class_name,
                grade,
                number,
                phone,
                app,
                type,
                group_name,
                ...rest
            }) => {
                const $name =
                    type.toString() === "그룹"
                        ? `${group_name} ${name} (${type})`
                        : `${grade}학년 ${class_name}반 ${number}번 ${name} (${type})`;

                return {
                    id,
                    app,
                    name: $name,
                    phone: phonemat(phone),
                    uid: uniqueKey(),
                    chipType: app ? 3 : 2,
                    type,
                    ...rest
                };
            }
        );

        return {
            ...state,
            searchReceiver
        };
    },

    "message/FETCH_MESSAGE_UPDATE_LOCK_SUCCESS": (state, action) => {
        const { mid } = action.payload;
        return produce(state, (draft) => {
            const updateIndex = draft.messages.findIndex(
                ({ message_request_id }) => message_request_id === mid
            );

            draft.view.view.is_lock = action.payload.lock;
            draft.messages[updateIndex].is_lock = action.payload.lock;
        });
    }
});
