import React from "react";
import styled, { css } from "styled-components";

export interface PbDividerProps {
    distance?: number;
    className?: string;
    hr?: boolean;
}

const PbDividerStyled = styled.div<PbDividerProps>`
    margin-bottom: ${(props) =>
        props.distance || props.distance === 0 ? props.distance * 10 : 10}px;

    word-break: break-all;

    ${(props) =>
        props.hr &&
        css`
            border-bottom: 1px solid ${props.theme.color.line};
        `}
`;

const PbDivider: React.SFC<PbDividerProps> = ({
    children,
    distance,
    hr,
    className = "",
}) => {
    return (
        <PbDividerStyled
            distance={distance}
            hr={hr}
            className={`pb-divider ${className}`}
        >
            {children}
        </PbDividerStyled>
    );
};

export default PbDivider;
