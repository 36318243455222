import loadable from "@loadable/component";

export const Auth = loadable(() => import("./AuthPage"));
export const Fail = loadable(() => import("./FailPage"));
export const Root = loadable(() => import("./RootPage"));
export const Message = loadable(() => import("./MessagePage"));
export const Approval = loadable(() => import("./ApprovalPage"));
export const Student = loadable(() => import("./StudentPage"));
export const Rate = loadable(() => import("./RatePage"));
export const NowAllow = loadable(() => import("./NotAllowPage"));
