import {
    ActionType,
    createAction,
    createReducer,
    createAsyncAction,
} from "typesafe-actions";
import { onlyKey, jsonEqual, phonemat } from "../lib/utils";
import produce from "immer";
import { setSchoolId } from "../api/configure";

export type SchoolType = {
    acc_balance: number;
    acc_count: number;
    class_name?: string;
    contract_status: number;
    depart: "없음";
    grade: "";
    id: number;
    manage_approval: 0 | 1;
    manage_board: 0 | 1;
    manage_messages: 0 | 1;
    manage_rating: 0 | 1;
    manage_rating_category: 0 | 1;
    manage_students: 0 | 1;
    manage_students_look: 0 | 1;
    manage_super: 0 | 1;
    manage_teachers: 0 | 1;
    manage_view_class: 0 | 1;
    name: string;
    only_approval: 0 | 1 | 2;
    permission: -1 | 0 | 1 | 2;
    plan: string;
    position: string;
    school_type: "ETC" | "ELE" | "MID" | "HIGH";
    smom_id: number;
    status: 0 | 1;
    tel: string | number;
    year: number;
};

export type SchoolClassType = {
    class_name: string;
    grade: number;
    id: number;
    student_count: number;
};

export type SchoolTel = {
    id: number;
    name: string;
    tel: string;
    status: number;
    label?: string;
};

export type SchoolSelectOption<T> = Array<{ label: string; value: T | null }>;
export type ClassNames = { [k: string]: string[] };

export type ReceiverConfigKeys =
    | "parents1"
    | "parents2"
    | "student"
    | "teacher";

export type ReceiverDefaultConfig = { [key in ReceiverConfigKeys]: boolean };

export const SET_SCHOOL = "school/SET_SCHOOL";
export const SET_SENDER = "school/SET_SENDER";
export const SCHOOL_CLEANUP = "school/SCHOOL_CLEANUP";
export const CHANGE_SCHOOL = "school/CHANGE_SCHOOL";

export const setSchool = createAction(
    SET_SCHOOL,
    (action) => (schools?: Array<SchoolType>, school?: SchoolType) =>
        action({ schools, school })
);

export const setSender = createAction(
    SET_SENDER,
    (action) => (tel?: SchoolTel) => action(tel)
);

export const changeSchool = createAction(
    CHANGE_SCHOOL,
    (action) => (item: SchoolType) => action(item)
);

export const schoolCleanup = createAction(SCHOOL_CLEANUP, (action) => () =>
    action()
);

/**
 * [GET] grade class_name data
 */
export const fetchCallClassAsync = createAsyncAction(
    "school/FETCH_CALL_CLASS_REQUEST",
    "school/FETCH_CALL_CLASS_SUCCESS",
    "school/FETCH_CALL_CLASS_FAILURE"
)<null, Array<SchoolClassType>, Error>();

export const fetchSchoolTelsAsync = createAsyncAction(
    "school/FETCH_SCHOOL_TELS_REQUEST",
    "school/FETCH_SCHOOL_TELS_SUCCESS",
    "school/FETCH_SCHOOL_TELS_FAILURE"
)<null, Array<SchoolTel>, Error>();

export const fetchReceiverDefaultConfigAsync = createAsyncAction(
    "school/FETCH_RECEIVER_DEFAULT_CONFIG_REQUEST",
    "school/FETCH_RECEIVER_DEFAULT_CONFIG_SUCCESS",
    "school/FETCH_RECEIVER_DEFAULT_CONFIG_FAILURE"
)<null, ReceiverDefaultConfig, Error>();

const actions = {
    setSchool,
    setSender,
    changeSchool,
    schoolCleanup,
    fetchCallClassAsync,
    fetchSchoolTelsAsync,
    fetchReceiverDefaultConfigAsync,
};

export type SchoolActions = ActionType<typeof actions>;

export type SchoolState = {
    schools: Array<SchoolType>;
    myschool?: SchoolType;
    tels: Array<SchoolTel>;
    sender?: SchoolTel;
    cloading: boolean; // class loading
    grade: SchoolSelectOption<number>;
    className: ClassNames;
    defaultReceiver: ReceiverDefaultConfig;
};

const initialState: SchoolState = {
    schools: [],
    myschool: null,
    tels: [],
    sender: null,
    cloading: false,
    className: {},
    grade: [],
    defaultReceiver: {
        parents1: false,
        parents2: false,
        student: false,
        teacher: false,
    },
};

export default createReducer<SchoolState, SchoolActions>(initialState, {
    "school/SET_SCHOOL": (state, action) => {
        const schools: Array<SchoolType> = action.payload.schools;
        const myschool = action.payload.school;

        setSchoolId(myschool.id);

        return {
            ...state,
            schools,
            myschool,
        };
    },

    "school/SET_SENDER": (state, action) => {
        return {
            ...state,
            sender: action.payload,
        };
    },

    "school/CHANGE_SCHOOL": (state, action) => {
        const { id } = action.payload;
        const index = state.schools.findIndex((item) => item.id === id);

        const noPick = state.schools.filter((item) => item.id !== id);

        localStorage.setItem(
            "schools",
            JSON.stringify([state.schools[index], ...noPick])
        );

        setSchoolId(state.schools[index].id);

        return {
            ...state,
            myschool: state.schools[index],
        };
    },

    "school/SCHOOL_CLEANUP": (state, action) => {
        return state;
    },

    "school/FETCH_CALL_CLASS_FAILURE": (state) => {
        return {
            ...state,
            cloading: false,
        };
    },

    "school/FETCH_CALL_CLASS_REQUEST": (state) => {
        return {
            ...state,
            cloading: true,
        };
    },

    "school/FETCH_CALL_CLASS_SUCCESS": (state, action) => {
        let classNames: { [k: string]: string[] } = {};
        let grades = onlyKey<SchoolClassType, "grade">(action.payload, "grade");

        let gradeOption: SchoolSelectOption<number> = grades.map((g) => ({
            label: `${g} 학년`,
            value: g,
        }));

        grades.forEach((g) => {
            let matches = action.payload.filter((cl) => cl.grade === g);
            classNames[g] = matches.map(({ class_name }) => class_name);
        });

        return produce(state, (draft) => {
            // 기존에 있는 값과 같으면 pass
            if (!jsonEqual(draft.grade, gradeOption)) {
                draft.grade = [{ label: "전체", value: null }, ...gradeOption];
            }

            if (!jsonEqual(draft.className, classNames)) {
                draft.className = classNames;
            }

            draft.cloading = false;
        });
    },

    "school/FETCH_SCHOOL_TELS_SUCCESS": (state, action) => {
        const tels = action.payload.map(({ name, tel, ...rest }) => {
            return {
                ...rest,
                name,
                tel,
                label: `${name} | ${phonemat(tel)}`,
            };
        });

        return {
            ...state,
            tels,
            sender: tels[0],
        };
    },

    "school/FETCH_RECEIVER_DEFAULT_CONFIG_SUCCESS": (state, action) => {
        return {
            ...state,
            defaultReceiver: { ...action.payload },
        };
    },
});
