import React, { useState } from "react";
import styled from "styled-components";

import FaIcon from "../common/FaIcon";
import PbRow from "./PbRow";

interface PbExpand {
    Title: React.SFC<PbExpandTitleProps>;
    Content: React.SFC;
}

export interface PbExpandProps {
    className?: string;
}

export interface PbExpandTitleProps {
    defaultExpand?: boolean;
    hideArrow?: boolean;
}

export interface PbExpandTitleStyleProps {
    expand?: boolean;
}

const PbExpandStyled = styled.div`
    font-size: 1.4rem;

    .expand-content {
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        transition: all 0.35s;
    }

    & + & {
        border-top: 1px solid ${(props) => props.theme.color.line};
    }
`;

const PbExpandTitleStyle = styled.div<PbExpandTitleStyleProps>`
    .expand-title {
        padding: 16px 24px;

        &__text {
            width: calc(100% - 36px);
        }
    }

    .expand-arrow {
        width: 36px;
        text-align: right;

        svg {
            font-size: 24px;
        }
    }

    & + .expand-content {
        height: ${(props) => (props.expand ? "auto" : "0")};
        flex: ${(props) => (props.expand ? "1" : "0")};
    }
`;

const PbExpand: PbExpand & React.SFC<PbExpandProps> = ({
    children,
    className = "",
}) => {
    return (
        <PbExpandStyled className={`pb-expand ${className}`}>
            {children}
        </PbExpandStyled>
    );
};

const PbExpandTitle: React.SFC<PbExpandTitleProps> = ({
    children,
    defaultExpand,
    hideArrow = false,
}) => {
    const [expand, onExpand] = useState<boolean>(defaultExpand);

    return (
        <PbExpandTitleStyle expand={expand}>
            <PbRow
                className="expand-title"
                align="center"
                onClick={() => onExpand(!expand)}
            >
                <div className="expand-title__text">{children}</div>
                {!hideArrow && (
                    <div className="expand-arrow">
                        <FaIcon prefix="fal" name="angle-down" />
                    </div>
                )}
            </PbRow>
        </PbExpandTitleStyle>
    );
};

const PbExpandContent: React.SFC = ({ children }) => {
    return <div className="expand-content">{children}</div>;
};

PbExpand.Title = PbExpandTitle;
PbExpand.Content = PbExpandContent;
export default PbExpand;
