import React, { DOMAttributes } from "react";
import styled from "styled-components";

export interface PbFloatProps extends DOMAttributes<HTMLDivElement> {
    float: "left" | "right";
    className?: string;
    textAlign?: "left" | "center" | "right";
}

const PbFloatStyled = styled.div<PbFloatProps>`
    display: inline-block;
    float: ${props => props.float};
    text-align: ${props => props.textAlign};
`;

const PbFloat: React.SFC<PbFloatProps> = ({
    children,
    float = "left",
    className = "",
    textAlign = "left",
    onClick,
    ...props
}) => {
    return (
        <PbFloatStyled
            float={float}
            textAlign={textAlign}
            className={`pb-float ${className}`}
            onClick={onClick}
            {...props}
        >
            {children}
        </PbFloatStyled>
    );
};

export default PbFloat;
