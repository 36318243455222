import { combineReducers } from "redux";

import base from "./base";
import user from "./user";
import message from "./message";
import school from "./school";
import rate from "./rate";
import approval from "./approval";

export default combineReducers({
    base,
    user,
    message,
    school,
    rate,
    approval
});
