import React from "react";
import styled, { css } from "styled-components";

export interface PbDropdownItemsProps {
    active: boolean;
}

const PbDropdownItemsStyled = styled.div.attrs({
    className: "pb-dropdown-items"
})<PbDropdownItemsProps>`
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #fff;
    word-break: keep-all;
    white-space: nowrap;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    z-index: 1004;

    visibility: hidden;
    opacity: 0;
    transform: translateY(15px);
    transition: all 0.35s;

    ${props =>
        props.active &&
        css`
            visibility: visible;
            opacity: 1;
            transform: translateY(5px);
        `}
`;

const PbDropdownItems: React.SFC<PbDropdownItemsProps> = ({
    children,
    active
}) => {
    return (
        <PbDropdownItemsStyled active={active}>
            {children}
        </PbDropdownItemsStyled>
    );
};

export default PbDropdownItems;
