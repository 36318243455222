import {
    createReducer,
    createAction,
    createAsyncAction,
    ActionType,
} from "typesafe-actions";
import { SelectorOption } from "../shared/PbSelector";
import {
    hasOwnProperty,
    isEmpty,
    uniqueKey,
    isZero,
    onlyKeyV2,
} from "../lib/utils";
import { ReceiverType } from "./user";
import produce from "immer";
import { RateInsert } from "../api/rate";

export type StudentReducerState = {
    date_start: string | null;
    grade: any;
    class_name: any;
    rating_sum_type: string | null;
    rating_min: number | null;
    rating_max: number | null;
};

export type StudnetReducerAction =
    | { type: "SET_DATE_START"; payload: any }
    | { type: "SET_RATING_SUM_TYPE"; payload: any }
    | { type: "SET_RATING_MIN"; payload: any }
    | { type: "SET_RATING_MAX"; payload: any }
    | { type: "SET_GRADE"; payload: any }
    | { type: "SET_CLASS_NAME"; payload: any }
    | { type: "reset" }
    | { type: "init"; payload: any };

export type RateType = {
    accumulate_rating: number;
    class_name: string;
    grade: number;
    id: number;
    minus_rating: number;
    name: string;
    number: number;
    plus_rating: number;
};

// 상세보기 상단 학생 번호 정보
export type StudentInfo = {
    id: number;
    name: string;
    grade: number;
    class_name: string;
    number: number;
    parents_phone1: string | null;
    parents_phone1_app: boolean | null;
    parents_phone2: string | null;
    parents_phone2_app: boolean | null;
    student_phone: string | null;
    student_phone_app: boolean | null;
    teacher_phone: string | null;
    teacher_phone_app: boolean | null;
    etc_phone: string | null;
    etc_phone_app: boolean | null;
};

// 상세보기 data items
export type RateViewType = {
    added_by: string;
    category1_name: string;
    category2: number;
    category2_name: string;
    category_text: string | null;
    class_name: string;
    grade: number;
    grady_day: string | Date;
    id: number;
    rating: number;
    rating_id: number;
    rating_type: string;
    student_name: string;
    student_number: number;
    updated_at?: string;
};

export type RateHistory = {
    count: number;
    items: Array<RateViewType>;
    total: TotalType;
} | null;

export type Category = {
    added_by: number;
    createdAt: number;
    delete: number;
    id: number;
    name: string;
    parent_category_id: number;
    rating_max: number;
    rating_min: number;
    rating_type: number;
    type: 0 | 1;
};

export type RateFilter =
    | "date_start"
    | "grade"
    | "class_name"
    | "rating_sum_type"
    | "rating_max"
    | "rating_min"
    | "student_name";

export type RFCType = { name: RateFilter | "searchWord"; value: any };
export type RCLType =
    | "all"
    | "student"
    | "view"
    | "insert"
    | "search"
    | "newSearch"
    | "filter"
    | "category";

// 상세보기 scroe_date
export type TotalType = {
    accumulate_rating: number;
    minus_rating: number;
    plus_rating: number;
};

// * type 대분류, 소분류
// * rType 상, 벌점
// * category 대분류 idx ( 소분류 가져올 때 사용한다. )
export type GetCategory = {
    type: "0" | "1";
    rType: "0" | "1";
    category?: number;
};

export type PhoneParam = {
    grade: number;
    class_name: string;
    name: string;
};

export type RateReceiver = {
    grade: number;
    class_name: string;
    count?: number[];
    members?: Array<ReceiverType>;
    checked?: boolean;
    uid?: string;
};

export type CheckRemoveParams = {
    id: number;
    grade: number;
    class_name: string;
    name: string;
    uid: string;
};

export const SET_STUDENT = "rate/SET_STUDENT";
export const SET_FILTER = "rate/SET_FILTER";
export const SEARCH_TOGGLE = "rate/SEARCH_TOGGLE";
export const CHANGE_PAGE = "rate/CHANGE_PAGE";
export const CHANGE_FILTER = "rate/CHANGE_FILTER";
export const CHANGE_RATE = "rate/CHANGE_RATE";
export const PUSH_STUDENT = "rate/PUSH_STUDENT";
export const REMOVE_STUDENT = "rate/REMOVE_STUDENT";
export const EXPORT_STUDENT = "rate/EXPORT_STUDENT";
export const CLASS_CHECKED = "rate/CLASS_CHECKED";
export const STUDENT_CHECKED = "rate/STUDENT_CHECKED";
export const STUDENT_REMOVE_CHECKED = "rate/STUDENT_REMOVE_CHECKED";
export const RATE_CLEANUP = "rate/RATE_CLEANUP";

export const setStudent = createAction(
    SET_STUDENT,
    (action) => (info: RateType) => action(info)
);

export const setFilter = createAction(
    SET_FILTER,
    (action) => (filter: StudentReducerState) => action(filter)
);

export const searchToggle = createAction(
    SEARCH_TOGGLE,
    (action) => () => action()
);

// student infinite paging
export const changePage = createAction(
    CHANGE_PAGE,
    (action) => (page: { key: "spage" | "page" | "vpage"; page?: number }) =>
        action(page)
);

// rate filter input change
export const changeFilter = createAction(
    CHANGE_FILTER,
    (action) => (nv: RFCType) => action(nv)
);

// rate insert input change
export const changeRate = createAction(
    CHANGE_RATE,
    (action) => (selector: { name: string; value: any }) => action(selector)
);

export const pushStudent = createAction(
    PUSH_STUDENT,
    (action) => (item: ReceiverType) => action(item)
);

export const removeStudent = createAction(
    REMOVE_STUDENT,
    (action) => (uid: string) => action(uid)
);

export const exportStudent = createAction(
    EXPORT_STUDENT,
    (action) => () => action()
);

export const classChecked = createAction(
    CLASS_CHECKED,
    (action) => (uid: string) => action(uid)
);

export const studentChecked = createAction(
    STUDENT_CHECKED,
    (action) => (params: { classId: string; uid: string }) => action(params)
);

export const studentRemoveChecked = createAction(
    STUDENT_REMOVE_CHECKED,
    (action) => (params: CheckRemoveParams) => action(params)
);

export const rateCleanup = createAction(
    RATE_CLEANUP,
    (action) => (type: RCLType) => action(type)
);

export const fetchRateStudentAsync = createAsyncAction(
    "rate/FETCH_RATE_STUDENT_REQUEST",
    "rate/FETCH_RATE_STUDENT_SUCCESS",
    "rate/FETCH_RATE_STUDENT_FAILURE"
)<null, { data: Array<RateType>; count: number }, Error>();

export const fetchStudentSearchAsync = createAsyncAction(
    "rate/FETCH_RATE_SEARCH_REQUEST",
    "rate/FETCH_RATE_SEARCH_SUCCESS",
    "rate/FETCH_RATE_SEARCH_FAILURE"
)<string | number, { data: Array<RateType>; count: number }, Error>();

export const fetchCallCategoryAsync = createAsyncAction(
    "rate/FETCH_CALL_CATEGORY_REQUEST",
    "rate/FETCH_CALL_CATEGORY_SUCCESS",
    "rate/FETCH_CALL_CATEGORY_FAILURE"
)<GetCategory, { data: Array<Category>; type: "0" | "1" }, Error>();

export const fetchRateReceiverAsync = createAsyncAction(
    "rate/FETCH_RATE_RECEIVER_REQUEST",
    "rate/FETCH_RATE_RECEIVER_SUCCESS",
    "rate/FETCH_RATE_RECEIVER_FAILURE"
)<
    { grade: number; className: string; searchWord: string },
    Array<ReceiverType>,
    Error
>();

export const fetchRateViewAsync = createAsyncAction(
    "rate/FETCH_RATE_VIEW_REQUEST",
    "rate/FETCH_RATE_VIEW_SUCCESS",
    "rate/FETCH_RATE_VIEW_FAILURE"
)<
    string,
    {
        info: StudentInfo;
        history: Array<RateViewType>;
        total: TotalType;
        count: number;
    },
    Error
>();

export const fetchRateViewPagingAsync = createAsyncAction(
    "rate/FETCH_RATE_VIEW_PAGING_REQUEST",
    "rate/FETCH_RATE_VIEW_PAGING_SUCCESS",
    "rate/FETCH_RATE_VIEW_PAGING_FAILURE"
)<string, Array<RateViewType>, Error>();

export const fetchRateInsertAsnyc = createAsyncAction(
    "rate/FETCH_RATE_INSERT_REQUEST",
    "rate/FETCH_RATE_INSERT_SUCCESS",
    "rate/FETCH_RATE_INSERT_FAILURE"
)<{ data: RateInsert; alert?: boolean }, null, Error>();

export const fetchPhoneInfoAsnyc = createAsyncAction(
    "rate/FETCH_PHONE_INFO_REQUEST",
    "rate/FETCH_PHONE_INFO_SUCCESS",
    "rate/FETCH_PHONE_INFO_FAILURE"
)<PhoneParam, Array<StudentInfo>, Error>();

const actions = {
    setStudent,
    setFilter,
    searchToggle,
    changePage,
    changeFilter,
    changeRate,
    pushStudent,
    removeStudent,
    exportStudent,
    classChecked,
    studentChecked,
    studentRemoveChecked,
    rateCleanup,
    fetchRateStudentAsync,
    fetchRateViewAsync,
    fetchRateViewPagingAsync,
    fetchCallCategoryAsync,
    fetchRateReceiverAsync,
    fetchRateInsertAsnyc,
    fetchStudentSearchAsync,
    fetchPhoneInfoAsnyc,
};

export type RateActions = ActionType<typeof actions>;

export type RateState = {
    // student list
    students: Array<RateType>;
    count: number;
    page: number;
    limit: number;
    loading: boolean;
    failure: boolean;

    // student search
    searchs: Array<RateType>;
    empty: boolean;
    searchWord: string;
    spage: number;
    scount: number;
    slimit: number;
    spopup: boolean;

    // filter
    filter: { [k in RateFilter]: number | null | string };

    // student view
    studentInfo: StudentInfo | null;
    tempInfo: RateType | null;
    rateHistory: RateHistory;
    vpage: number;
    vlimit: number;
    vcount: number;
    vloading: boolean;
    vfailure: boolean;

    // rate insert
    type: "0" | "1";
    bigCategory: Array<SelectorOption>;
    smallCategory: Array<SelectorOption>;
    selfCategory: string;
    big: any;
    small: any;
    score: number;
    gradyDay: string | number;
    overlap: boolean;
    selfWrite: boolean;
    added_by: any;
    // withMessage: boolean;
    iloading: boolean;

    // receiver items
    rStudents: Array<RateReceiver>;
    ron_receivers: Array<ReceiverType>;
    tempSelectStore: Array<ReceiverType>;
    tempSelectStudentCount: number;
    sloading: boolean;
    sempty: boolean;
};

/**
 * studentInfo: {
        id: null,
        name: null,
        grade: null,
        class_name: null,
        number: null,
        parents_phone1: null,
        parents_phone1_app: null,
        parents_phone2: null,
        parents_phone2_app: null,
        student_phone: null,
        student_phone_app: null
    }
 */

const initialState: RateState = {
    // student list
    students: [],
    count: 0,
    page: 1,
    limit: 20,
    loading: false,
    failure: false,

    // search
    searchs: [],
    empty: false,
    searchWord: null,
    scount: 0,
    spage: 1,
    slimit: 20,
    spopup: false,

    // filter
    filter: {
        date_start: null,
        grade: null,
        class_name: null,
        rating_sum_type: null,
        rating_max: null,
        rating_min: null,
        student_name: null,
    },

    // student view
    studentInfo: {
        id: null,
        name: null,
        grade: null,
        class_name: null,
        number: null,
        parents_phone1: null,
        parents_phone1_app: null,
        parents_phone2: null,
        parents_phone2_app: null,
        student_phone: null,
        student_phone_app: null,
        teacher_phone: null,
        teacher_phone_app: null,
        etc_phone: null,
        etc_phone_app: null,
    },
    tempInfo: null,
    rateHistory: {
        count: 0,
        items: [],
        total: {
            accumulate_rating: 0,
            minus_rating: 0,
            plus_rating: 0,
        },
    },
    vpage: 1,
    vlimit: 20,
    vcount: 0,
    vloading: false,
    vfailure: false,

    // insert info
    type: "1",
    bigCategory: [],
    smallCategory: [],
    selfCategory: null,
    big: null,
    small: null,
    score: 0,
    gradyDay: null,
    overlap: false,
    selfWrite: false,
    added_by: null,
    // withMessage: false,
    iloading: false,

    // receiver items
    rStudents: [],
    ron_receivers: [],
    tempSelectStore: [],
    tempSelectStudentCount: 0,
    sloading: false,
    sempty: false,
};

export default createReducer<RateState, RateActions>(initialState, {
    "rate/SET_STUDENT": (state, action) => {
        return {
            ...state,
            tempInfo: {
                ...action.payload,
            },
        };
    },

    "rate/SET_FILTER": (state, action) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                ...action.payload,
            },
        };
    },

    "rate/SEARCH_TOGGLE": (state) => ({ ...state, spopup: !state.spopup }),

    "rate/CHANGE_RATE": (state, action) => {
        let option = {};
        const { name, value } = action.payload;

        if (name === "type") {
            option = { bigCategory: [], big: null, small: null, score: 0 };
        }

        if (name === "big") {
            option = { smallCategory: [], small: null, score: 0 };
        }

        if (name === "small") {
            if (hasOwnProperty(value, "id")) {
                if (value.rating_min === value.rating_max) {
                    option = { score: value.rating_max };
                } else {
                    option = { score: 0 };
                }
            }
        }

        return {
            ...state,
            [name]: value,
            ...option,
        };
    },

    "rate/CHANGE_PAGE": (state, action) => {
        const { key, page } = action.payload;

        return produce(state, (draft) => {
            draft[key] = page ? page : draft[key] + 1;
        });
    },

    "rate/CHANGE_FILTER": (state, action) => {
        const { name, value } = action.payload;

        return produce(state, (draft) => {
            if (name === "searchWord") {
                draft.searchWord = value as string;
                // 검색어 입력시 not found 해제
                draft.empty = false;
            } else {
                draft.filter[name] = value;
            }
        });
    },

    "rate/PUSH_STUDENT": (state, action) => {
        return {
            ...state,
            ron_receivers: [...state.ron_receivers, action.payload],
        };
    },

    "rate/REMOVE_STUDENT": (state, action) => {
        let filter = state.ron_receivers.filter(
            (r) => r.id.toString() !== action.payload
        );

        return {
            ...state,
            ron_receivers: [...filter],
        };
    },

    "rate/EXPORT_STUDENT": (state) => {
        return produce(state, (draft) => {
            // 중복 학생 제거
            let nAlready = draft.tempSelectStore.filter(
                (temp) =>
                    draft.ron_receivers.findIndex(
                        ({ id }) => id === temp.id
                    ) === -1
            );

            draft.ron_receivers = [...draft.ron_receivers, ...nAlready];
        });
    },

    "rate/CLASS_CHECKED": (state, action) => {
        const uid = action.payload;

        return produce(state, (draft) => {
            const index = draft.rStudents.findIndex((item) => item.uid === uid);
            const { checked, members, grade, class_name } =
                draft.rStudents[index];
            draft.rStudents[index].checked = !checked;
            draft.rStudents[index].members = members.map(({ ...props }) => ({
                ...props,
                checked: !checked,
            }));

            if (!checked === true) {
                // temp 에 없는 학생 ( 중복 제거 )
                const notYet = draft.rStudents[index].members.filter(
                    ({ id, checked }) =>
                        draft.tempSelectStore.findIndex(
                            (temp) => temp.id === id
                        ) === -1
                );

                draft.tempSelectStore = [...draft.tempSelectStore, ...notYet];
                draft.tempSelectStudentCount += notYet.length;
            } else {
                // 해당 반 모든 학생 삭제
                const classDel = draft.tempSelectStore.filter(
                    (item) =>
                        item.grade !== grade && item.class_name !== class_name
                );

                draft.tempSelectStore = classDel;
                draft.tempSelectStudentCount -= members.length;
            }
        });
    },

    "rate/STUDENT_CHECKED": (state, action) => {
        const { uid, classId } = action.payload;

        return produce(state, (draft) => {
            const index = draft.rStudents.findIndex(
                (item) => item.uid === classId
            );
            const { members } = draft.rStudents[index];

            const cIndex = draft.rStudents[index].members.findIndex(
                (item) => item.id.toString() === uid
            );

            draft.rStudents[index].members = members.reduce(
                (prev, current, i) => {
                    if (i === cIndex) {
                        current.checked = !current.checked;

                        if (current.checked) {
                            // check
                            draft.tempSelectStore.push(current);
                            draft.tempSelectStudentCount += 1;
                        } else {
                            // uncheck
                            draft.tempSelectStore =
                                draft.tempSelectStore.filter(
                                    (temp) => temp.id !== current.id
                                );
                            draft.tempSelectStudentCount -= 1;
                        }
                    }

                    return [...prev, current];
                },
                []
            );

            const trueCount = members.filter(({ checked }) => !!checked).length;
            draft.rStudents[index].checked = trueCount === members.length;
        });
    },

    "rate/STUDENT_REMOVE_CHECKED": (state, action) => {
        const { uid, class_name, grade, id } = action.payload;

        return produce(state, (draft) => {
            const index = draft.rStudents.findIndex(
                (item) => item.grade === grade && item.class_name === class_name
            );

            const sindex = draft.rStudents[index].members.findIndex(
                (student) => {
                    return student.id === id;
                }
            );

            if (sindex !== -1) {
                draft.rStudents[index].members[sindex].checked =
                    !draft.rStudents[index].members[sindex].checked;

                draft.tempSelectStore = draft.tempSelectStore.filter((temp) => {
                    return temp.uid !== uid;
                });

                draft.tempSelectStudentCount -= 1;

                // 전체 선택이 true 면 무조건 false로 바꾼다.
                // 학생를 삭제하면 무조건 해제되기 때문
                // absolutly (check length !== student length)
                if (draft.rStudents[index].checked) {
                    draft.rStudents[index].checked = false;
                }
            }
        });
    },

    "rate/RATE_CLEANUP": (state, action) => {
        switch (action.payload) {
            case "all":
                return {
                    ...state,
                    students: [],
                    page: 1,
                    type: "1",
                    filter: {
                        grade: null,
                        class_name: null,
                        date_start: null,
                        rating_sum_type: null,
                        rating_max: null,
                        rating_min: null,
                        student_name: null,
                    },
                };
            case "student":
                return {
                    ...state,
                    students: [],
                    page: 1,
                };
            case "view":
                return {
                    ...state,
                    studentInfo: {
                        id: null,
                        name: null,
                        grade: null,
                        class_name: null,
                        number: null,
                        parents_phone1: null,
                        parents_phone1_app: null,
                        parents_phone2: null,
                        parents_phone2_app: null,
                        student_phone: null,
                        student_phone_app: null,
                        teacher_phone: null,
                        teacher_phone_app: null,
                        etc_phone: null,
                        etc_phone_app: null,
                    },
                    rateHistory: {
                        count: 0,
                        items: [],
                        total: {
                            accumulate_rating: 0,
                            minus_rating: 0,
                            plus_rating: 0,
                        },
                    },
                    tempInfo: null,
                    vloading: false,
                    vcount: 0,
                    vpage: 1,
                    vfailure: false,
                };
            case "insert":
                return {
                    ...state,
                    type: "1",
                    smallCategory: [],
                    selfCategory: null,
                    big: null,
                    small: null,
                    selfWrite: false,
                    score: 0,
                    gradyDay: null,
                    overlap: false,
                    // withMessage: false,
                    iloading: false,
                    rStudents: [],
                    ron_receivers: [],
                    tempSelectStore: [],
                    tempSelectStudentCount: 0,
                    sloading: false,
                    sempty: false,
                    searchWord: null,
                    spopup: false,
                };
            case "category":
                return {
                    ...state,
                    big: null,
                    small: null,
                    selfCategory: null,
                    score: 0,
                };
            case "filter":
                return {
                    ...state,
                    filter: {
                        class_name: null,
                        grade: null,
                        date_start: null,
                        rating_sum_type: null,
                        rating_max: null,
                        rating_min: null,
                        student_name: null,
                    },
                };
            case "search":
                return {
                    ...state,
                    searchs: [],
                    spage: 1,
                    searchWord: null,
                };
            case "newSearch":
                return {
                    ...state,
                    searchs: [],
                    spage: 1,
                };
            default:
                return state;
        }
    },

    // rate list student
    "rate/FETCH_RATE_STUDENT_FAILURE": (state, action) => {
        return {
            ...state,
            loading: false,
            failure: true,
        };
    },

    "rate/FETCH_RATE_STUDENT_REQUEST": (state, action) => {
        return {
            ...state,
            loading: true,
            failure: false,
        };
    },

    "rate/FETCH_RATE_STUDENT_SUCCESS": (state, action) => {
        const { data, count } = action.payload;

        return {
            ...state,
            students: [...state.students, ...data],
            count,
            loading: false,
        };
    },

    // rate view student
    "rate/FETCH_RATE_VIEW_FAILURE": (state) => {
        return {
            ...state,
            vloading: false,
            vfailure: true,
        };
    },

    "rate/FETCH_RATE_VIEW_REQUEST": (state) => {
        return {
            ...state,
            vloading: true,
            vfailure: false,
        };
    },

    "rate/FETCH_RATE_VIEW_SUCCESS": (state, action) => {
        const { count, history, info, total } = action.payload;

        return {
            ...state,
            rateHistory: {
                count,
                total,
                items: history,
            },
            studentInfo: info,
            vloading: false,
        };
    },

    "rate/FETCH_RATE_VIEW_PAGING_FAILURE": (state) => {
        return {
            ...state,
            vloading: false,
            vfailure: true,
        };
    },

    "rate/FETCH_RATE_VIEW_PAGING_REQUEST": (state) => {
        return {
            ...state,
            vloading: true,
            vfailure: false,
        };
    },

    "rate/FETCH_RATE_VIEW_PAGING_SUCCESS": (state, action) => {
        return {
            ...state,
            rateHistory: {
                ...state.rateHistory,
                items: [...state.rateHistory.items, ...action.payload],
            },
            vloading: false,
        };
    },

    // rate receiver student
    "rate/FETCH_RATE_RECEIVER_FAILURE": (state) => {
        return {
            ...state,
            sloading: false,
        };
    },

    "rate/FETCH_RATE_RECEIVER_REQUEST": (state) => {
        return {
            ...state,
            sloading: true,
        };
    },

    // 평점 학생 불러오기 리스트
    "rate/FETCH_RATE_RECEIVER_SUCCESS": (state, action) => {
        const seperateClass: Array<RateReceiver> = [];

        const classUnique = onlyKeyV2<ReceiverType, "grade" | "class_name">(
            action.payload,
            ["grade", "class_name"]
        );

        classUnique.forEach((c) => {
            let classRoom = action.payload.filter(
                (item) =>
                    item.grade === c.grade && item.class_name === c.class_name
            );

            // 이미 tempStore에 있으면 check 되서 들어간다.
            classRoom = classRoom.map((student) => {
                const intoTemp = state.tempSelectStore.findIndex(
                    ({ id }) => id === student.id
                );
                return {
                    ...student,
                    checked: intoTemp !== -1,
                };
            });

            if (!isEmpty(classRoom[0])) {
                const { class_name, grade } = classRoom[0];
                seperateClass.push({
                    grade,
                    class_name,
                    count: [0, classRoom.length],
                    members: classRoom,
                    checked: false,
                    uid: uniqueKey(),
                });
            }
        });

        return {
            ...state,
            sloading: false,
            sempty: isZero(seperateClass.length),
            rStudents: seperateClass,
        };
    },

    "rate/FETCH_RATE_INSERT_FAILURE": (state) => {
        return state;
    },

    "rate/FETCH_RATE_INSERT_REQUEST": (state) => {
        return {
            ...state,
            iloading: true,
        };
    },

    "rate/FETCH_RATE_INSERT_SUCCESS": (state) => {
        return {
            ...state,
        };
    },

    // rate category
    "rate/FETCH_CALL_CATEGORY_FAILURE": (state) => {
        return state;
    },

    "rate/FETCH_CALL_CATEGORY_REQUEST": (state) => {
        return state;
    },

    "rate/FETCH_CALL_CATEGORY_SUCCESS": (state, action) => {
        if (action.payload.type === "0") {
            return {
                ...state,
                bigCategory: action.payload.data,
            };
        }

        if (action.payload.type === "1") {
            return {
                ...state,
                smallCategory: action.payload.data,
            };
        }

        return state;
    },

    "rate/FETCH_RATE_SEARCH_FAILURE": (state) => {
        return {
            ...state,
            loading: false,
        };
    },

    "rate/FETCH_RATE_SEARCH_REQUEST": (state) => {
        return {
            ...state,
            loading: true,
        };
    },

    "rate/FETCH_RATE_SEARCH_SUCCESS": (state, action) => {
        const { data, count } = action.payload;

        return {
            ...state,
            loading: false,
            searchs: [...state.searchs, ...data],
            scount: count,
            empty: isZero(data.length),
        };
    },

    "rate/FETCH_PHONE_INFO_FAILURE": (state) => {
        return state;
    },

    "rate/FETCH_PHONE_INFO_REQUEST": (state) => {
        return state;
    },

    "rate/FETCH_PHONE_INFO_SUCCESS": (state, action) => {
        return {
            ...state,
            studentInfo: action.payload[0],
        };
    },
});
