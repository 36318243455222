import React, { createContext, Component } from "react";
import AlertWrapper from "./AlertWrapper";
import { uniqueKey } from "../lib/utils";

export type AlertType = "SUCCESS" | "ERROR" | "WARNING" | "INFO";
export type AlertLowerType = "success" | "error" | "warning" | "info";

export interface PbAlertProps {
    duration?: number;
    children?: React.ReactNode;
}

export interface AlertItem {
    type: AlertType;
    content: string;
}

export interface AlertUseage {
    type: AlertLowerType;
    content: string;
    uid: string;
    onClose: () => void;
}

export interface AlertContextValue {
    show: (alert: AlertItem) => void;
    success: (content: string) => void;
    info: (content: string) => void;
    error: (content: string) => void;
    warning: (content: string) => void;
    remove: (uid: string) => void;
}

interface PbAlertState {
    alerts: Array<AlertUseage>;
}

export const DEFAULT_OPTION = {
    duration: 3500
};

export const AlertContext = createContext<AlertContextValue>(null);

class PbAlertProvider extends Component<PbAlertProps, PbAlertState> {
    state: PbAlertState = {
        alerts: []
    };

    show = (alert: AlertItem) => {
        const uid = uniqueKey();

        let item: AlertUseage = {
            ...alert,
            type: alert.type.toLocaleLowerCase() as AlertLowerType,
            uid,
            onClose: () => this.remove(uid)
        };

        this.setState((prevState) => {
            return {
                alerts: [item, ...prevState.alerts]
            };
        });

        setTimeout(() => {
            this.remove(uid);
        }, this.props.duration);
    };

    success = (content: string) => {
        this.show({
            type: "SUCCESS",
            content
        });
    };

    info = (content: string) => {
        this.show({
            type: "INFO",
            content
        });
    };

    error = (content: string) => {
        this.show({
            type: "ERROR",
            content
        });
    };

    warning = (content: string) => {
        this.show({
            type: "WARNING",
            content
        });
    };

    remove = (uid: string): void => {
        this.setState((prevState) => {
            let newItems = prevState.alerts.filter((item) => uid !== item.uid);
            return {
                alerts: [...newItems]
            };
        });
    };

    render() {
        const { show, success, info, error, warning, remove } = this;

        const actions: AlertContextValue = {
            show,
            success,
            info,
            error,
            warning,
            remove
        };

        return (
            <AlertContext.Provider value={actions}>
                {this.props.children}
                <AlertWrapper
                    alerts={this.state.alerts}
                    duration={this.props.duration}
                />
            </AlertContext.Provider>
        );
    }
}

function factory({ duration = 3500, children }: PbAlertProps) {
    return <PbAlertProvider children={children} duration={duration} />;
}

export default factory;
