import {
    ActionType,
    createAction,
    createAsyncAction,
    createReducer
} from "typesafe-actions";
import { hasOwnProperty } from "../lib/utils";

export type TApproval = {
    approval_status: number;
    created_at: string;
    message: string;
    message_read_count: number;
    message_request_id: number;
    message_send_count: number;
    message_type: number;
    name: string;
    request_user: number;
    send_date: string | null;
    send_status: number;
    send_type: number;
};

export const UPDATE_APPROVAL_ITEM = "approval/UPDATE_APPROVAL_ITEM";

export const updateApprovalItem = createAction(
    UPDATE_APPROVAL_ITEM,
    (action) => (id: number, status: number) => action({ id, status })
);

export const fetchApprovalAsync = createAsyncAction(
    "approval/FETCH_APPROVAL_REQUEST",
    "approval/FETCH_APPROVAL_SUCCESS",
    "approval/FETCH_APPROVAL_FAILURE"
)<boolean, { count: number; data: Array<TApproval> }, Error>();

const actions = {
    updateApprovalItem,
    fetchApprovalAsync
};

export type ApprovalAction = ActionType<typeof actions>;

export type ApprovalState = {
    approvals: TApproval[];
    loading: boolean;
    failure: boolean;
    count: number;
    page: number;
    limit: number;
    access_deny: boolean;
};

const initialState: ApprovalState = {
    approvals: [],
    loading: false,
    failure: false,
    count: 0,
    page: 1,
    limit: 20,
    access_deny: false
};

export default createReducer<ApprovalState, ApprovalAction>(initialState, {
    "approval/UPDATE_APPROVAL_ITEM": (state, action) => {
        const { id, status } = action.payload;
        const copy = [...state.approvals];

        const index = copy.findIndex(
            ({ message_request_id }) => message_request_id === id
        );
        copy[index].approval_status = status;

        return { ...state, approvals: [...copy] };
    },

    "approval/FETCH_APPROVAL_REQUEST": (state) => {
        return { ...state, loading: true };
    },

    "approval/FETCH_APPROVAL_SUCCESS": (state, action) => {
        let { data, count } = action.payload;

        return {
            ...state,
            approvals: data,
            count,
            loading: false,
            failure: false
        };
    },

    "approval/FETCH_APPROVAL_FAILURE": (state, action) => {
        let access_deny = false;

        if (
            hasOwnProperty(action, "payload") &&
            typeof action.payload === "string"
        ) {
            access_deny = /(권한)/g.test(action.payload as string);
        }

        return { ...state, loading: false, failure: true, access_deny };
    }
});
