import React, { DOMAttributes } from "react";
import styled, { css } from "styled-components";
import { TemplateColor, GlobalSize } from "../style/theme";

export interface PbLabelProps extends DOMAttributes<HTMLDivElement> {
    className?: string;
}

export interface PbLabelStyleProps {
    color?: TemplateColor;
    size?: GlobalSize;
}

const PbLabelStyled = styled.div.attrs((props) => ({
    className: `pb-label ${props.className}`,
}))<PbLabelStyleProps>`
    display: inline-block;
    border-radius: 3px;

    ${(props) => props.theme[props.color]}

    ${(props) => {
        switch (props.size) {
            case "large":
                return css`
                    padding: 6px 12px;
                    font-size: 1.5rem;
                `;
            case "small":
                return css`
                    min-width: 32px;
                    padding: 5px 8px;
                    font-size: 1rem;
                `;
            default:
                return css`
                    padding: 6px 10px;
                    font-size: 1.3rem;
                `;
        }
    }}
`;

const PbLabel: React.SFC<PbLabelProps & PbLabelStyleProps> = ({
    children,
    className,
    size = "default",
    color = "disabled",
}) => {
    return (
        <PbLabelStyled className={className} color={color} size={size}>
            {children}
        </PbLabelStyled>
    );
};

export default PbLabel;
