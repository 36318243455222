import { put, takeEvery, call, select } from "redux-saga/effects";

import { default as userAPI } from "../../api/user";
import { default as schoolAPI } from "../../api/school";
import { default as rateAPI } from "../../api/rate";

import { setHeaders } from "../../api/configure";
import { history } from "..";

import {
    encodeQuery,
    isEmpty,
    isZero,
    returnAllowSchool,
} from "../../lib/utils";
import { SchoolType } from "../school";

import {
    fetchTokenAsync,
    fetchSearchReceiverAsync,
    fetchCheckAppInstallAsync,
    fetchCallGroupAsync,
    fetchApprovalListAsync,
    fetchTeachersAsync,
    fetchDepartsTeacherAsync,
    GroupType,
    FReceiverType,
    TeacherType,
} from "../user";

import { setSchool } from "../school";
import { alertToggle } from "../base";
import { changeRate } from "../rate";

function* fetchToken(action: ReturnType<typeof fetchTokenAsync.request>) {
    try {
        const response = yield call(
            userAPI.getSession,
            encodeQuery({ token: action.payload })
        );

        yield setHeaders(response.data.session);
        localStorage.setItem("session", response.data.session);

        const response_school: { data: Array<SchoolType> } = yield call(
            schoolAPI.getMySchool,
            response.data.session
        );

        // 입장 가능한 학교를 찾는다.
        const startSchool = returnAllowSchool(response_school.data);

        if (startSchool !== -1) {
            const loginSchool = response_school.data[startSchool];

            localStorage.setItem(
                "schools",
                JSON.stringify(response_school.data)
            );
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem(
                "user",
                JSON.stringify(response.data.user_info)
            );

            yield put(setSchool(response_school.data, loginSchool));
            yield put(fetchTokenAsync.success(response.data));
            history.push("/message");
        }

        if (startSchool === -1) {
            history.push("/not_allow");
        }
    } catch (error) {
        // auth check and history push in api configure
        yield put(fetchTokenAsync.failure(error.message));
    }
}

function* fetchCheckAppInstall(
    action: ReturnType<typeof fetchCheckAppInstallAsync.request>
) {
    try {
        const { data } = yield call(userAPI.getCheckAppInstall, {
            numbers: JSON.stringify([action.payload]),
        });

        yield put(
            fetchCheckAppInstallAsync.success({ data, number: action.payload })
        );
    } catch (error) {
        yield put(fetchCheckAppInstallAsync.failure(error.message));
    }
}

function* fetchSearchReceiver(
    action: ReturnType<typeof fetchSearchReceiverAsync.request>
) {
    const state = yield select();
    const { searchWord, type } = action.payload;

    try {
        if (isEmpty(searchWord)) {
            yield put(fetchSearchReceiverAsync.success({ data: [], type }));
            return;
        }

        const { data } = yield call(
            rateAPI.getRateReceiver,
            encodeQuery({
                school_id: state.school.myschool.id,
                search_word: searchWord,
                search_type: 0,
            })
        );

        yield put(fetchSearchReceiverAsync.success({ data, type }));
    } catch (error) {
        yield put(fetchSearchReceiverAsync.failure(error.message));
    }
}

function* fetchCallGroup(
    action: ReturnType<typeof fetchCallGroupAsync.request>
) {
    const state = yield select();
    const { searchWord = null, type } = action.payload;

    try {
        const response = yield call(
            userAPI.getGroup,
            encodeQuery({
                group_type: type,
                school_id: state.school.myschool.id,
                search_word: searchWord,
            })
        );

        const withMember = yield Promise.all(
            response.data.map(async (props: GroupType) => {
                let members = await userAPI.getGroupMembers(
                    encodeQuery({
                        school_id: state.school.myschool.id,
                        group_id: props.id,
                    })
                );

                return {
                    ...props,
                    members: members.data.map((props: FReceiverType) => ({
                        ...props,
                        checked: false,
                    })),
                    checked: false,
                };
            })
        );

        yield put(
            fetchCallGroupAsync.success({
                data: withMember || [],
                type,
            })
        );
    } catch (error) {
        yield put(fetchCallGroupAsync.failure(error.message));
    }
}

// 한번만 호출하게 한다.
function* fetchApprovalList(
    action: ReturnType<typeof fetchApprovalListAsync.request>
) {
    const state = yield select();

    try {
        if (isZero(state.user.approvals.length)) {
            const school_id = state.school.myschool.id;
            const response = yield call(
                userAPI.getApprovals,
                encodeQuery({ school_id })
            );
            yield put(fetchApprovalListAsync.success(response.data));
        }
    } catch (error) {
        yield put(fetchApprovalListAsync.failure(error.message));
    }
}

function* fetchTeachers(action: ReturnType<typeof fetchTeachersAsync.request>) {
    const state = yield select();
    const { isRate, searchWord } = action.payload;

    const options = isRate
        ? {
              search_type: 0,
              page: 1,
          }
        : {
              is_phone_exist: 1,
              search_word: searchWord,
          };

    try {
        const school_id = state.school.myschool.id;

        const { data } = yield call(
            userAPI.getTeachers,
            encodeQuery({
                school_id,
                ...options,
            })
        );

        // 생평 부여자 기본값 지정
        if (isRate) {
            const _data = data as TeacherType[];

            const initialAddedBy = _data.find(
                ({ teacher_idx }) =>
                    teacher_idx === state.school.myschool.teacher_id
            );

            yield put(changeRate({ name: "added_by", value: initialAddedBy }));
        }

        yield put(fetchTeachersAsync.success({ data, isRate: isRate }));
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );

        yield put(fetchTeachersAsync.failure(error));
    }
}

function* fetchDepartsTeachers(
    action: ReturnType<typeof fetchDepartsTeacherAsync.request>
) {
    const state = yield select();
    const { searchWord } = action.payload;

    try {
        const school_id = state.school.myschool.id;

        const response = yield call(
            userAPI.getTeacherGroups,
            encodeQuery({ school_id, search_word: searchWord })
        );

        const data = response.data.map((props: GroupType, index: number) => {
            let members = props.members || [];

            members = members.map((childProps: FReceiverType) => ({
                ...childProps,
                checked: false,
            }));

            return {
                id: index,
                ...props,
                members,
                checked: false,
            };
        });

        // fetchCallGroup success로 우회 시킨다.
        yield put(fetchCallGroupAsync.success({ data, type: 3 }));
        yield put(fetchDepartsTeacherAsync.success());
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );

        yield put(fetchDepartsTeacherAsync.failure(error));
    }
}

export default [
    takeEvery(fetchTokenAsync.request, fetchToken),
    takeEvery(fetchSearchReceiverAsync.request, fetchSearchReceiver),
    takeEvery(fetchCheckAppInstallAsync.request, fetchCheckAppInstall),
    takeEvery(fetchCallGroupAsync.request, fetchCallGroup),
    takeEvery(fetchApprovalListAsync.request, fetchApprovalList),
    takeEvery(fetchTeachersAsync.request, fetchTeachers),
    takeEvery(fetchDepartsTeacherAsync.request, fetchDepartsTeachers),
];
