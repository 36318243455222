import React from "react";
import styled from "styled-components";

export interface PbTabProps {
    tabId: number;
}

export interface PbTabStyleProps {
    active?: any;
}

const PbTabStyled = styled.div<PbTabStyleProps>``;

const PbTab: React.SFC<PbTabProps & PbTabStyleProps> = ({
    children,
    tabId,
    active
}) => {
    if (tabId !== active) {
        return <div></div>;
    }

    return (
        <PbTabStyled className="pb-tab" data-tabindex={tabId}>
            {children}
        </PbTabStyled>
    );
};

export default PbTab;
