import React, { useEffect, useRef, useCallback, useState } from "react";
import styled from "styled-components";
import { agentEvent } from "../lib/utils";

import PbDropdownItem, { PbDropdownItemProps } from "./PbDropdownItem";
import PbDropdownItems from "./PbDropdownItems";
import { PureFunction } from "../lib/types";

export interface PbDropdownProps {
    children: string;
    items: Array<PbDropdownItemProps>;
}

export interface PbDropdownStyleProps {}

const PbDropdownStyled = styled.div.attrs({})<PbDropdownStyleProps>`
    position: relative;
    display: inline-block;

    label {
        display: inline-block;
        width: 100%;
        cursor: pointer;
    }
`;

const event = agentEvent("click");

const PbDropdown: React.SFC<PbDropdownProps & PbDropdownStyleProps> = ({
    children,
    items
}) => {
    const [active, setActive] = useState<boolean>(false);
    const documentListener = useRef<null | PureFunction>(null);

    const unbindDocumentListener = useCallback(() => {
        if (documentListener.current) {
            document.removeEventListener(event, documentListener.current);
            documentListener.current = null;
        }
    }, []);

    const bindDocumentListener = () => {
        if (!documentListener.current) {
            documentListener.current = () => {
                if (!active) hide();
            };
        }

        document.addEventListener(event, documentListener.current);
    };

    const show = () => {
        setActive(true);
        bindDocumentListener();
    };

    const hide = () => {
        setActive(false);
        unbindDocumentListener();
    };

    const clickHandler = () => (!active ? show() : hide());

    useEffect(() => {
        return function cleanup() {
            if (documentListener.current) unbindDocumentListener();
        };
    }, [unbindDocumentListener]);

    return (
        <PbDropdownStyled>
            <label onClick={clickHandler}>{children}</label>
            <PbDropdownItems active={active}>
                {items.map(({ label, ...props }, index) => (
                    <PbDropdownItem key={index} {...props}>
                        {label}
                    </PbDropdownItem>
                ))}
            </PbDropdownItems>
        </PbDropdownStyled>
    );
};

export default PbDropdown;
