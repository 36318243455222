import React, { useEffect, useRef, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { isEmpty, isZero, returnAllowSchool } from "../lib/utils";
import { useToggle } from "../lib/hooks";

import {
    SchoolState,
    setSchool,
    SchoolType,
    changeSchool
} from "../modules/school";
import { BaseState, closeConfirm, alertClose } from "../modules/base";

import RootHeader from "../common/RootHeader";
import Content from "../common/Content";
import Container from "../common/Container";
import TabMenu from "../common/TabMenu";
import PbPrompt from "../shared/PbPrompt";
import { PbConfirm, PbPickerModal } from "../shared";
import { messageCleanup, MessageState } from "../modules/message";
import { AlertContext } from "../shared/PbAlert";

// header, footer, container visible controll
import { RootContext } from "../common/RootProvider/RootWrapper";

// Not Lazy, Suspense
import { Message, Student, Rate, Approval } from "./";
import { RateState } from "../modules/rate";
import { setUser } from "../modules/user";

const SchoolPick: React.SFC<SchoolType> = ({ name }) => <div>{name}</div>;

const RootPage: React.SFC<{ visible?: boolean }> = ({ visible }) => {
    const [pickerModal, onPickerModal] = useToggle(false);
    const containerRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [
        { myschool, schools },
        { confirm, prompt, alert: _alert, alertToggle },
        { spopup: mspopup },
        { spopup: rspopup }
    ] = useSelector(
        (state: {
            school: SchoolState;
            base: BaseState;
            message: MessageState;
            rate: RateState;
        }) => [state.school, state.base, state.message, state.rate]
    );

    const alert = useContext(AlertContext);
    const root = useContext(RootContext);

    const pickHandler = useCallback(
        (item: SchoolType) => {
            if (item.permission === -1) {
                alert.error("이용정지된 학교 입니다.");
                return false;
            }

            if (item.permission === 0) {
                alert.warning("관리자에게 승인을 받아야합니다.");
                return false;
            }

            if (item.status === 0) {
                alert.warning("승인 대기중인 학교 입니다.");
                return false;
            }

            if (item.id === myschool.id) {
                return false;
            }

            dispatch(changeSchool(item));
            dispatch(messageCleanup("all"));
            history.replace("/message");

            return true;
        },
        [dispatch, history, myschool, alert]
    );

    const confirmHandler = useCallback(() => {
        confirm.onConfirm();
        dispatch(closeConfirm());
    }, [dispatch, confirm]);

    const confirmCloseHandler = useCallback(() => {
        if (!isEmpty(confirm.onCancle)) {
            confirm.onCancle();
        }

        dispatch(closeConfirm());
    }, [dispatch, confirm]);

    const promptHandler = useCallback(
        (value) => prompt.onConfirm(value),
        [prompt]
    );

    const promptCloseHandler = useCallback(() => prompt.onCancle(), [prompt]);

    useEffect(() => {
        if (isEmpty(localStorage.getItem("session"))) {
            // Without session, it is a failure
            history.push("/fail");
        }

        if (!myschool) {
            // let userId = localStorage.getItem("userId");
            let schools = JSON.parse(localStorage.getItem("schools"));
            let user = JSON.parse(localStorage.getItem("user"));

            if (isEmpty(schools) || isZero(schools.length) || isEmpty(user)) {
                // Without school list, it is a failure
                history.replace("/fail");
            } else {
                const startSchool = returnAllowSchool(schools);

                if (startSchool !== -1) {
                    // Without myschool value in store, Get localstorage value 'schools'
                    // and push into store school state
                    const loginSchool = schools[startSchool];
                    dispatch(setSchool(schools, loginSchool));
                    dispatch(setUser(user));
                }
            }
        }

        // message, rate 모두 search popup state가 false 인 경우 top bottom 을 보여준다.
        if (!(mspopup || rspopup)) {
            root.onVisible(
                /^\/(message|approval|student|rate)$/g.test(location.pathname)
            );
        }

        if (alertToggle) {
            alert.show(_alert);

            setTimeout(() => {
                dispatch(alertClose());
            }, 2400);
        }

        return function cleanup() {};
    }, [
        dispatch,
        myschool,
        history,
        location,
        alertToggle,
        _alert,
        mspopup,
        rspopup
    ]);

    if (isEmpty(myschool)) {
        return <div></div>;
    }

    return (
        <>
            <RootHeader
                visible={visible}
                schoolName={myschool.name}
                accBalance={myschool.acc_balance}
                accCount={myschool.acc_count}
                onSchool={onPickerModal}
            />
            <Container visible={visible} forwardRef={containerRef}>
                <Content>
                    <Switch>
                        <Route path="/message" component={Message}></Route>
                        <Route path="/approval" component={Approval}></Route>
                        <Route path="/student" component={Student}></Route>
                        <Route path="/rate" component={Rate}></Route>
                    </Switch>
                </Content>
            </Container>
            <TabMenu visible={visible} />

            <PbConfirm
                toggle={confirm.toggle}
                title={confirm.title}
                contents={confirm.contents}
                onConfirm={confirmHandler}
                onCancle={confirmCloseHandler}
            />

            <PbPrompt
                toggle={prompt.toggle}
                title={prompt.title}
                value={prompt.value}
                onConfirm={promptHandler}
                onCancle={promptCloseHandler}
            />

            <PbPickerModal
                toggle={pickerModal}
                pickShape={SchoolPick}
                items={schools}
                onPick={pickHandler}
                onClose={() => onPickerModal()}
            />
        </>
    );
};

export default RootPage;
