import { createStore, applyMiddleware } from "redux";
import { createHashHistory } from "history";
// import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import reducers from "./configure";
import sagas from "./sagas";

// const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(sagas);

// 나중에 history mode 로 바꿔야함
export const history = createHashHistory();

export default store;
