import React from "react";
import styled, { css } from "styled-components";
import { DivClickEvent } from "../lib/types";
import { isString } from "../lib/utils";

export interface PbModalProps {
    children: React.ReactNode;
    toggle: boolean;
    beforeClose?: () => void;
    onClose?: () => void;
    modalWidth?: string;
}

const PbModalStyled = styled.div<PbModalProps>`
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 0.35s;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        background-color: #ffffff;
        width: ${props => props.modalWidth};
        margin-left: auto;
        margin-right: auto;
    }

    ${props =>
        !props.toggle
            ? css`
                  opacity: 0;
                  visibility: hidden;
              `
            : css`
                  opacity: 1;
                  visibility: visible;
              `};
`;

const PbModal: React.SFC<PbModalProps> = ({
    children,
    toggle,
    beforeClose,
    modalWidth = "80vw",
    onClose
}) => {
    const closeHandler = (ev: DivClickEvent) => {
        let className = ev.target.className;

        if (isString(className) && className.includes("pb-modal")) {
            if (beforeClose) {
                setTimeout(() => {
                    beforeClose();
                }, 250);
            }
            if (onClose) onClose();
        }
    };

    return (
        <PbModalStyled
            className="pb-modal"
            toggle={toggle}
            modalWidth={modalWidth}
            onClick={closeHandler}
        >
            <div className="modal-content">{children}</div>
        </PbModalStyled>
    );
};

export default PbModal;
