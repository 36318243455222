import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    IconPrefix,
    IconName,
    IconProp
} from "@fortawesome/fontawesome-svg-core";
import { color as theme, Color } from "../style/theme";

export interface FaIconProps {
    prefix: IconPrefix;
    name: IconName;
    color?: Color;
    fill?: boolean;
    className?: string;
    onClick?: () => void;
    onMouseDown?: () => void;
    onMouseUp?: () => void;
    onTouchStart?: () => void;
    mask?: IconProp;
}

export default ({
    prefix,
    name,
    mask,
    color,
    fill,
    className,
    onClick,
    onMouseDown,
    onMouseUp
}: FaIconProps) => {
    return (
        <FontAwesomeIcon
            className={className}
            icon={[prefix, name]}
            color={theme[color] || theme.gray}
            mask={mask}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        />
    );
};
