import fetch, { pure } from "./configure";

export default {
    // [GET] token to session
    getSession: (params: string) => fetch.get(`/identify/session?${params}`),

    // check App Install State :: checkAppInstall2
    // [GET] check install app for numbers<Array to String>
    getCheckAppInstall: (data: { numbers: string }) =>
        pure.post("http://schoolmom.co.kr:3001/checkAppInstall2", data),

    // [GET] receivers
    // getSearchReceivers: (params: string) =>
    //     fetch.get(`/student/message_students?${params}`),

    // [GET] group
    getGroup: (params: string) => fetch.get(`/group/group?${params}`),

    // [GET] group member
    getGroupMembers: (params: string) => fetch.get(`/group/member?${params}`),

    // [GET] approvals
    getApprovals: (params: string) =>
        fetch.get(`/teacher/approval_list?${params}`),

    getTeachers: (params: string) => fetch.get(`/teacher/teachers?${params}`),

    getTeacherGroups: (params: string) =>
        fetch.get(`/message/departs/teacher?${params}`),
};
