import React from "react";
import styled from "styled-components";
import { isEmpty } from "../lib/utils";

export interface PbLineProps {
    distance?: number;
}

const PbLineStyled = styled.div<PbLineProps>`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.color.line};

    margin-bottom: ${props =>
        isEmpty(props.distance) ? 10 : props.distance * 10}px;
`;

const PbLine: React.SFC<PbLineProps> = ({ distance }) => (
    <PbLineStyled distance={distance} />
);

export default PbLine;
