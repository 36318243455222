import React from "react";
import styled from "styled-components";

export interface ContentProps {}

const ContentStyled = styled.div<ContentProps>`
    position: relative;
    overflow-y: hidden;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .page-content {
        flex: 1;
        margin-top: 60px;
        overflow-x: hidden;

        &.no-content {
            margin-top: -1px;
            padding-bottom: 60px;
            height: calc(100vh - 60px);
        }
    }
`;

const Content: React.SFC<ContentProps> = ({ children }) => {
    return <ContentStyled>{children}</ContentStyled>;
};

export default Content;
