import React from "react";
import styled from "styled-components";
import PbModal from "./PbModal";
import { PbCols } from ".";
import PbRow from "./PbRow";
import FaIcon from "../common/FaIcon";

export interface PbPickerModalProps {
    items: object[];
    title?: string;
    pickShape: React.SFC<any>;
    beforeClose?: () => void;
    onClose: () => void;
    onPick?: (items: any) => boolean;
}

export interface PbPickerModalStyleProps {
    toggle: boolean;
    disabled?: boolean;
}

const PbPickerModalStyled = styled.div.attrs({
    className: "pb-picker-modal"
})<PbPickerModalStyleProps>`
    position: fixed;
    left: 0;
    background-color: #fff;
    width: 100vw;
    padding-top: 18px;
    padding-bottom: 16px;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 12px 12px 0 0;
    transition: all 0.35s;

    bottom: ${(props) => (props.toggle ? "0" : "-26vh")};

    .pb-picker-item {
        ${(props) => props.disabled && props.theme.disabled}
        font-size: 1.6rem;

        & > div {
            padding: 0 24px;
            &:active {
                background-color: ${(props) => props.theme.color.line};
            }
        }

        & .linear > div {
            padding: 16px 0;
        }

        & + .pb-picker-item > .linear > div {
            border-top: 1px solid ${(props) => props.theme.color.line};
        }
    }

    .pb-picker-title {
        font-size: 1.4rem;
        padding: 0rem 2.4rem 0.6rem 2.4rem;
        color: #a3a3a3;

        svg {
            font-size: 2.4rem;
        }
    }

    .grap {
        position: absolute;
        top: -1.4rem;
        left: 50%;
        height: 12px;
        transform: translateX(-50%);
    }

    .grap-bar {
        margin-top: -0.6rem;
        width: 20vw;
        height: 6px;
        border-radius: 24px;
        background-color: rgba(255, 255, 255, 0.75);
    }
`;

const PbPickItemsStyle = styled.div.attrs({
    className: "pb-picker-items"
})`
    max-height: 42vh;
    min-height: 0vh;
    overflow-y: auto;
`;

const PbPickerModal: React.SFC<
    PbPickerModalProps & PbPickerModalStyleProps
> = ({
    toggle,
    items,
    title,
    disabled,
    pickShape: PickShape,
    beforeClose,
    onClose,
    onPick
}) => {
    const closeHandler = () => {
        if (beforeClose) {
            setTimeout(() => {
                beforeClose();
            }, 250);
        }
        onClose();
    };

    const picks = items.map((items, index) => {
        return (
            <div
                className="pb-picker-item"
                key={index}
                onMouseDown={() => {
                    let next = false;
                    if (onPick) next = onPick(items);
                    if (next) closeHandler();
                }}
            >
                <div className="linear">
                    <PickShape {...items} />
                </div>
            </div>
        );
    });

    return (
        <PbModal
            toggle={toggle}
            onClose={closeHandler}
            beforeClose={beforeClose}
        >
            <PbPickerModalStyled toggle={toggle} disabled={disabled}>
                <PbRow
                    className="pb-picker-title"
                    justify="space-between"
                    align="center"
                >
                    <PbCols>{title && <div>{title}</div>}</PbCols>
                    <PbCols>
                        <FaIcon
                            prefix="fal"
                            name="times"
                            onClick={closeHandler}
                        />
                    </PbCols>
                </PbRow>
                <PbPickItemsStyle>{picks}</PbPickItemsStyle>
            </PbPickerModalStyled>
        </PbModal>
    );
};

export default PbPickerModal;
