import React, {
    InputHTMLAttributes,
    ChangeEvent,
    useCallback,
    useEffect
} from "react";
import styled from "styled-components";
import { useToggle } from "../lib/hooks";

import AbledCheck from "../assets/abled_check.png";
import DisabledCheck from "../assets/disabeld_check.png";

export interface PbCheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
    id?: string;
}

const PbCheckBoxStyled = styled.label<PbCheckBoxProps>`
    .checkbox-visual {
        width: 18px;
        height: 18px;
        line-height: 18px;
        background-size: cover;
        background-position: center;

        &.abled_check {
            background-image: url("${AbledCheck}");
        }

        &.disabled_check {
            background-image: url("${DisabledCheck}");
        }
    }

    input[type="checkbox"] {
        display: none;
    }
`;

const PbCheckBox: React.SFC<PbCheckBoxProps> = ({
    defaultChecked = false,
    checked = false,
    disabled,
    readOnly,
    id,
    onChange
}) => {
    const [toggle, onToggle] = useToggle(defaultChecked || checked);

    const changeHandler = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            onToggle();

            if (onChange) {
                onChange(ev);
            }
        },
        [onChange, onToggle]
    );

    useEffect(() => {
        if (checked !== toggle) {
            onToggle(checked);
        }
    }, [toggle, checked, onToggle]);

    return (
        <PbCheckBoxStyled className="pb-checkbox">
            <input
                type="checkbox"
                checked={toggle}
                disabled={disabled}
                id={id}
                onChange={changeHandler}
                readOnly={readOnly}
            />
            <div
                className={`checkbox-visual ${
                    toggle ? "abled_check" : "disabled_check"
                }`}
            />
        </PbCheckBoxStyled>
    );
};

export default PbCheckBox;
