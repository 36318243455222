import React from "react";
import styled from "styled-components";
import { PbFloat } from "../shared";
import FaIcon from "./FaIcon";
import { threeComma } from "../lib/utils";

export interface RootHeaderProps {
    count?: number;
    visible: boolean;
    schoolName: string | null;
    accBalance: number;
    accCount: number;
    onSchool: () => void;
}

const RootHeaderStyled = styled.div<{ visible: boolean }>`
    position: fixed;
    left: 0;
    top: ${(props) => (props.visible ? "0" : "-60px")};
    z-index: 1000;
    transition: top 0.35s;

    .header {
        position: relative;
        width: 100vw;
        font-size: 1.6rem;
        display: flex;
        text-align: center;
        background-color: #fff;
        border-bottom: 1px solid ${(props) => props.theme.color.line};
        z-index: 1;
    }

    .side {
        flex: 1;
        height: 60px;
        line-height: 60px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .header-menu {
        position: relative;
    }

    .header-main {
        display: flex;
        flex: 2;
        height: 60px;
        justify-content: center;
        align-items: center;

        &__title {
            font-size: 1.8rem;
        }

        &__info,
        svg {
            font-size: 1.1rem;
            margin-top: 2px;
            color: ${(props) => props.theme.color.primary};
        }
    }
`;

const RootHeader: React.SFC<RootHeaderProps> = ({
    visible,
    schoolName,
    accBalance,
    accCount,
    onSchool
}) => {
    return (
        <RootHeaderStyled visible={visible}>
            <div className="header">
                <PbFloat float="left" textAlign="left" className="side" />

                <div className="header-main">
                    <div className="header-main__title">
                        {schoolName}
                        <div className="header-main__info">
                            {Math.floor(accCount) === 0 ? (
                                <>
                                    <FaIcon prefix="fas" name="coins" />{" "}
                                    잔여금액 {threeComma(accBalance)} 원
                                </>
                            ) : (
                                <>
                                    <FaIcon
                                        prefix="fas"
                                        name="envelope-square"
                                    />{" "}
                                    잔여건수 {threeComma(accCount)} 건
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <PbFloat
                    float="right"
                    textAlign="right"
                    className="side"
                    onClick={onSchool}
                >
                    학교 전환
                </PbFloat>
            </div>
        </RootHeaderStyled>
    );
};

export default RootHeader;
