import { isNumber } from "./utils";

// export function yearRange(range = 1, type = "array", between = ":") {
//     const result = [year(), year() + range];

//     switch (type) {
//         case "string":
//             return result.join(between);
//         default:
//             return result;
//     }
// }

export type DateType = number | string | Date;
export type FormatType = "utc" | "iso";
export type TimeFlowOption = {
    format: string;
    hour: number;
};

class TimeFlow {
    private _clock: Date;
    private _timezone: number = 0;
    private _type: FormatType = "utc";
    private defaultOptions: TimeFlowOption = {
        format: "yyyy-MM-DD",
        hour: 1000 * 60 * 60,
    };

    constructor(date: DateType) {
        if (isNumber(date)) {
            this._clock = new Date(date);
        } else {
            const zone = /(T|Z)/g.test(date.toString());
            const combinePlatform = zone
                ? date
                : date.toString().replace(/-/g, "/");

            this._clock = new Date(combinePlatform);
        }
    }

    format(fm: string = this.defaultOptions.format) {
        const d = new Date(this._clock);

        return fm.replace(
            /(yyyy|mm|dd|MM|DD|H|h|loc|i|s|a)/g,
            (t: string): any => {
                switch (t) {
                    case "yyyy":
                        return d.getFullYear();
                    case "mm":
                        return d.getMonth() + 1;
                    case "dd":
                        return d.getDate();
                    case "MM":
                        return this.iZero(d.getMonth() + 1);
                    case "DD":
                        return this.iZero(d.getDate());
                    case "H":
                        return this.iZero(d.getHours());
                    case "h":
                        return this.minHour(d.getHours());
                    case "i":
                        return this.iZero(d.getMinutes());
                    case "s":
                        return this.iZero(d.getSeconds());
                    case "a":
                        return this.krTime(d.getHours()); // 오전 오후
                    case "loc":
                        return "T" + this.iZero(d.getHours()); // local : using for input type='datetime-local'
                    default:
                        return "";
                }
            }
        );
    }

    timezone(time?: number) {
        this._timezone = time;
        return this;
    }

    setType(type?: FormatType) {
        this._type = type;
        return this;
    }

    getDate(): string {
        if (this._type === "utc") {
            return this._clock.toUTCString();
        } else {
            let tzoffset = 0;
            if (this._timezone) {
                tzoffset =
                    this._clock.getTimezoneOffset() -
                    this.defaultOptions.hour * this._timezone;
            }

            const localISOTime = new Date(
                new Date(this._clock).getTime() - tzoffset
            ).toISOString();

            return localISOTime.substr(0, 16);
        }
    }

    setHour(hour: string) {
        const second = this.milliseconds();
        let newTime: number = second;
        const calcValue: any[] = hour.split(/^(\+|\-)([1-9]+)/); // ["", "+ | -", number, ""]
        const additive = parseInt(calcValue[2], 10) * this.defaultOptions.hour;

        if (calcValue[1] === "+") {
            newTime = second + additive;
        }

        if (calcValue[1] === "-") {
            newTime = second - additive;
        }

        this._clock = new Date(newTime);

        return this;
    }

    milliseconds = (): number => this._clock.getTime();

    isToday = (): boolean => Date.now() === new Date(this._clock).getTime();

    krTime = (value: number) => (value >= 0 && 12 >= value ? "오전" : "오후");

    iZero = (value: number | string) =>
        value.toString().length === 1 ? `0${value}` : value;

    minHour = (value: number) =>
        value > 12 ? this.iZero(value - 12) : this.iZero(value);
}

const timeflow = (init?: DateType) => {
    return new TimeFlow(init || Date.now());
};

export default timeflow;
