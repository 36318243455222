import React from "react";
import styled from "styled-components";

import PbModal from "./PbModal";
import PbButton from "./PbButton";
import PbFloat from "./PbFloat";
import PbDivider from "./PbDivider";

export interface PbConfirmProps {
    toggle: boolean;
    title: string | null | undefined;
    contents: string | null | undefined;
    onConfirm: () => void;
    onCancle: () => void;
}

export const PbConfirmStyledForAndroid = styled.div`
    padding: 24px 24px 12px 24px;

    .pb-confirm__title {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 14px;
    }

    .pb-confirm__contents {
        font-size: 1.4rem;
        line-height: 1.4;
    }
`;

const PbConfirm: React.SFC<PbConfirmProps> = ({
    toggle,
    title,
    contents,
    onConfirm,
    onCancle
}) => {
    const confirmHandler = () => {
        onConfirm();
    };

    return (
        <PbModal toggle={toggle}>
            <PbConfirmStyledForAndroid className="pb-confirm">
                <PbDivider distance={2}>
                    <div className="pb-confirm__title">{title}</div>
                    <div
                        className="pb-confirm__contents"
                        dangerouslySetInnerHTML={{ __html: contents }}
                    ></div>
                </PbDivider>
                <div className="pb-confirm__bottom clear-both">
                    <PbFloat float="right">
                        <PbButton
                            width={60}
                            height={36}
                            ghost
                            onClick={onCancle}
                        >
                            취소
                        </PbButton>
                        <PbButton
                            width={60}
                            height={36}
                            ghost
                            onClick={confirmHandler}
                        >
                            확인
                        </PbButton>
                    </PbFloat>
                </div>
            </PbConfirmStyledForAndroid>
        </PbModal>
    );
};

export default React.memo(PbConfirm);
