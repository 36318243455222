import React from "react";
import styled from "styled-components";

export interface PbRadioProps {
    name?: string;
    value: string;
    checked?: boolean;
    className?: string;
    onChange?: (value: string) => void;
}

const PbRadioStyled = styled.div`
    input[type="radio"] {
        display: none;
    }

    input[type="radio"]:checked + .pb-radio__block {
        color: #ffffff;

        ${props => props.theme.bgd_primary};
    }

    .pb-radio__block {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 10px 12px;
        border-radius: 5px;
        color: ${props => props.theme.color["skyblue-2"]};
    }
`;

const PbRadio: React.SFC<PbRadioProps> = ({
    children,
    name,
    checked = false,
    value,
    className,
    onChange
}) => {
    return (
        <PbRadioStyled className={`pb-radio ${className}`}>
            <label>
                <input
                    type="radio"
                    checked={checked}
                    name={name}
                    value={value}
                    onChange={() => onChange(value)}
                />
                <div className="pb-radio__block">{children}</div>
            </label>
        </PbRadioStyled>
    );
};

export default PbRadio;
