import React, { AnchorHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Color } from "../style/theme";

export interface PbLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    className?: string;
    color?: Color;
}

export interface PbLinkStyleProps {
    block?: boolean;
}

const PbLinkStyled = styled.a<PbLinkStyleProps>`
    cursor: pointer;
    color: ${props => props.theme.color[props.color]};

    &:visited,
    &:active {
        color: ${props => props.theme.color[props.color]};
    }

    ${props =>
        props.block &&
        css`
            display: block;
        `}

    & + & {
        margin-left: 2.4rem;
    }
`;

const PbLink: React.SFC<PbLinkProps & PbLinkStyleProps> = ({
    children,
    href = null,
    className = "",
    color = "black",
    block = false,
    onClick
}) => {
    return (
        <PbLinkStyled
            href={href}
            className={`pb-link ${className}`}
            color={color}
            block={block}
            onClick={onClick}
        >
            {children}
        </PbLinkStyled>
    );
};

export default PbLink;
