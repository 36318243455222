import React from "react";
import styled from "styled-components";
import { useToggle } from "../lib/hooks";

export interface PbSwitchProps {
    value: boolean;
    onSwitch: (value: boolean) => void;
}

export interface PbSiwtchStyleProps {
    activeColor?: string;
    unactiveColor?: string;
}

const PbSwitchStyled = styled.label<PbSiwtchStyleProps>`
    display: inline-block;
    font-size: 0;

    .switch-body {
        height: 20px;
        width: 40px;
        display: inline-block;
        position: relative;
        border: 1px solid
            ${props => props.theme.color[props.unactiveColor || "skyblue-0"]};
        background-color: ${props =>
            props.theme.color[props.unactiveColor || "skyblue-0"]};
        border-radius: 10px;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: #ffffff;
            display: inline-block;
            border-radius: 50%;
            top: 1px;
            left: 1px;
            transition: all 0.3s;
        }
    }

    .switch-box {
        display: none;

        &:checked + .switch-body {
            background-color: ${props =>
                props.activeColor || props.theme.color.primary};
            border-color: ${props =>
                props.activeColor || props.theme.color.primary};

            &::after {
                left: 100%;
                margin-left: -17px;
            }
        }
    }
`;

const PbSwitch: React.SFC<PbSwitchProps & PbSiwtchStyleProps> = ({
    activeColor,
    unactiveColor,
    value,
    onSwitch
}) => {
    const [toggle, setToggle] = useToggle(value || false);

    const switchHandler = () => {
        const value = !toggle;
        setToggle(value);
        onSwitch && onSwitch(value);
    };

    return (
        <PbSwitchStyled
            className="pb-switch"
            activeColor={activeColor}
            unactiveColor={unactiveColor}
        >
            <input
                className="switch-box"
                type="checkbox"
                checked={toggle}
                onChange={switchHandler}
            />
            <div className="switch-body" />
        </PbSwitchStyled>
    );
};

export default PbSwitch;
