import fetch from "./configure";

export type TApprovalPutBody = {
    approval_status: 1 | -1;
    message_id: string | number;
    school_id: number;
};

export default {
    getApproval: (params: string) =>
        fetch.get(`/message/approval_list?${params}`),

    putApproval: (body: TApprovalPutBody) =>
        fetch.put("/message/approval", body)
};
