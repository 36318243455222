import React from "react";
import styled from "styled-components";
import { PbCols, PbRow } from "../shared";
import FaIcon from "./FaIcon";
import { NavLink } from "react-router-dom";

export interface TabMenuProps {
    visible: boolean;
}

const TabMenuStyled = styled.div<TabMenuProps>`
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 1000;
    background-color: #fff;
    transition: bottom 0.35s;
    box-shadow: 0 -1px 4px 0px rgba(0, 0, 0, 0.08);

    bottom: ${(props) => (props.visible ? "0px" : "-60px")};

    .pb-row {
        height: 100%;
    }

    .pb-cols {
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;

            &.active {
                .tab-menu__title {
                    color: ${(props) => props.theme.color.primary};
                }

                svg {
                    color: ${(props) => props.theme.color.primary};
                }
            }
        }
    }

    .tab-menu {
        &__icon {
            margin-bottom: 4px;
            svg {
                font-size: 2.35rem;
            }
        }

        &__title {
            font-size: 1.05rem;
            color: ${(props) => props.theme.color.gray};
        }
    }
`;

const TabMenu: React.SFC<TabMenuProps> = ({ visible }) => {
    return (
        <TabMenuStyled visible={visible}>
            <PbRow align="center" justify="space-between" between={0}>
                <PbCols col-8>
                    <NavLink
                        to="/message"
                        className="menu-item"
                        activeClassName="active"
                    >
                        <div className="tab-menu__icon">
                            <FaIcon prefix="fal" name="envelope" />
                        </div>
                        <div className="tab-menu__title">메시지</div>
                    </NavLink>
                </PbCols>
                <PbCols col-8>
                    <NavLink
                        to="/approval"
                        className="menu-item"
                        activeClassName="active"
                    >
                        <div className="tab-menu__icon">
                            <FaIcon prefix="fal" name="signature" />
                        </div>
                        <div className="tab-menu__title">결재</div>
                    </NavLink>
                </PbCols>
                <PbCols col-8>
                    <NavLink
                        to="/student"
                        className="menu-item"
                        activeClassName="active"
                    >
                        <div className="tab-menu__icon">
                            <FaIcon prefix="fal" name="users-class" />
                        </div>
                        <div className="tab-menu__title">학생</div>
                    </NavLink>
                </PbCols>
                <PbCols col-8>
                    <NavLink
                        to="/rate"
                        className="menu-item"
                        activeClassName="active"
                    >
                        <div className="tab-menu__icon">
                            <FaIcon prefix="fal" name="stars" />
                        </div>
                        <div className="tab-menu__title">생활평점</div>
                    </NavLink>
                </PbCols>
            </PbRow>
        </TabMenuStyled>
    );
};

export default React.memo(TabMenu);
