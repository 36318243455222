import React, { useEffect, useState, useRef, useCallback } from "react";
import styled, { css } from "styled-components";

import { encodeObject } from "../lib/utils";
import { PureFunction } from "../lib/types";
import { GlobalSize } from "../style/theme";

import PbInput, { PbInputStyleProps } from "./PbInput";

export interface PbSelectorProps extends PbInputStyleProps {
    options: Array<PbSelectorItemProps>;
    isLabel?: string;
    value?: any;
    width?: number;
    name?: string;
    style?: any;
    size?: GlobalSize;
    placeholder?: string;
    disabled?: boolean;
    arrowShape?: "chevron-down" | "sort-down";

    onChange: (item: any, index: number, ev?: HTMLInputElement) => void;
}

export interface PbSelectorItemProps {
    [key: string]: any;
}

export interface PbSelectorItemsProps {
    active?: boolean;
    maxHeight?: number;
    direction?: "up" | "down";
}

export interface SelectorOption {
    [k: string]: any;
}

const PbSelectorStyled = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100%;

    label {
        position: relative;
        cursor: pointer;
        display: block;
    }
`;

const PbSelectorItemsStyled = styled.ul<PbSelectorItemsProps>`
    font-size: 1.3rem;
    min-width: 100%;
    position: absolute;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
    z-index: 1004;
    transition: all 0.2s;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    max-height: ${(props) => props.maxHeight}px;
    overflow-y: auto;
    scroll-padding: 0px;
    -webkit-overflow-scrolling: touch;

    ${(props) =>
        props.direction === "up"
            ? css`
                  bottom: 100%;
              `
            : css`
                  top: 100%;
              `}

    ${(props) =>
        props.active
            ? css`
                  visibility: visible;
                  opacity: 1;
                  transform: ${props.direction === "up"
                      ? "translateY(-7px)"
                      : "translateY(2px)"};
              `
            : css`
                  visibility: hidden;
                  opacity: 0;
                  transform: ${props.direction === "up"
                      ? "translateY(-2px)"
                      : "translateY(7px)"};
              `}
`;

const PbSelectItemStyled = styled.li<PbSelectorItemProps>`
    padding: 0 16px;
    height: 52px;
    line-height: 52px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    outline: none;

    ${(props) =>
        props.active
            ? css`
                  color: ${props.theme.color.primary};
              `
            : css`
                  background-color: #ffffff;
              `};
`;

const PbSelector: React.SFC<PbSelectorProps & PbSelectorItemsProps> = ({
    options = [],
    isLabel = "label",
    value,
    width,
    name,
    maxHeight = 200,
    style,
    ghost,
    direction = "down",
    disabled,
    size = "default",
    placeholder,
    arrowShape = "chevron-down",
    onChange,
}) => {
    const [active, onActive] = useState<boolean>(false);
    const [inValue, setInValue] = useState(null);
    const documentListener = useRef<null | PureFunction>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const unbindDocumentListener = useCallback(() => {
        if (documentListener.current) {
            document.removeEventListener("mousedown", documentListener.current);
            documentListener.current = null;
        }
    }, []);

    const hide = useCallback(() => {
        onActive(false);
        unbindDocumentListener();
    }, [unbindDocumentListener]);

    const bindDocumentListener = useCallback(() => {
        if (!documentListener.current) {
            documentListener.current = () => {
                if (!active) hide();
            };
        }

        document.addEventListener("mousedown", documentListener.current);
    }, [active, hide]);

    const show = useCallback(() => {
        onActive(true);
        bindDocumentListener();
    }, [bindDocumentListener]);

    useEffect(() => {
        if (value && typeof value === "object") {
            setInValue(value);
        }

        if (!value && inValue) {
            setInValue(null);
        }

        return function cleanup() {
            if (documentListener.current) unbindDocumentListener();
        };
    }, [value, isLabel, inValue, unbindDocumentListener]);

    const clickHandler = active ? hide : show;

    const clickToItem = (item: any, index: number) =>
        onChange(item, index, inputRef.current);

    const opt =
        options.length === 0 ? [{ [isLabel]: "없음", value: null }] : options;

    const optionRender = opt.map((item, index) => {
        const isState = !inValue ? value : inValue[isLabel];
        const optionActive = isState === item[isLabel];

        return (
            <PbSelectItemStyled
                key={index}
                className="pb-selector-item"
                disabled={item.disabled}
                active={optionActive}
                onMouseDown={() => clickToItem(item, index)}
            >
                {item[isLabel]}
            </PbSelectItemStyled>
        );
    });

    const customStyle = !style ? encodeObject({ width }) : style;
    const title = inValue && inValue[isLabel];

    return (
        <PbSelectorStyled className="pb-selector">
            <label onMouseDown={() => !disabled && clickHandler()}>
                <PbInput
                    value={title}
                    name={name}
                    readOnly
                    bulk={size}
                    width={width}
                    icon={{
                        align: "right",
                        prefix: "far",
                        name: arrowShape,
                    }}
                    ref={inputRef}
                    disabled={disabled}
                    ghost={ghost}
                    placeholder={placeholder}
                    style={customStyle}
                />
            </label>

            <PbSelectorItemsStyled
                className="pb-selector-items"
                active={active}
                maxHeight={maxHeight}
                direction={direction}
            >
                {optionRender}
            </PbSelectorItemsStyled>
        </PbSelectorStyled>
    );
};

export default PbSelector;
