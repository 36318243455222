import { TemplateColor } from "../style/theme";

export type SendPhoneType = "APP" | "SMS" | "LMS";
export const SEND_PHONE_TYPE: Array<SendPhoneType> = ["APP", "SMS", "LMS"];

// phone_name_space
export type PhoneType =
    | "parents_phone1"
    | "parents_phone2"
    | "student_phone"
    | "teacher_phone";

export type AppType =
    | "parents_phone1_app"
    | "parents_phone2_app"
    | "student_phone_app"
    | "teacher_phone_app";

export type PhoneAppType =
    | "parents_phone1_app"
    | "parents_phone2_app"
    | "student_phone_app"
    | "etc_phone_app";

export const phoneType: Array<PhoneType> = [
    "parents_phone1",
    "parents_phone2",
    "student_phone",
    "teacher_phone",
];

export const appType: Array<AppType> = [
    "parents_phone1_app",
    "parents_phone2_app",
    "student_phone_app",
    "teacher_phone_app",
];

export const recieverTypeWithKo = {
    parents_phone1: "학부모1",
    parents_phone2: "학부모2",
    student_phone: "학생",
    teacher_phone: "담임선생님",
};

// name_key_space
export type KindType = "Parents1" | "Parents2" | "Student" | "Teacher";
export const kindType: Array<KindType> = [
    "Parents1",
    "Parents2",
    "Student",
    "Teacher",
];

// 타입도 불러와야 할듯 ??
export type ReceiverType = "학부모1" | "학부모2" | "학생";
export const receiverType = ["학부모1", "학부모2", "학생"];

export type ReceiverTypeKey = "Parent1" | "Parent2" | "Student" | "Unknown";
export const receiverTypeKey: { [key in ReceiverTypeKey]: string } = {
    Parent1: "학부모1",
    Parent2: "학부모2",
    Student: "학생",
    Unknown: "없음",
};

// 메세지 발송 형태
export type SendType = "0" | "1" | "2";
export const sendType = ["일반", "무료", "긴급"];

// 메시지 타입
export type MessageType = 1 | 2 | 3 | 4 | 5;
export const messageType = ["메시지", "회신", "개별메시지", "생활평점", "기타"];

//
export const sendStatusType = ["취소", "예약", "성공", "발송중"];
//
export const sendRequestStatusType = [
    "실패",
    "취소",
    "대기중",
    "성공",
    "발송중",
];

export const statusColorMap: Array<TemplateColor> = [
    "error",
    "disabled",
    "warning",
    "info",
    "success",
];

export const approvalStatus = ["반려", "결재 대기중", "결재"];

export const groupType = ["나의 그룹", "공유 그룹", "학생부"];

export type FileColor =
    | "jpg"
    | "jpeg"
    | "png"
    | "gif"
    | "hwp"
    | "xls"
    | "xlsx"
    | "docx"
    | "none";

export const fileType: Array<FileColor> = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "hwp",
    "xls",
    "xlsx",
    "docx",
];

export const fileColor: { [k in FileColor]: string } = {
    none: "#9B9BAA",
    docx: "#1592e6",
    hwp: "#1592e6",
    gif: "#9450FF",
    jpeg: "#9450FF",
    jpg: "#9450FF",
    png: "#9450FF",
    xls: "#00c853",
    xlsx: "#00c853",
};
