import React, { DOMAttributes } from "react";
import styled, { css } from "styled-components";
import { JustifyContentProperty, AlignItemsProperty } from "../style/theme";

export interface PbRowProps extends DOMAttributes<HTMLDivElement> {
    columnMode?: boolean;
    className?: string;
    justify?: JustifyContentProperty;
    align?: AlignItemsProperty;
    field?: boolean;
    distance?: number;
    between?: number;
    style?: object;
}

const PbRowStyled = styled.div<PbRowProps>`
    display: flex;
    width: 100%;

    & + & {
        margin-top: ${(props) => props.distance * 10}px;
    }

    .pb-cols {
        & + .pb-cols {
            margin-left: ${(props) => props.between * 10 + "px"};
        }
    }

    ${(props) =>
        props.field &&
        css`
            height: calc(100vh - 60px);
            overflow-y: auto;
        `}

    ${(props) =>
        props.columnMode &&
        css`
            flex-direction: column;
        `}

    ${(props) =>
        props.align &&
        css`
            align-items: ${props.align};
        `}

    ${(props) =>
        props.justify &&
        css`
            justify-content: ${props.justify};
        `}
`;

const PbRow: React.SFC<PbRowProps> = ({
    children,
    columnMode,
    justify,
    align,
    field,
    className = "",
    distance = 1,
    between = 1,
    style,
    ...props
}) => {
    return (
        <PbRowStyled
            className={`pb-row ${className}`}
            columnMode={columnMode}
            justify={justify}
            align={align}
            field={field}
            distance={distance}
            between={between}
            style={style}
            {...props}
        >
            {children}
        </PbRowStyled>
    );
};

export default PbRow;
