import { put, call, select, takeEvery } from "redux-saga/effects";
import { fetchCallClassAsync, fetchSchoolTelsAsync } from "../school";
import { default as schoolAPI } from "../../api/school";
import { encodeQuery, encodeObject } from "../../lib/utils";
import {
    fetchCallClassGroupAsync,
    ClassGroupType,
    GroupStudentType,
} from "../user";

import { fetchReceiverDefaultConfigAsync } from "../school";

import { alertToggle } from "../base";

function* fetchCallClass() {
    let state = yield select();
    try {
        let school_id = state.school.myschool.id;

        const { data } = yield call(
            schoolAPI.getClassNew,
            encodeQuery({ school_id })
        );

        yield put(fetchCallClassAsync.success(data));
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );

        yield put(fetchCallClassAsync.failure(error.message));
    }
}

function* fetchCallClassGroup(
    action: ReturnType<typeof fetchCallClassGroupAsync.request>
) {
    let state = yield select();

    try {
        let school_id = state.school.myschool.id;
        let year = state.school.myschool.year;
        let { grade, class_name, type } = action.payload;

        let filter = encodeObject({ grade, class_name, type });

        const response = yield call(
            schoolAPI.getClassGroup,
            encodeQuery({
                school_id,
                year,
                ...filter,
            })
        );

        const includeChecked = response.data.map((props: ClassGroupType) => {
            const students = props.students.map((srops: GroupStudentType) => ({
                ...srops,
                checked: false,
            }));

            return {
                ...props,
                students,
                checked: false,
            };
        });

        yield put(
            fetchCallClassGroupAsync.success({ data: includeChecked, type })
        );
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );

        yield put(fetchCallClassGroupAsync.failure());
    }
}

function* fetchReceiverDefaultConfig(
    action: ReturnType<typeof fetchReceiverDefaultConfigAsync.request>
) {
    let state = yield select();

    try {
        const response = yield call(
            schoolAPI.getReceiverDefaultConfig,
            encodeQuery({
                school_id: state.school.myschool.id,
            })
        );

        yield put(fetchReceiverDefaultConfigAsync.success(response.data));
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );

        yield put(fetchReceiverDefaultConfigAsync.failure(error));
    }
}

function* fetchSchoolTels(
    action: ReturnType<typeof fetchSchoolTelsAsync.request>
) {
    let state = yield select();

    try {
        const response = yield call(
            schoolAPI.getSchoolTels,
            encodeQuery({
                school_id: state.school.myschool.id,
                selector: 1,
            })
        );

        yield put(fetchSchoolTelsAsync.success(response.data));
    } catch (error) {
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

export default [
    takeEvery(fetchCallClassAsync.request, fetchCallClass),
    takeEvery(fetchCallClassGroupAsync.request, fetchCallClassGroup),
    takeEvery(
        fetchReceiverDefaultConfigAsync.request,
        fetchReceiverDefaultConfig
    ),
    takeEvery(fetchSchoolTelsAsync.request, fetchSchoolTels),
];
