import fetch from "./configure";
import {
    MessageType,
    SendType,
    SendPhoneType,
    KindType
} from "../lib/staticValue";
import { ReceiverSearchType, MessageLock } from "../modules/message";

/**
 * * Send Multiple Type
 * type: SendPhoneType;
 * number: string;
 * student_id?: number;
 */
export type MessageSendType = {
    approval: "0" | "1";
    approval_user: number | string;
    attach_files: string;
    byte: number;
    files: string;
    message: string;
    message_type: MessageType;
    receiver: string;
    school_id: number;
    send_date: string | Date;
    send_reserve: "0" | "1";
    send_type: SendType;
    sender: number | string;
    is_lock: "0" | "1";
};

/**
 * * Send Persnal Type
 */

export type PersnalType = {
    grade: number;
    number: number;
    name: string;
    message: string; //"6학년 3반 박성진 교복 으로 상점 3점 추가입니다.",
    class: string;
    phone: string;
    type: SendPhoneType;
    receiver_type: KindType;
    sms_type: "SMS" | "LMS";
};

export type MessagePersnalType = {
    approval: "0" | "1";
    approval_user: number | string;
    attach_files: string; // []
    message_type: number; // absolute number 4
    receiver: string; // Array<PersnalType>
    send_date: string;
    send_reserve: "0" | "1";
    send_type: SendType;
    school_id: number;
    sender: number | string;
};

export default {
    // [GET] 받는 분 불러오기 (검색)
    getReceiver: (params: string): Promise<Array<ReceiverSearchType>> =>
        fetch.get(`/student/school_students?${params}`),

    // [GET] messages
    getMessage: (params: string) => fetch.get(`/message/send_list2?${params}`),

    // [GET] message view
    getMessageView: (params: string) =>
        fetch.get(`/message/send_detail_list?${params}`),

    // [POST] message send multiple ( for Message )
    sendMessage: (data: MessageSendType) =>
        fetch.post("/message/send/middleware", data),

    // [PUT] message send cancle
    cancelMessage: (data: { message_id: number }) =>
        fetch.put("/message/send_cancel", data),

    // [POST] message send persnal ( for Rate )
    sendPersnalMessage: (data: MessagePersnalType) =>
        fetch.post("/message/send/personal", data),

    // [PUT] message lock update
    putMessageLock: (data: MessageLock) => fetch.put("/message/lock", data),

    // [POST] transfer file to url
    getFileRequestId: (body: object | FormData): Promise<object> =>
        fetch.post("/message/file", body, {
            headers: { "Content-Type": "multipart/form-data" }
        })
};
