import React from "react";
import styled, { keyframes } from "styled-components";
import { AlertUseage, AlertLowerType } from "./PbAlert";
import PbRow from "./PbRow";
import { PbCols } from ".";

export interface AlertWrapperProps {
    alerts: Array<AlertUseage>;
    duration: number;
}

const AlertPop = keyframes`
0% {
    opacity: 0;
    top: 20px;
} 10% {
    opacity: 1;
    top: -56px;
} 20% {
    top: -56px;
} 80% {
    opacity: 1;
    top: -56px;
} 100% {
    opacity: 0;
    top: 20px;
}
`;

const AlertWrapperStyled = styled.div`
    position: absolute;
    bottom: 8px;
    left: 0;
    padding: 0 8px;
    width: 100%;
    z-index: 2000;
`;

const AlertItemStyeld = styled.div<{ type: AlertLowerType; timer: number }>`
    position: absolute;
    font-size: 1.4rem;
    padding: 18px 24px;
    border-radius: 5px;
    width: calc(100% - 16px);
    left: 50%;
    top: 20px;
    transform: translateX(-50%);

    animation: ${AlertPop} ${props => props.timer / 1000}s alternate;
    ${props => props.theme[props.type]}
`;

const AlertItem: React.SFC<AlertUseage & {
    index: number;
    duration: number;
}> = ({ content, type, index, duration, onClose }) => {
    return (
        <AlertItemStyeld style={{ zIndex: index }} type={type} timer={duration}>
            <PbRow justify="space-between" align="center">
                <PbCols>{content}</PbCols>
                <PbCols>
                    <div onClick={onClose}>확인</div>
                </PbCols>
            </PbRow>
        </AlertItemStyeld>
    );
};

const AlertWrapper: React.SFC<AlertWrapperProps> = ({ alerts, duration }) => {
    return (
        <AlertWrapperStyled>
            {alerts.map((item, index) => (
                <AlertItem
                    key={item.uid}
                    {...item}
                    index={index}
                    duration={duration}
                />
            ))}
        </AlertWrapperStyled>
    );
};

export default AlertWrapper;
