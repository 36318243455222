import React from "react";
import styled from "styled-components";

export type PanalItem = {
    name: string;
    id: string | number;
};

export interface PbTabPanalProps {
    items: Array<PanalItem>;
    active: string | number;
    onPanalClick?: (panalId: string | number) => void;
}

export interface PbTabPanalStyleProps {}

const PbTabPanalStyled = styled.div<PbTabPanalStyleProps>`
    font-size: 1.4rem;
    color: ${props => props.theme.color.gray};

    .pb-tab-panal__item {
        position: relative;
        display: inline-block;
        z-index: 0;
        transition: all 0.3s;

        & + .pb-tab-panal__item {
            margin-left: 5vw;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -6px;
            left: 50%;
            transition: all 0.3s;
            transform: translateX(-50%) scale(0);
            width: calc(100% + 6px);
            height: 12px;
            background-color: ${props => props.theme.color.primary};
            border-radius: 15px;
            z-index: -1;
        }

        &[data-active="true"] {
            font-weight: bold;
            color: ${props => props.theme.color.black};

            &::after {
                transform: translateX(-50%) scale(1);
            }
        }
    }
`;

const PbTabPanal: React.SFC<PbTabPanalProps & PbTabPanalStyleProps> = ({
    items,
    active,
    onPanalClick
}) => {
    const panals = items.map(({ id, name }) => {
        const isActive = active === id;

        return (
            <div
                className="pb-tab-panal__item"
                data-active={isActive}
                key={id}
                onClick={() => onPanalClick(id)}
            >
                {name}
            </div>
        );
    });

    return (
        <PbTabPanalStyled className="pb-tab-panal">{panals}</PbTabPanalStyled>
    );
};

export default PbTabPanal;
