import React from "react";
import styled, { keyframes, css } from "styled-components";

export interface PbLoadingProps {
    loading: number;
    width?: number;
    color?: string;
    stroke?: number;
    block?: "block" | "flex";
    breakColor?: string;
    className?: string;
}

const LoadingAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const PbLoadingStyled = styled.div<PbLoadingProps>`
    width: 100%;

    ${props =>
        props.block === "block" &&
        css`
            text-align: center;
            padding-top: 24px;
            padding-bottom: 24px;
        `}

    display: ${props => (props.loading ? "block" : "none")};

    .loading-text {
        margin-top: 12px;
        font-size: 1.4rem;
    }

    .loading-spinner {
        animation: ${LoadingAnimation} 1s linear infinite;
        width: ${props => props.width}px;
        height: ${props => props.width}px;

        .track {
            fill: ${props => props.theme.color[props.color]};
        }
    }
`;

const PbLoading: React.SFC<PbLoadingProps> = ({
    children,
    loading = 0,
    width = 32,
    color = "primary",
    breakColor = "#f9fafc",
    stroke = 10,
    block = "block",
    className = ""
}) => {
    return (
        <PbLoadingStyled
            color={color}
            width={width}
            stroke={stroke}
            className={`pb-loading ${className}`}
            loading={loading}
            block={block}
            breakColor={breakColor}
        >
            <svg
                className="loading-spinner"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
            >
                <g fill="none">
                    <path
                        className="track"
                        data-discription="main circle"
                        d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
                    />

                    <path
                        className="section"
                        data-discription="break point"
                        fill={breakColor}
                        d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z"
                    />
                </g>
            </svg>

            <div className="loading-text">{children}</div>
        </PbLoadingStyled>
    );
};

export default PbLoading;
