import { all } from "redux-saga/effects";

import userSagas from "./userSaga";
import rateSagas from "./rateSaga";
import messageSagas from "./messageSaga";
import schoolSagas from "./schoolSaga";
import approvalSagas from "./approvalSaga";

export default function* rootSaga() {
    yield all([
        ...userSagas,
        ...messageSagas,
        ...rateSagas,
        ...schoolSagas,
        ...approvalSagas
    ]);
}
