import { createAction, createReducer, ActionType } from "typesafe-actions";
import { AlertItem } from "../shared/PbAlert";

export type SetConfirm = {
    title?: string;
    contents?: string;
    onConfirm: () => void;
    onCancle?: () => void;
};

export type SetPrompt = {
    title?: string;
    value?: string | number;
    onConfirm: (value?: string) => boolean | Promise<boolean>;
    onCancle?: () => void;
};

export const RUN_CONFIRM = "base/RUN_CONFIRM";
export const CLOSE_CONFIRM = "base/CLOSE_CONFIRM";
export const RUN_PROMPT = "base/RUN_PROMPT";
export const CLOSE_PROMPT = "base/CLOSE_PROMPT";
export const ALERT_CLOSE = "base/ALERT_CLOSE";
export const ALERT_TOGGLE = "base/ALERT_TOGGLE";

export const runConfirm = createAction(
    RUN_CONFIRM,
    action => (params: SetConfirm) => action(params)
);

export const closeConfirm = createAction(CLOSE_CONFIRM, action => () =>
    action()
);

export const runPrompt = createAction(
    RUN_PROMPT,
    action => (params: SetPrompt) => action(params)
);

export const closePrompt = createAction(CLOSE_PROMPT, action => () => action());

export const alertClose = createAction(ALERT_CLOSE, action => () => action());

export const alertToggle = createAction(
    ALERT_TOGGLE,
    action => (alert: AlertItem) => action(alert)
);

export const actions = {
    runConfirm,
    closeConfirm,
    runPrompt,
    closePrompt,
    alertClose,
    alertToggle
};

export type BaseActions = ActionType<typeof actions>;

export type BaseState = {
    confirm: {
        toggle: boolean;
        title?: string;
        contents?: string;
        onConfirm: () => void;
        onCancle: () => void;
    };
    prompt: {
        toggle: boolean;
        title?: string;
        value?: string | number;
        onConfirm: (value?: string) => boolean | Promise<boolean>;
        onCancle: () => void;
    };
    alertToggle: boolean;
    alert: AlertItem;
};

const initialState: BaseState = {
    confirm: {
        toggle: false,
        title: null,
        contents: null,
        onCancle: null,
        onConfirm: () => {}
    },
    // confirm: {
    //     toggle: true,
    //     title: "Title",
    //     contents: "This is confirm",
    //     onCancle: () => {},
    //     onConfirm: () => {}
    // },
    prompt: {
        toggle: false,
        title: null,
        value: null,
        onCancle: () => {},
        onConfirm: () => true
    },

    alertToggle: false,
    alert: {
        type: "INFO",
        content: ""
    }
};

export default createReducer<BaseState, BaseActions>(initialState, {
    "base/RUN_CONFIRM": (state, action) => {
        const { title, contents, onConfirm, onCancle } = action.payload;

        return {
            ...state,
            confirm: {
                toggle: true,
                title,
                contents,
                onConfirm,
                onCancle
            }
        };
    },

    "base/CLOSE_CONFIRM": (state, action) => {
        return {
            ...state,
            confirm: {
                ...state.confirm,
                toggle: false
            }
        };
    },

    "base/RUN_PROMPT": (state, action) => {
        const { title, value, onConfirm, onCancle } = action.payload;

        return {
            ...state,
            prompt: {
                toggle: true,
                title,
                value,
                onConfirm,
                onCancle
            }
        };
    },

    "base/CLOSE_PROMPT": state => {
        return {
            ...state,
            prompt: {
                ...state.prompt,
                toggle: false
            }
        };
    },

    "base/ALERT_CLOSE": state => {
        return {
            ...state,
            alertToggle: false
        };
    },

    "base/ALERT_TOGGLE": (state, action) => {
        const { content, type } = action.payload;

        return {
            ...state,
            alertToggle: true,
            alert: {
                content,
                type
            }
        };
    }
});
