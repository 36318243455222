import {
    faCoins,
    faSortDown,
    faTimesCircle,
    faChevronUp,
    faCheck,
    faLongArrowDown,
    faPhoneAlt,
    faExclamationTriangle,
    faEnvelopeSquare,
    faSiren,
    faStars as fasStars,
    faLockAlt,
    faLockOpenAlt,
    faEnvelope as fasEnvelope,
    faFilter as fasFilter
} from "@fortawesome/pro-solid-svg-icons";

import {
    faTimes,
    faFilter,
    faSearch,
    faCalendarAlt,
    faChevronLeft,
    faAngleDown,
    faEllipsisH,
    faEllipsisHAlt,
    faTrashAlt,
    faEraser,
    faTimesCircle as falTimesCircle,
    faStars as falStars,
    faEnvelope as falEnvelope,
    faUsersClass as falUsersClass,
    faSignature
} from "@fortawesome/pro-light-svg-icons";

import {
    faUsersClass,
    faEnvelope,
    faStars,
    faFileSearch,
    faChevronDown,
    faPaperclip,
    faUserPlus,
    faCircle,
    faTimes as farTimes
} from "@fortawesome/pro-regular-svg-icons";

export default [
    faAngleDown,
    faCalendarAlt,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faCheck,
    faCoins,
    faCircle,
    faEraser,
    faEnvelope,
    falEnvelope,
    fasEnvelope,
    faEnvelopeSquare,
    faEllipsisH,
    faEllipsisHAlt,
    faExclamationTriangle,
    faFilter,
    fasFilter,
    faFileSearch,
    faLockAlt,
    faLockOpenAlt,
    faLongArrowDown,
    faSearch,
    faSiren,
    faStars,
    faSignature,
    falStars,
    fasStars,
    faSortDown,
    faPaperclip,
    faPhoneAlt,
    faUsersClass,
    falUsersClass,
    faUserPlus,
    faTrashAlt,
    faTimes,
    farTimes,
    faTimesCircle,
    falTimesCircle
];
