import { put, call, takeEvery, select } from "redux-saga/effects";
import { default as rateAPI } from "../../api/rate";
import {
    fetchRateStudentAsync,
    fetchRateViewAsync,
    fetchCallCategoryAsync,
    fetchRateReceiverAsync,
    fetchRateInsertAsnyc,
    rateCleanup,
    fetchStudentSearchAsync,
    fetchRateViewPagingAsync,
    fetchPhoneInfoAsnyc,
} from "../rate";
import {
    encodeQuery,
    isEmpty,
    uniqueKey,
    encodeObject,
    objectKeys,
    hasOwnProperty,
} from "../../lib/utils";
import { ReceiverType } from "../user";
import { alertToggle } from "../base";

function* fetchRateStudent(
    action: ReturnType<typeof fetchRateStudentAsync.request>
) {
    const state = yield select();

    try {
        let filters: { [k: string]: string } = {};
        const school_id = state.school.myschool.id;
        const { page, limit, filter } = state.rate;

        const obj = encodeObject(filter);

        objectKeys(obj).forEach((key) => {
            filters[key] = hasOwnProperty(filter[key], "value")
                ? filter[key].value
                : filter[key];
        });

        const response = yield call(
            rateAPI.getRateStudents,
            encodeQuery({
                school_id,
                page,
                limit,
                ...filters,
            })
        );

        yield put(fetchRateStudentAsync.success(response));
    } catch (error) {
        yield put(fetchRateStudentAsync.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

function* fetchPhoneInfo(
    action: ReturnType<typeof fetchPhoneInfoAsnyc.request>
) {
    const state = yield select();
    const { grade, class_name, name } = action.payload;

    const school_id = state.school.myschool.id;

    try {
        const response = yield call(
            rateAPI.getStudentStudents,
            encodeQuery({
                school_id,
                grade,
                class_name,
                search_word: name,
                search_type: 0,
            })
        );

        yield put(fetchPhoneInfoAsnyc.success(response.data));
    } catch (error) {
        yield put(fetchPhoneInfoAsnyc.failure(error));
    }
}

function* fetchRateView(action: ReturnType<typeof fetchRateViewAsync.request>) {
    const state = yield select();

    try {
        const tempInfo = state.rate.tempInfo;
        const { id: school_id, year } = state.school.myschool;
        const { vpage, vlimit } = state.rate;

        const response1 = yield call(
            rateAPI.getRateHistory,
            encodeQuery({
                page: vpage,
                limit: vlimit,
                school_id,
                student_id: action.payload,
            })
        );

        if (response1.data.length === 0 && tempInfo === null) {
            throw new Error("데이터가 존재하지 않습니다.");
        }

        const students_arr = isEmpty(response1.data[0])
            ? [
                  {
                      grade: tempInfo.grade,
                      class_name: tempInfo.class_name,
                      number: tempInfo.number,
                  },
              ]
            : [
                  {
                      grade: response1.data[0].grade,
                      class_name: response1.data[0].class_name,
                      number: response1.data[0].student_number,
                  },
              ];

        const response2 = yield call(rateAPI.getStudentInfo, {
            school_id,
            students_arr: JSON.stringify(students_arr),
            year,
        });

        yield put(
            fetchRateViewAsync.success({
                count: response1.count,
                history: response1.data,
                info: { ...response2.data[0], ...students_arr[0] },
                total: {
                    accumulate_rating: response1.score_data.accumulate_rating,
                    minus_rating: response1.score_data.minus_rating,
                    plus_rating: response1.score_data.plus_rating,
                },
            })
        );
    } catch (error) {
        yield put(fetchRateViewAsync.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

function* fetchRateViewPaging(
    action: ReturnType<typeof fetchRateViewPagingAsync.request>
) {
    const state = yield select();

    try {
        const { id: school_id } = state.school.myschool;
        const { vpage, vlimit } = state.rate;

        const response = yield call(
            rateAPI.getRateHistory,
            encodeQuery({
                page: vpage,
                limit: vlimit,
                school_id,
                student_id: action.payload,
            })
        );

        yield put(fetchRateViewPagingAsync.success(response.data));
    } catch (error) {
        yield put(fetchRateViewPagingAsync.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

function* fetchCallCategory(
    action: ReturnType<typeof fetchCallCategoryAsync.request>
) {
    const state = yield select();
    const { type, rType, category } = action.payload;

    try {
        const school_id = state.school.myschool.id;

        const response = yield call(
            rateAPI.getRateCategory,
            encodeQuery({
                school_id,
                rating_type: rType,
                type,
                category1: type === "0" ? null : category,
            })
        );

        yield put(
            fetchCallCategoryAsync.success({ data: response.data, type })
        );
    } catch (error) {
        yield put(fetchCallCategoryAsync.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

function* fetchRateReceiver(
    action: ReturnType<typeof fetchRateReceiverAsync.request>
) {
    const state = yield select();
    const { grade, className, searchWord } = action.payload;
    try {
        const response = yield call(
            rateAPI.getRateReceiver,
            encodeQuery({
                school_id: state.school.myschool.id,
                search_type: "0",
                grade,
                class_name: className,
                search_word: searchWord,
            })
        );

        const withChecked = response.data.map((props: ReceiverType) => ({
            ...props,
            checked: false,
            uid: uniqueKey(),
        }));

        yield put(fetchRateReceiverAsync.success(withChecked));
    } catch (error) {
        yield put(fetchRateReceiverAsync.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

function* fetchRateInsert(
    action: ReturnType<typeof fetchRateInsertAsnyc.request>
) {
    const state = yield select();
    const { data, alert } = action.payload;
    const isAlert = isEmpty(alert) ? false : alert;

    try {
        const school_id = state.school.myschool.id;
        yield call(rateAPI.setRateInsert, {
            ...data,
            school_id,
        });

        yield put(fetchRateInsertAsnyc.success());

        // 대분류 초기화
        yield put(fetchCallCategoryAsync.request({ type: "0", rType: "1" }));
        yield put(rateCleanup("insert"));

        // only inseart
        if (isAlert) {
            yield put(
                alertToggle({
                    type: "SUCCESS",
                    content: "생활평점이 정상적으로 부여되었습니다.",
                })
            );
        }
    } catch (error) {
        yield put(fetchRateInsertAsnyc.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

function* fetchStudentSearch(
    action: ReturnType<typeof fetchStudentSearchAsync.request>
) {
    const state = yield select();
    try {
        const school_id = state.school.myschool.id;
        const { spage, slimit } = state.rate;

        const response = yield call(
            rateAPI.getRateStudents,
            encodeQuery({
                school_id,
                student_name: action.payload.toString(),
                page: spage,
                limit: slimit,
            })
        );
        yield put(fetchStudentSearchAsync.success(response));
    } catch (error) {
        yield put(fetchStudentSearchAsync.failure(error.message));
        yield put(
            alertToggle({
                type: "ERROR",
                content: error.message,
            })
        );
    }
}

export default [
    takeEvery(fetchRateStudentAsync.request, fetchRateStudent),
    takeEvery(fetchRateViewAsync.request, fetchRateView),
    takeEvery(fetchCallCategoryAsync.request, fetchCallCategory),
    takeEvery(fetchRateReceiverAsync.request, fetchRateReceiver),
    takeEvery(fetchRateInsertAsnyc.request, fetchRateInsert),
    takeEvery(fetchStudentSearchAsync.request, fetchStudentSearch),
    takeEvery(fetchRateViewPagingAsync.request, fetchRateViewPaging),
    takeEvery(fetchPhoneInfoAsnyc.request, fetchPhoneInfo),
];
