import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { HashRouter as Router } from "react-router-dom";
import store from "./modules";
import icons from "./lib/faIcons";
import * as theme from "./style/theme";
import * as serviceWorker from "./serviceWorker";
import { whichFlatform } from "./lib/utils";

import App from "./App";

import "./style/base.scss";

import { library } from "@fortawesome/fontawesome-svg-core";

const platform = whichFlatform();

// global zone
window.platform = platform || "android";
window.CURRENT_ENVIRONMENT = process.env.NODE_ENV;

library.add(...icons);

if ("scrollRestoration" in window.history) {
    // Back off, browser, I got this...
    window.history.scrollRestoration = "manual";
}

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
