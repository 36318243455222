import fetch from "./configure";

type StudentInfoParams = {
    school_id: number;
    students_arr: string;
    year: number;
};

export type RateInsert = {
    school_id?: number;
    student_id: string; // JSON.stringify(number[])
    rating_type: 0 | 1;
    rating: number;
    category2: number | null;
    grady_day: string | Date;
    category_text?: string | null;
    added_by: number;
    overlap: boolean;
};

export type RateUpdate = {
    rating: string | number;
    rating_id: number;
    school_id: number;
};

/**
 * * rating_ids: JSON.stringify(Array<string>)
 */
export type RateDelete = {
    rating_ids: string;
    school_id: number;
};

export default {
    /**
     * [GET] 학생 검색 [학생 탭]
     * @param school_id number
     * @param student_name string
     * @param page number
     * @param limit number
     */
    getRateStudents: (params: string) =>
        fetch.get(`/rating/rating_students?${params}`),

    /**
     * [GET] 학생 번호 검색 [학생 탭]
     * @param school_id number
     * @param grade number
     * @param class_name string
     * @param search_word string
     * @param search_type 0
     */
    getStudentStudents: (params: string) =>
        fetch.get(`/student/rating_students?${params}`),

    /**
     * [POST] student info
     *  @body
     *  @body
     */
    getStudentInfo: (data: StudentInfoParams) =>
        fetch.post("/student/message_students_arr", data),

    /**
     * [GET] 학생 검색 [생활평점 탭]
     * @param school_id number
     * @param grade number | string
     * @param class_name number | string
     * @param search_type 0 search_word | student_name
     * @param search_word string
     */
    getRateReceiver: (params: string) =>
        fetch.get(`/student/rating_students?${params}`),

    /**
     * [GET] 생평 기록 조회
     * @param page number
     * @param limit number
     * @param school_id number
     * @param student_id number
     */
    getRateHistory: (params: string) =>
        fetch.get(`/rating/ratings?type=history&${params}`),

    /**
     * [GET] 생평 카테고리 조회
     * @param school_id number
     * @param rating_type 0 벌점 | 1 상점
     * @param type
     */
    getRateCategory: (params: string) =>
        fetch.get(`/rating/rating_categorys?${params}`),

    /**
     * [POST] 생평 저장
     */
    setRateInsert: (data: RateInsert) => fetch.post(`/rating/rating`, data),

    /**
     * [PUT] 생평 수정
     */
    updateRateScore: (data: RateUpdate) => fetch.put("/rating/rating", data),

    /**
     * [DELETE] 생평 삭제
     */
    deleteRateScore: (data: RateDelete) =>
        fetch.delete("/rating/rating", { data }),
};
