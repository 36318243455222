import React from "react";
import styled from "styled-components";
import { obj2Cls } from "../lib/utils";

type ColRange =
    | "col-1"
    | "col-2"
    | "col-3"
    | "col-4"
    | "col-5"
    | "col-6"
    | "col-7"
    | "col-8"
    | "col-9"
    | "col-10"
    | "col-11"
    | "col-12"
    | "col-13"
    | "col-14"
    | "col-15"
    | "col-16"
    | "col-17"
    | "col-18"
    | "col-19"
    | "col-20"
    | "col-21"
    | "col-22"
    | "col-23"
    | "col-24"
    | "col-flex";

type Cols24 = { [key in ColRange]?: boolean };

export interface PbColsProps {
    className?: string;
    children?: React.ReactNode;
    textAlign?: "left" | "right" | "center";
}

const PbColsStyled = styled.div<PbColsProps>`
    text-align: ${props => props.textAlign};

    &.col-flex {
        flex: 1;
    }
`;

const PbCols: React.SFC<PbColsProps & Cols24> = ({
    children,
    className,
    textAlign = "left",
    ...props
}) => {
    const classNames = obj2Cls(props, className);

    return (
        <PbColsStyled
            className={`pb-cols ${classNames}`}
            {...props}
            textAlign={textAlign}
        >
            {children}
        </PbColsStyled>
    );
};

export default React.memo<PbColsProps & Cols24>(PbCols);
