import { lighten } from "polished";
import { css, FlattenInterpolation, ThemeProps } from "styled-components";

export type GlobalSize = "small" | "default" | "large";
export type JustifyContentProperty =
    | "space-between"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-around";

export type AlignItemsProperty = JustifyContentProperty;

export const withFlex = (
    justify: JustifyContentProperty,
    align: AlignItemsProperty,
    direction: string
): FlattenInterpolation<ThemeProps<any>> => css`
    display: flex;
    ${justify &&
        css`
            justify-content: ${justify};
        `}
    ${align &&
        css`
            align-items: ${align};
        `}

    ${direction &&
        css`
            flex-direction: ${direction};
        `}
`;

export const boxShadow = "box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);";

export type Color =
    | "primary"
    | "error"
    | "success"
    | "info"
    | "warning"
    | "disabled"
    | "gray"
    | "wait"
    // | "gray-2"
    | "black"
    | "white"
    | "line"
    | "bg"
    | "skyblue-0"
    | "skyblue-half"
    | "skyblue-1"
    | "skyblue-2";

export const color: { [k in Color]: string } = {
    primary: "#FFB73B",
    error: "#ef6666",
    success: "#00c853",
    info: "#1592e6",
    warning: "#ffb73b",
    disabled: "#a5a5b3",
    gray: "#9B9BAA",
    wait: "#A05AFF",
    // "gray-2": "#9B9BAA",
    black: "#5e616e",
    white: "#ffffff",
    line: "#EBEDF6",
    bg: "#f9fafc",
    "skyblue-0": "#DCDFEC",
    "skyblue-half": "#EEF2F6",
    "skyblue-1": "#CFD3E4",
    "skyblue-2": "#BCC0CE"
};

// Primary GraDation
export const primaryGD = {
    h: "#fec93a", // head
    m: "#febf39", // middle
    t: "#ffa93a" // tail
};

export type TemplateColor =
    | "disabled"
    | "primary"
    | "gd_primary"
    | "white_primary"
    | "info"
    | "success"
    | "error"
    | "warning";

export const disabled = css`
    background-color: #eef2f6;
    color: ${color.gray};
    cursor: not-allowed;
`;

export const btDisabeld = css`
    background-color: #c8ccdf;
    color: #ffffff;
    cursor: not-allowed;
`;

export const primary = css`
    background-color: ${lighten(0.3, color.primary)};
    color: ${color.primary};
`;

// background gradation
export const bgd_primary = css`
    background-image: linear-gradient(to right, ${primaryGD.h}, ${primaryGD.t});
`;

export const gd_primary = css`
    background-image: linear-gradient(to right, ${primaryGD.h}, ${primaryGD.t});
    color: #fff;
    box-shadow: 0 3px 4px 0 rgba(255, 184, 61, 0.45);
`;

export const white_primary = css`
    background-color: #ffffff;
    color: ${color.primary};
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.09);
`;

export const info = css`
    background-color: ${color.info};
    color: #ffffff;
`;

export const success = css`
    background-color: ${color.success};
    color: #ffffff;
`;

export const error = css`
    background-color: ${color.error};
    color: #ffffff;
`;

export const warning = css`
    background-color: ${color.warning};
    color: #ffffff;
`;

export const withMessageColor = (status: number) => {
    switch (status) {
        case -2:
            return color.error;
        case -1:
            return color.disabled;
        case 0:
            return color.primary;
        case 1:
            return color.info;
        case 2:
            return color.success;
        default:
            return color.primary;
    }
};

export const inputSize = {
    small: "40px",
    default: "46px",
    large: "48px"
};

export const inputSizeFactory = (size?: GlobalSize) =>
    size ? inputSize[size] : inputSize.default;
