/**
 * axios global instance
 * setting default valiue ( baseUrl, headers < session > )
 * catch Error
 */
import axios from "axios";
import { history } from "../modules";

const baseURL = process.env.REACT_APP_API;

const xSessionManager =
    localStorage.getItem("session") === null
        ? {}
        : { "x-session-manager": localStorage.getItem("session") };

const axiosInstance = axios.create({
    baseURL,
    headers: {
        common: {
            ...xSessionManager
        }
    }
});

axiosInstance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        const {
            data: { code, result, ...rest }
        } = response;

        if (
            code === "no token" ||
            code === "invalid_session" ||
            code === "no user"
        ) {
            // dev: hash
            // prod: history
            history.push("/fail");
        }

        if (result === "fail") throw new Error(fail_status[code as FailStatus]);

        return { ...rest }; // recieve success
    },
    function (error: Error) {
        error.message = "알 수 없는 오류";

        return Promise.reject(error);
    }
);

export const setHeaders = (session: string): Promise<boolean> =>
    new Promise((resolve) => {
        axiosInstance.defaults.headers["x-session-manager"] = session;
        resolve(true);
    });

export const setSchoolId = (school_id: number): Promise<boolean> =>
    new Promise((resolve) => {
        axiosInstance.defaults.headers["x-school-id"] = school_id;
        resolve(true);
    });

export const pure = axios;

export type FailStatus =
    | "no token"
    | "invalid_session"
    | "invalid_rating"
    | "invalid_type"
    | "invalid_category_text"
    | "must_approval_school"
    | "not_correct_info"
    | "not_same_sender"
    | "permission_denied";

export const fail_status: { [k in FailStatus]: string } = {
    "no token": "토큰이 만료되었습니다.",
    invalid_category_text: "평점내용을 선택 및 작성해주세요.",
    invalid_session: "세션이 만료되었습니다.",
    invalid_rating: "점수를 작성해주세요.",
    invalid_type: "유형을 선택해 주세요",
    must_approval_school: "결재가 필수적인 학교입니다.",
    not_correct_info: "",
    not_same_sender: "보낸사람과 일치하지 않습니다",
    permission_denied: "권한이 없습니다."
};

export default axiosInstance;
