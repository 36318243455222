import React from "react";
import styled, { css } from "styled-components";
import { PbHeader } from "./";
import { PbHeaderProps } from "./PbHeader";

type CloseHandlerType = (e?: Event) => void;
type PopType = boolean;

interface PbPopup {
    Content: React.SFC;
    Header: React.SFC<PbHeaderProps & PbPopupHeaderProps>;
}

export interface PbPopupRequried {
    toggle: PopType;
    onClose: CloseHandlerType;
}

export interface PbPopupProps {
    title?: string;
    onClose?: CloseHandlerType;
    onConfirm?: () => void;
}

export interface PbPopupHeaderProps {
    beforeClose?: () => void;
}

export interface PbPopupStyleProps {
    pop: PopType;
    position?: "left" | "right" | "top" | "bottom";
}

const PbPopupStyled = styled.div.attrs({
    className: "pb-popup"
})<PbPopupStyleProps>`
    position: fixed;
    z-index: 1001;
    width: 100vw;
    height: 100%;
    transition: all 0.35s;
    background-color: ${props => props.theme.color.bg};

    // -webkit-backface-visibility: hidden;
    // -webkit-transform: translate3d(0, 0, 0);

    ${props => {
        if (props.position) {
            if (props.position === "left") {
                return props.pop
                    ? css`
                          top: 0;
                          left: 0;
                      `
                    : css`
                          top: 0;
                          left: 100%;
                      `;
            }

            return props.pop
                ? css`
                      top: 0;
                  `
                : css`
                      top: 100%;
                  `;
        }

        return props.pop
            ? css`
                  top: 0;
              `
            : css`
                  top: 100%;
              `;
    }}

    .popup-content {
        height: 100%;
    }
`;

const PbPopup: PbPopup & React.SFC<PbPopupProps & PbPopupStyleProps> = ({
    children,
    pop = false,
    position = "bottom"
}) => {
    return (
        <PbPopupStyled pop={pop} position={position}>
            {children}
        </PbPopupStyled>
    );
};

const PbPopupContent: React.SFC = ({ children }) => (
    <div className="popup-content">{children}</div>
);

const PbPopupHeader: React.SFC<PbHeaderProps & PbPopupHeaderProps> = ({
    children,
    title,
    left,
    right,
    onLeftClick,
    onRightClick
}) => {
    return (
        <PbHeader
            title={title}
            left={left}
            right={right}
            onLeftClick={onLeftClick}
            onRightClick={onRightClick}
        >
            {children}
        </PbHeader>
    );
};

PbPopup.Content = PbPopupContent;
PbPopup.Header = PbPopupHeader;
export default PbPopup;
