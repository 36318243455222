import { useState, useCallback } from "react";

const useToggle = (defaultValue: boolean) => {
    const [toggle, setValue] = useState<boolean>(() => defaultValue);

    const setToggle = useCallback(
        (value?: boolean) => {
            if (typeof value === "boolean") {
                setValue(value);
                return;
            }

            setValue(!toggle);
        },
        [toggle]
    );

    return [toggle, setToggle] as [boolean, typeof setToggle];
};

export default useToggle;
