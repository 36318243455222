import React, { useState, useEffect, useRef } from "react";

import PbModal from "./PbModal";
import PbButton from "./PbButton";
import PbFloat from "./PbFloat";
import PbDivider from "./PbDivider";
import PbInput from "./PbInput";
import { PbConfirmStyledForAndroid } from "./PbConfirm";

export interface PbPromptProps {
    toggle: boolean;
    title?: string;
    value?: string | number;
    onConfirm: (value?: string) => boolean | Promise<boolean>;
    onCancle: () => void;
}

const PbPrompt: React.SFC<PbPromptProps> = ({
    toggle,
    title,
    value = "",
    onConfirm,
    onCancle
}) => {
    const [prompt, setPrompt] = useState(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const confirmHandler = async () => {
        let result = true;
        const next = onConfirm(prompt);

        result = await next;

        if (result) {
            onCancle();
        }
    };

    useEffect(() => {
        if (toggle) {
            setPrompt(value);
            setTimeout(() => {
                inputRef.current.focus();
            }, 50);
        }
    }, [toggle, value]);

    return (
        <PbModal toggle={toggle}>
            <PbConfirmStyledForAndroid className="pb-confirm">
                <PbDivider distance={1.6}>
                    <div className="pb-confirm__title">{title}</div>
                    <div className="pb-confirm__contents">
                        <PbInput
                            type="number"
                            value={prompt}
                            bulk="small"
                            ghost
                            onlyExp="^[1-9][0-9]*$"
                            ref={inputRef}
                            onChange={ev => setPrompt(ev.target.value)}
                        />
                    </div>
                </PbDivider>
                <div className="clear-both">
                    <PbFloat float="right">
                        <PbButton
                            width={60}
                            height={36}
                            ghost
                            onClick={onCancle}
                        >
                            취소
                        </PbButton>
                        <PbButton
                            width={60}
                            height={36}
                            ghost
                            onClick={confirmHandler}
                        >
                            확인
                        </PbButton>
                    </PbFloat>
                </div>
            </PbConfirmStyledForAndroid>
        </PbModal>
    );
};

export default PbPrompt;
