import React, { DOMAttributes } from "react";
import styled, { css } from "styled-components";
import { TemplateColor } from "../style/theme";

export interface PbButtonProps extends DOMAttributes<HTMLButtonElement> {
    full?: boolean;
    color?: TemplateColor;
    size?: "small" | "default" | "large";
    round?: boolean;
    width?: number;
    height?: number;
    ghost?: boolean;
    disabled?: boolean;
    onClick?: (...args: any) => void;
}

const PbButtonStyled = styled.button<PbButtonProps>`
    display: inline-block;
    border-radius: ${props => (props.round ? "40px" : "5px")};
    border: 0;
    outline: 0;

    svg {
        color: inherit;
    }

    ${props =>
        props.full &&
        css`
            width: 100%;
        `}

    ${props => {
        if (props.width) {
            return css`
                width: ${props.width}px;
                height: ${props.height ? props.height : "48"}px;
                line-height: ${props.height ? props.height : "48"}px;
                text-align: center;
                font-size: 1.4rem;
            `;
        }

        switch (props.size) {
            case "small":
                return css`
                    font-size: 1.2rem;
                    padding: 10px 16px;
                `;
            case "large":
                return css`
                    font-size: 1.6rem;
                    padding: 17px 24px;
                `;
            default:
                return css`
                    font-size: 1.4rem;
                    padding: 15px 20px;
                `;
        }
    }}

    ${props => {
        if (props.ghost) {
            return css`
                background-color: transparent;
                color: ${props.theme.color.black};
            `;
        }

        return props.disabled
            ? props.theme.btDisabeld
            : props.theme[props.color];
    }}};
`;

const PbButton: React.SFC<PbButtonProps> = ({
    children,
    color = "primary",
    disabled,
    full,
    width,
    height,
    ghost,
    round = false,
    size = "default",
    onClick
}) => {
    return (
        <PbButtonStyled
            className="pb-button"
            size={size}
            color={color}
            full={full}
            width={width}
            height={height}
            ghost={ghost}
            round={round}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </PbButtonStyled>
    );
};

export default PbButton;
