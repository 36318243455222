import React from "react";
import styled from "styled-components";
import { InputChangeEvent } from "../lib/types";

export interface PbUploadProps {
    id: string;
    onClick?: () => void;
    onFile?: (ev: InputChangeEvent, file: FileList) => void;
}

const PbUploadStyled = styled.div`
    display: inline-block;
    width: auto;

    input[type="file"] {
        position: absolute;
        height: 0.1px;
        width: 0.1px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;
    }
`;

const PbUpload: React.SFC<PbUploadProps> = ({
    children,
    id,
    onClick,
    onFile
}) => {
    const fileHandler = (ev: InputChangeEvent) => {
        ev.preventDefault();
        onFile(ev, ev.target.files);
        ev.target.value = null;
    };

    return (
        <PbUploadStyled>
            <input
                type="file"
                name="upload"
                id={`${id}_upload`}
                onChange={onFile && fileHandler}
                multiple
                accept=".png, .jpeg, .jpg, .xlsx, .xls, .hwp"
            />
            <label htmlFor={`${id}_upload`}>{children}</label>
        </PbUploadStyled>
    );
};

export default PbUpload;

/**
 * 
 *  id={`${id}_upload`}
    onChange={onFile && fileHandler}
    multiple
    accept=".png, .jpeg, .jpg, .xlsx, .xls, .hwp"
 */
