import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Auth, Fail, NowAllow } from "./page";
import { PbAlert } from "./shared";

// RootPage Wrapper not factory
import RootWrapper from "./common/RootProvider/RootWrapper";

const App: React.FC = () => {
    return (
        <div className="App">
            <PbAlert>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => <Redirect to="/message" />}
                    />
                    <Route path="/auth" component={Auth}></Route>
                    <Route path="/fail" component={Fail}></Route>
                    <Route path="/not_allow" component={NowAllow}></Route>
                    <RootWrapper />
                    <Route
                        path="*"
                        component={() => <div>Not Found</div>}
                    />
                </Switch>
            </PbAlert>
        </div>
    );
};

export default App;
