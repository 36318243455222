import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { PbFloat } from ".";

import FaIcon, { FaIconProps } from "../common/FaIcon";

interface PbHeader {
    Portal: React.SFC;
}

export interface PbHeaderProps {
    left?: { icon: FaIconProps; onClick: () => void };
    right?: Function;
    title?: string;
    content?: React.SFC;
    onLeftClick?: (e?: any) => void;
    onRightClick?: () => void;
}

export interface PbHeaderStyleProps {}

const PbHeaderStyled = styled.div.attrs({
    className: "pb-header"
})<PbHeaderStyleProps>`
    height: 60px;
    font-size: 1.6rem;
    display: flex;
    border-bottom: 1px solid ${props => props.theme.color.line};
    background-color: #fff;

    .side {
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: ${props => props.theme.color.gray};

        svg {
            vertical-align: middle;
            color: ${props => props.theme.color.gray};
        }

        &.left {
            font-size: 2.4rem;
        }

        &.right {
            font-size: 1.6rem;
        }
    }

    .title {
        display: flex;
        flex: 1;
        height: 60px;
        align-items: center;
        font-size: 1.8rem;
    }

    .center {
        justify-content: center;
    }

    .between {
        justify-content: space-between;
    }
`;

const PbHeader: PbHeader & React.SFC<PbHeaderProps & PbHeaderStyleProps> = ({
    children,
    left = { icon: { prefix: "fal", name: "times" } },
    right: RightComponent,
    title,
    onLeftClick,
    onRightClick
}) => {
    if (left.onClick) {
        onLeftClick = left.onClick;
    }

    return (
        <PbHeaderStyled>
            <PbFloat float="left" className="side left" onClick={onLeftClick}>
                <FaIcon prefix={left.icon.prefix} name={left.icon.name} />
            </PbFloat>

            {children ? (
                children
            ) : (
                <>
                    <div className="title center">
                        <div>{title}</div>
                    </div>
                    <PbFloat
                        float="right"
                        className="side right"
                        onClick={onRightClick}
                    >
                        {RightComponent && <RightComponent />}
                    </PbFloat>
                </>
            )}
        </PbHeaderStyled>
    );
};

const PbHeaderPortal: React.SFC<{}> = ({ children }) => {
    const el = document.getElementById("pb-popup-header-portal");
    return ReactDOM.createPortal(children, el);
};

PbHeader.Portal = PbHeaderPortal;
export default PbHeader;
