import React from "react";
import styled from "styled-components";

export interface ContainerProps {
    children: React.ReactNode;
    forwardRef?: any;
}

interface ContainerStyleProps {
    visible: boolean;
}

const ContainerStyled = styled.div<ContainerStyleProps>`
    position: relative;
    height: calc(100vh - ${(props) => (props.visible ? "60px" : "0px")});
    width: 100vw;
    overflow-y: hidden;
    transition: all 0.35s;

    padding-top: ${(props) => (props.visible ? "60px" : "0px")};
`;

const Container: React.SFC<ContainerProps & ContainerStyleProps> = ({
    children,
    visible,
    forwardRef
}) => {
    return (
        <ContainerStyled visible={visible} ref={forwardRef}>
            {children}
        </ContainerStyled>
    );
};

export default React.forwardRef(
    ({ children, ...props }: ContainerProps & ContainerStyleProps, ref) => {
        return (
            <Container {...props} forwardRef={ref}>
                {children}
            </Container>
        );
    }
);
