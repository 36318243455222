import React, { createContext, Component } from "react";
import RootPage from "../../page/RootPage";
import { isEmpty } from "../../lib/utils";

interface RootState {
    visible?: boolean;
}

interface RootProps {}

export interface RootContextActions {
    onVisible: (bool?: boolean) => void;
    getState: () => boolean;
}

export const RootContext = createContext<RootContextActions>(null);

class RootProvider extends Component<RootProps, RootState> {
    state: RootState = {
        visible: true
    };

    onVisible = (value?: boolean) => {
        let bool = !this.state.visible;

        if (!isEmpty(value)) {
            bool = value;
        }

        this.setState({
            visible: bool
        });
    };

    getState = () => this.state.visible;

    render() {
        const actions: RootContextActions = {
            onVisible: this.onVisible,
            getState: this.getState
        };

        return (
            <RootContext.Provider value={actions}>
                {this.props.children}
                <RootPage visible={this.state.visible} />
            </RootContext.Provider>
        );
    }
}

export default RootProvider;
