import {
    ActionType,
    createReducer,
    createAsyncAction,
    createAction
} from "typesafe-actions";
import {
    phoneType,
    PhoneType,
    PhoneAppType,
    receiverType,
    ReceiverTypeKey
} from "../lib/staticValue";

import produce from "immer";

import {
    isEmpty,
    phonemat,
    isZero,
    uniqueKey,
    hasOwnProperty
} from "../lib/utils";
import { ReceiverSearchType } from "./message";

// import { unstable_next } from "scheduler";

// 0 my 1 share 2 student 3 teacher
export type ShareType = 0 | 1 | 2 | 3;

export interface ReceiverType {
    class_name: string;
    etc_phone: string | number;
    etc_phone_app: boolean;
    grade: number;
    id: number;
    name: string;
    number: number;
    parents_phone1: string | number;
    parents_phone1_app: boolean;
    parents_phone2: string | number;
    parents_phone2_app: boolean;
    student_phone: string | number;
    student_phone_app: boolean;
    teacher_phone?: string | number;
    teacher_phone_app?: boolean;

    // in school.myschool
    school_id?: number;
    year?: number;

    // for rate
    checked?: boolean;
    uid?: string;
}

export interface TeacherType {
    admin: boolean;
    teacher_idx: number;
    user_id: number;
    teacher_type: string;
    position: string;
    depart: string;
    name: string;
    username: string;
    phone: string;
    status: string;
    email: string;
    approved_at: string;
    manage_students: 0 | 1;
    manage_messages: 0 | 1;
    manage_rating: 0 | 1;
    manage_rating_category: 0 | 1;
    manage_teachers: 0 | 1;
    manage_approval: 0 | 1;
    manage_board: 0 | 1;
    manage_students_look: 0 | 1;
    manage_class_view: { view: number; all: number };
    phone_app: boolean;
}

/**
 * * chipType
 * * 0 typing number no app
 * * 1 typing number app
 * * 2 search no app
 * * 3 search app
 * * 4 group & class group
 */
export interface FReceiverType {
    // require
    id?: number;
    phone?: string;
    name: string;
    number?: number;
    app?: boolean;
    chipType: 0 | 1 | 2 | 3 | 4;
    uid?: string;
    receiver_type?: ReceiverTypeKey;
    // for group
    members?: Array<FReceiverType>;
    count?: number[];
    checked?: boolean;
    type?: ShareType;
    is_shared?: boolean;
}

export interface GroupType {
    constructor?: string;
    creation_date?: string;
    group_name: string;
    id: number;
    member_count: number;
    share_type?: ShareType;
    checked?: boolean;
    members?: Array<FReceiverType>;
}

export interface TempGroupType {
    id: number;
    uid: string;
    name: string;
    type: ShareType;
    chipType: 4;
    count: number[];
    members: Array<FReceiverType>;
}

export type ApprovalType = {
    depart: string;
    name: string;
    position: string;
    teacher_id: number;
    user_id: number;
};

export type GroupStudentType = {
    name: string;
    phone: string;
    number: number;
    phone_app: boolean;
    student_id: number;
    checked?: boolean;
};

export type ClassGroupType = {
    class_id: number;
    class_name: string;
    students: Array<GroupStudentType>;
    checked?: boolean;
};

export type GroupCheckRequired = {
    uid: number;
    shareType: ShareType;
};

export type MemberCheckRequired = {
    uid: number;
    groupUid: number;
    shareType: ShareType;
};

export type DeleteCheckRequired = {
    id: number;
    uid: string;
    count: number[];
    name: string;
    shareType: ShareType;
};

export type UserInfo = {
    user_id?: number;
    username?: string;
    name?: string;
    phone?: string;
    email?: string;
};

export const SET_SESSION = "user/SET_SESSION";
export const PUSH_RECEIVER = "user/PUSH_RECEIVER";
export const REMOVE_RECEIVER = "user/REMOVE_RECEIVER";
export const GROUP_CHECKED = "user/GROUP_CHECKED";
export const MEMBER_CHECKED = "user/MEMBER_CHECKED";
export const TEACHER_CHECKED = "user/TEACHER_CHECKED";
export const REMOVE_CHECKED = "user/REMOVE_CHECKED";
export const EXPORT_GROUP = "user/EXPORT_GROUP";
export const SET_USER = "user/SET_USER";
export const USER_CLEANUP = "user/USER_CLEANUP";
export const UPDATE_COUNT = "user/UPDATE_COUNT";

export const setSession = createAction(SET_SESSION, (action) => () => action());

export const pushReceiver = createAction(
    PUSH_RECEIVER,
    (action) => (item: FReceiverType | ReceiverSearchType) => action(item)
);

export const removeReceiver = createAction(
    REMOVE_RECEIVER,
    (action) => (uid: string) => action(uid)
);

export const groupChecked = createAction(
    GROUP_CHECKED,
    (action) => (required: GroupCheckRequired) => action(required)
);

export const memberChecked = createAction(
    MEMBER_CHECKED,
    (action) => (required: MemberCheckRequired) => action(required)
);

export const removeChecked = createAction(
    REMOVE_CHECKED,
    (action) => (required: DeleteCheckRequired) => action(required)
);

export const exportGroup = createAction(
    EXPORT_GROUP,
    (action) => () => action()
);

export const userCleanup = createAction(
    USER_CLEANUP,
    (action) => (which: "close-search" | "after-send") => action(which)
);

export const updateCount = createAction(
    UPDATE_COUNT,
    (action) => (params: { type: number; total: number }) => action(params)
);

export const setUser = createAction(
    SET_USER,
    (action) => (user: UserInfo) => action(user)
);

export const fetchTokenAsync = createAsyncAction(
    "user/FETCH_TOKEN_REQUEST",
    "user/FETCH_TOKEN_SUCCESS",
    "user/FETCH_TOKEN_FAILURE"
)<
    string,
    {
        session: string;
        user_id: number;
        user_info: {
            user_id: number;
            username?: string;
            name?: string;
            phone?: string;
            email?: string;
        };
    },
    Error
>();

export const fetchSearchReceiverAsync = createAsyncAction(
    "user/FETCH_SEARCH_RECIEVER_REQUEST",
    "user/FETCH_SEARCH_RECIEVER_SUCCESS",
    "user/FETCH_SEARCH_RECIEVER_FAILURE"
)<
    { searchWord: string; type: "message" | "rate" },
    { data: Array<ReceiverType>; type: "message" | "rate" },
    Error
>();

export const fetchCheckAppInstallAsync = createAsyncAction(
    "user/FETCH_CHECK_APP_INSTALL_REQUEST",
    "user/FETCH_CHECK_APP_INSTALL_SUCCESS",
    "user/FETCH_CHECK_APP_INSTALL_FAILURE"
)<string, { data: boolean[]; number: string }, Error>();

export const fetchCallGroupAsync = createAsyncAction(
    "user/FETCH_CALL_GROUP_REQUEST",
    "user/FETCH_CALL_GROUP_SUCCESS",
    "user/FETCH_CALL_GROUP_FAILURE"
)<
    { searchWord?: string; type: number },
    { data: Array<GroupType>; type: number },
    Error
>();

export const fetchCallClassGroupAsync = createAsyncAction(
    "school/FETCH_CALL_CLASS_GROUP_REQUEST",
    "school/FETCH_CALL_CLASS_GROUP_SUCCESS",
    "school/FETCH_CALL_CLASS_GROUP_FAILURE"
)<
    { grade: number; type: string; class_name: string },
    { data: Array<ClassGroupType>; type: string },
    null
>();

export const fetchApprovalListAsync = createAsyncAction(
    "user/FETCH_APPROVAL_LIST_REQUEST",
    "user/FETCH_APPROVAL_LIST_SUCCESS",
    "user/FETCH_APPROVAL_LIST_FAILURE"
)<null, Array<ApprovalType>, Error>();

export const fetchTeachersAsync = createAsyncAction(
    "user/FETCH_TEACHERS_REQUEST",
    "user/FETCH_TEACHERS_SUCCESS",
    "user/FETCH_TEACHERS_FAILURE"
)<
    { searchWord?: string; isRate?: boolean },
    { data: Array<TeacherType>; isRate?: boolean },
    Error
>();

export const fetchDepartsTeacherAsync = createAsyncAction(
    "user/FETCH_DEPARTS_TEACHER_REQUEST",
    "user/FETCH_DEPARTS_TEACHER_SUCCESS",
    "user/FETCH_DEPARTS_TEACHER_FAILURE"
)<{ searchWord?: string }, null, Error>();

export const actions = {
    setSession,
    pushReceiver,
    removeReceiver,
    groupChecked,
    memberChecked,
    removeChecked,
    exportGroup,
    userCleanup,
    setUser,
    updateCount,
    fetchTokenAsync,
    fetchSearchReceiverAsync,
    fetchCheckAppInstallAsync,
    fetchCallGroupAsync,
    fetchApprovalListAsync,
    fetchCallClassGroupAsync,
    fetchTeachersAsync,
    fetchDepartsTeacherAsync
};

export type UserActions = ActionType<typeof actions>;

export type UserState = {
    on_receivers: Array<FReceiverType | ReceiverSearchType>;
    receivers: Array<FReceiverType>;
    studentGroup: Array<GroupType>;
    myGroup: Array<GroupType>;
    sharedGroup: Array<GroupType>;
    teacherGroup: Array<GroupType>;
    teachers: Array<FReceiverType | TeacherType>;
    approvals: Array<ApprovalType>;
    groupCount: number[];
    pbloading: boolean; // group loading :: MessageWriteContainer > MessagePhoneBook
    rloading: boolean; // receiver loading :: MessageWriteContainer > MessagePhone > MessagePhoneSearch
    rempty: boolean; // receiver empty state
    pbempty: boolean; // phone book empty state
    failure: boolean;
    loading: boolean;
    session: string | null;
    userId: number | null;
    user: UserInfo;
    tempSelectStore: Array<FReceiverType>;
    tempSelectMemberCount: number;

    // rate
    rReceivers: Array<ReceiverType>;
};

const initialState: UserState = {
    session: null,
    userId: null,
    user: {
        user_id: null,
        email: null,
        name: null,
        phone: null,
        username: null
    },
    on_receivers: [], // 받는 사람
    receivers: [],
    studentGroup: [],
    myGroup: [],
    sharedGroup: [],
    teacherGroup: [],
    teachers: [],
    approvals: [],
    pbloading: false,
    rloading: false,
    groupCount: [0, 0],
    pbempty: false,
    rempty: false,
    failure: false,
    loading: false,
    tempSelectStore: [],
    tempSelectMemberCount: 0,
    // rate
    rReceivers: []
};

export default createReducer<UserState, UserActions>(initialState, {
    "user/SET_SESSION": (state, action) => {
        const localSession = localStorage.getItem("session");

        if (localSession) {
            return {
                ...state,
                session: localSession
            };
        }

        return {
            ...state,
            session: null
        };
    },

    // 받는 분 추가
    "user/PUSH_RECEIVER": (state, action) => {
        return {
            ...state,
            on_receivers: [
                ...state.on_receivers,
                { ...action.payload, uid: uniqueKey() }
            ]
        };
    },

    // 받는 분 제거
    "user/REMOVE_RECEIVER": (state, action) => {
        let filter = state.on_receivers.filter((r) => r.uid !== action.payload);

        return {
            ...state,
            on_receivers: [...filter]
        };
    },

    // 받는 분 불러오기 > 그룹 전체체크
    "user/GROUP_CHECKED": (state, action) => {
        let current: Array<GroupType> = [];
        let { shareType, uid } = action.payload;

        return produce(state, (draft) => {
            // selected group

            switch (shareType) {
                case 0:
                    current = draft.myGroup;
                    break;
                case 1:
                    current = draft.sharedGroup;
                    break;
                case 2:
                    current = draft.studentGroup;
                    break;
                case 3:
                    current = draft.teacherGroup;
                    break;
                default:
                    current = [];
            }

            let index = current.findIndex((g) => g.id === uid);

            let {
                checked,
                members,
                member_count,
                group_name: name,
                id
            } = current[index];

            current[index].checked = !checked;
            current[index].members = members.map((props) => ({
                ...props,
                checked: !checked
            }));

            let isAlready = draft.tempSelectStore.findIndex(
                (temp) => temp.id === id && temp.name === name
            );

            if (!checked === true) {
                // 다음 checked 가 true인 경우 멤버를 추가해 준다.
                let falseCount = members.filter(
                    ({ checked }) => !checked
                ).length;

                const data: TempGroupType = {
                    id,
                    uid: uniqueKey(),
                    name,
                    members,
                    count: [member_count, member_count],
                    type: shareType,
                    chipType: 4
                };

                if (isAlready !== -1) {
                    draft.tempSelectStore[isAlready] = data;
                } else {
                    draft.tempSelectStore.push(data);
                }

                draft.tempSelectMemberCount += falseCount;
            } else {
                // 다음 checked 가 false인 경우 멤버뺴준다.
                let trueCount = members.filter(
                    ({ checked }) => !!checked
                ).length;

                draft.tempSelectStore = draft.tempSelectStore.filter(
                    (item) => item.id !== id
                );

                draft.tempSelectMemberCount -= trueCount;
            }
        });
    },

    // 받는 분 불러오기 > 그룹 멤버 체크
    "user/MEMBER_CHECKED": (state, action) => {
        let current: Array<GroupType> = [];
        const { uid, groupUid, shareType } = action.payload;

        return produce(state, (draft) => {
            // selected group
            switch (shareType) {
                case 0:
                    current = draft.myGroup;
                    break;
                case 1:
                    current = draft.sharedGroup;
                    break;
                case 2:
                    current = draft.studentGroup;
                    break;
                case 3:
                    current = draft.teacherGroup;
                    break;
                default:
                    current = [];
            }

            let index = current.findIndex((item) => item.id === groupUid);

            let {
                members,
                member_count,
                id,
                group_name: name
            } = current[index];

            // get children checked
            let cIndex = members.findIndex((item) => item.id === uid);
            let { checked } = members[cIndex];

            current[index].members[cIndex].checked = !checked;
            current[index].checked =
                members.filter(({ checked }) => !!checked).length ===
                member_count;

            // 이미 있는 그룹인지 검사한다.
            let isAlready = draft.tempSelectStore.findIndex(
                (temp) => temp.id === id && temp.name === name
            );

            let trueMembers = members.filter(({ checked }) => !!checked);

            if (!checked === true) {
                if (isAlready !== -1) {
                    draft.tempSelectStore[isAlready].members = [...trueMembers];
                    draft.tempSelectStore[isAlready].count = [
                        trueMembers.length,
                        member_count
                    ];
                } else {
                    draft.tempSelectStore.push({
                        id,
                        uid: uniqueKey(),
                        name,
                        members: trueMembers,
                        count: [trueMembers.length, member_count],
                        type: shareType,
                        chipType: 4
                    });
                }

                draft.tempSelectMemberCount += 1;
            } else {
                // 반드시 그룹이 있어야 하지만 그래도 검사 한번 해준다.
                if (isAlready !== -1) {
                    let falseMembers = members.filter(
                        ({ checked }) => !checked
                    );

                    // check 해제된 멤버와 멤버수가 같으면 tempSelectStore 에서 제거하기
                    if (falseMembers.length === member_count) {
                        draft.tempSelectStore = draft.tempSelectStore.filter(
                            (temp) => temp.id !== id
                        );
                    } else {
                        // rest member count calc
                        let restMember = member_count - falseMembers.length;

                        // true checked member push to tempSelectStore
                        draft.tempSelectStore[isAlready].members = [
                            ...trueMembers
                        ];

                        draft.tempSelectStore[isAlready].count = [
                            restMember,
                            member_count
                        ];
                    }

                    draft.tempSelectMemberCount -= 1;
                }
            }
        });
    },

    // 받는 분 불러오기 > 체크된 멤버 제거
    "user/REMOVE_CHECKED": (state, action) => {
        let count = 0;
        let { id, uid, name, shareType } = action.payload;

        return produce(state, (draft) => {
            draft.tempSelectStore = draft.tempSelectStore.filter(
                (item) => item.uid !== uid
            );

            if (shareType === 0) {
                if (!isZero(draft.myGroup.length)) {
                    draft.myGroup = draft.myGroup.map((props, index) => {
                        if (props.id === id) {
                            // get true count
                            count = draft.myGroup[index].members.filter(
                                ({ checked }) => checked
                            ).length;

                            draft.myGroup[index].members = draft.myGroup[
                                index
                            ].members.map((crops) => ({
                                ...crops,
                                checked: false
                            }));

                            return {
                                ...props,
                                checked: false
                            };
                        } else {
                            return props;
                        }
                    });
                }

                // sub tempSelectMemberCount
                draft.tempSelectMemberCount -= count;
            }

            if (shareType === 1) {
                if (!isZero(draft.sharedGroup.length)) {
                    draft.sharedGroup = draft.sharedGroup.map(
                        (props, index) => {
                            if (props.id === id) {
                                // get true count
                                count = draft.sharedGroup[index].members.filter(
                                    ({ checked }) => checked
                                ).length;

                                draft.sharedGroup[index].members =
                                    draft.sharedGroup[index].members.map(
                                        (crops) => ({
                                            ...crops,
                                            checked: false
                                        })
                                    );

                                return {
                                    ...props,
                                    checked: false
                                };
                            } else {
                                return props;
                            }
                        }
                    );

                    // sub tempSelectMemberCount
                    draft.tempSelectMemberCount -= count;
                }
            }

            if (shareType === 2) {
                if (!isZero(draft.studentGroup.length)) {
                    draft.studentGroup = draft.studentGroup.map(
                        (props, index) => {
                            if (props.id === id && props.group_name === name) {
                                // get true count
                                count = draft.studentGroup[
                                    index
                                ].members.filter(
                                    ({ checked }) => checked
                                ).length;

                                draft.studentGroup[index].members =
                                    draft.studentGroup[index].members.map(
                                        (crops) => ({
                                            ...crops,
                                            checked: false
                                        })
                                    );

                                return {
                                    ...props,
                                    checked: false
                                };
                            } else {
                                return props;
                            }
                        }
                    );

                    // sub tempSelectMemberCount
                    draft.tempSelectMemberCount -= count;
                }
            }

            if (shareType === 3) {
                if (!isZero(draft.teacherGroup.length)) {
                    draft.teacherGroup = draft.teacherGroup.map(
                        (props, index) => {
                            if (props.id === id && props.group_name === name) {
                                // get true count
                                count = draft.teacherGroup[
                                    index
                                ].members.filter(
                                    ({ checked }) => checked
                                ).length;

                                draft.teacherGroup[index].members =
                                    draft.teacherGroup[index].members.map(
                                        (crops) => ({
                                            ...crops,
                                            checked: false
                                        })
                                    );

                                return {
                                    ...props,
                                    checked: false
                                };
                            } else {
                                return props;
                            }
                        }
                    );

                    // sub tempSelectMemberCount
                    draft.tempSelectMemberCount -= count;
                }
            }
        });
    },

    // 받는 분 불러오기 > 체크된 그룹 내보내기
    "user/EXPORT_GROUP": (state) => {
        let cleanOverlap: Array<FReceiverType | ReceiverSearchType> = [];
        let merge = [...state.on_receivers, ...state.tempSelectStore];

        return produce(state, (draft) => {
            merge.forEach((item) => {
                if (hasOwnProperty(item, "members")) {
                    let isOverlap = cleanOverlap.findIndex(
                        ({ id, name }) => item.id === id && item.name === name
                    );

                    if (isOverlap === -1) {
                        cleanOverlap.push(item);
                    } else {
                        cleanOverlap[isOverlap] = item;
                    }
                } else {
                    cleanOverlap.push(item);
                }
            });

            draft.on_receivers = [...cleanOverlap];
        });
    },

    "user/USER_CLEANUP": (state, action) => {
        switch (action.payload) {
            case "after-send":
                return {
                    ...state,
                    on_receivers: [],
                    receivers: [],
                    myGroup: [],
                    sharedGroup: [],
                    studentGroup: [],
                    tempSelectStore: [],
                    tempSelectMemberCount: 0,
                    rloading: false
                };
            case "close-search":
                return { ...state, receivers: [], rloading: false };

            default:
                return state;
        }
    },

    "user/SET_USER": (state, action) => {
        return {
            ...state,
            userId: action.payload.user_id,
            user: {
                ...action.payload
            }
        };
    },

    "user/UPDATE_COUNT": (state, action) => {
        const { type, total } = action.payload;

        return {
            ...state,
            groupCount: [type, total]
        };
    },

    "user/FETCH_TOKEN_REQUEST": (state, action) => {
        return {
            ...state,
            loading: true,
            failure: false
        };
    },

    "user/FETCH_TOKEN_FAILURE": (state, action) => {
        return {
            ...state,
            loading: false,
            failure: true
        };
    },

    "user/FETCH_TOKEN_SUCCESS": (state, action) => {
        const data = action.payload;

        return {
            ...state,
            session: data.session,
            userId: data.user_id,
            user: {
                ...data.user_info
            },
            loading: false
        };
    },

    "user/FETCH_SEARCH_RECIEVER_REQUEST": (state, action) => {
        return {
            ...state,
            rloading: true,
            rempty: false,
            receivers: []
        };
    },

    "user/FETCH_SEARCH_RECIEVER_FAILURE": (state, action) => {
        return {
            ...state,
            rloading: false,
            receivers: []
        };
    },

    "user/FETCH_SEARCH_RECIEVER_SUCCESS": (state, action) => {
        const { data, type } = action.payload;

        if (isZero(data.length)) {
            return {
                ...state,
                rloading: false,
                rempty: true,
                receivers: [],
                rReceivers: []
            };
        }

        if (type === "message") {
            const apply: Array<FReceiverType> = [];

            data.forEach(
                ({ id, name, class_name, grade, number, ...phone }) => {
                    phoneType.forEach((target: PhoneType, index: number) => {
                        let _phone: string = phone[target].toString();

                        if (!isEmpty(_phone)) {
                            let _app = phone[`${target}_app` as PhoneAppType];
                            let _name = `${grade}학년 ${class_name}반 ${number}번 ${name} (${receiverType[index]})`;
                            _phone = phonemat(_phone);

                            let uuid = uniqueKey();

                            apply.push({
                                id,
                                app: _app,
                                name: _name,
                                phone: _phone,
                                number,
                                chipType: _app ? 3 : 2,
                                uid: uuid
                            });
                        }
                    });
                }
            );

            return {
                ...state,
                rloading: false,
                receivers: apply
            };
        }

        if (type === "rate") {
            // 무조건 chip type 은 4 이다.
            return {
                ...state,
                rloading: false,
                rReceivers: data
            };
        }

        return state;
    },

    "user/FETCH_CHECK_APP_INSTALL_FAILURE": (state, action) => {
        return state;
    },

    "user/FETCH_CHECK_APP_INSTALL_REQUEST": (state, action) => {
        return state;
    },

    "user/FETCH_CHECK_APP_INSTALL_SUCCESS": (state, action) => {
        let uuid = uniqueKey();
        let number = action.payload.number;
        let item: FReceiverType | ReceiverSearchType = action.payload.data[0]
            ? {
                  phone: number,
                  app: true,
                  chipType: 1,
                  name: "스쿨투게더",
                  uid: uuid
              }
            : {
                  phone: number,
                  app: false,
                  chipType: 0,
                  name: "메시지",
                  uid: uuid
              };

        return {
            ...state,
            on_receivers: [...state.on_receivers, item]
        };
    },

    // group
    "user/FETCH_CALL_GROUP_FAILURE": (state, action) => {
        return {
            ...state,
            pbloading: false
        };
    },

    "user/FETCH_CALL_GROUP_REQUEST": (state, action) => {
        return {
            ...state,
            pbloading: true
        };
    },

    "user/FETCH_CALL_GROUP_SUCCESS": (state, action) => {
        let { data, type } = action.payload;

        // check the group already checked
        let alreadyGroup = data.map(
            ({ id, members, member_count, ...props }) => {
                let isAlready = state.tempSelectStore.findIndex(
                    (temp) => temp.id === id
                );

                // temp에 이미 있는 그룹인지 확인
                if (isAlready !== -1) {
                    let trueCount = 0; // 나중에 member_count랑 비교해서 group checkbox 표시하기 위함

                    // members 체크
                    let alreadyMembers = members.map(({ id, ...crops }) => {
                        let isChecked =
                            state.tempSelectStore[isAlready].members.findIndex(
                                (member) => member.id === id
                            ) !== -1;

                        if (isChecked) trueCount++;

                        return {
                            ...crops,
                            id,
                            checked: isChecked
                        };
                    });

                    return {
                        ...props,
                        id,
                        member_count,
                        checked: trueCount === member_count,
                        members: alreadyMembers
                    };
                }
                return { ...props, id, member_count, members };
            }
        );

        return produce(state, (draft) => {
            if (type === 0) {
                draft.myGroup = alreadyGroup;
            }

            if (type === 1) {
                draft.sharedGroup = alreadyGroup;
            }

            if (type === 3) {
                draft.teacherGroup = alreadyGroup;
            }

            draft.pbloading = false;
        });
    },

    "school/FETCH_CALL_CLASS_GROUP_FAILURE": (state) => {
        return {
            ...state,
            pbloading: false
        };
    },

    "school/FETCH_CALL_CLASS_GROUP_REQUEST": (state) => {
        return {
            ...state,
            pbloading: true
        };
    },

    "school/FETCH_CALL_CLASS_GROUP_SUCCESS": (state, action) => {
        // class group transform like group type
        const { data, type } = action.payload;

        let receiver_type: ReceiverTypeKey = "Unknown";

        switch (type) {
            case "0":
                receiver_type = "Parent1";
                break;
            case "1":
                receiver_type = "Parent2";
                break;
            case "2":
                receiver_type = "Student";
                break;
            default:
                receiver_type = "Unknown";
        }

        let likeGroup: Array<GroupType> = data.map(
            ({ class_id, checked, class_name, students }): GroupType => {
                let trueCount = 0; // 나중에 member_count랑 비교해서 group checkbox 표시하기 위함
                let isAlready = state.tempSelectStore.findIndex(
                    (temp) => temp.id === class_id && temp.name === class_name
                );

                let alreadyMembers: Array<FReceiverType> = students.map(
                    ({
                        phone_app,
                        student_id,
                        name,
                        phone,
                        number
                    }): FReceiverType => {
                        let isChecked = false;

                        if (isAlready !== -1) {
                            isChecked =
                                state.tempSelectStore[
                                    isAlready
                                ].members.findIndex(
                                    (member) => member.id === student_id
                                ) !== -1;

                            if (isChecked) trueCount++;
                        }

                        return {
                            phone,
                            checked: isChecked,
                            app: phone_app,
                            id: student_id,
                            chipType: 4,
                            number,
                            receiver_type,
                            name,
                            uid: uniqueKey()
                        };
                    }
                );

                return {
                    checked: trueCount === students.length,
                    members: alreadyMembers,
                    constructor: "school",
                    share_type: 2,
                    id: class_id,
                    member_count: students.length,
                    group_name: class_name,
                    creation_date: ""
                };
            }
        );

        return produce(state, (draft) => {
            draft.studentGroup = likeGroup;
            draft.pbloading = false;
        });
    },

    "user/FETCH_APPROVAL_LIST_FAILURE": (state) => {
        return state;
    },

    "user/FETCH_APPROVAL_LIST_REQUEST": (state) => {
        return state;
    },

    "user/FETCH_APPROVAL_LIST_SUCCESS": (state, action) => {
        return {
            ...state,
            approvals: action.payload
        };
    },

    "user/FETCH_TEACHERS_FAILURE": (state) => {
        return {
            ...state,
            pbloading: false
        };
    },

    "user/FETCH_TEACHERS_REQUEST": (state) => {
        return {
            ...state,
            pbloading: true
        };
    },

    "user/FETCH_TEACHERS_SUCCESS": (state, action) => {
        const { data, isRate } = action.payload;

        let teachers: Array<FReceiverType> = [];
        let isAlready = state.tempSelectStore.findIndex(
            (temp) => temp.id === 0 && temp.name === "교직원"
        );

        return produce(state, (draft) => {
            if (!isRate) {
                teachers = data.map(
                    ({ phone_app, name, phone, teacher_idx }) => ({
                        id: teacher_idx,
                        app: phone_app,
                        name,
                        phone,
                        chipType: 4,
                        checked: false
                    })
                );

                if (isAlready !== -1) {
                    teachers = teachers.map(({ id, ...trops }) => {
                        let isChecked =
                            state.tempSelectStore[isAlready].members.findIndex(
                                (teacher) => teacher.id === id
                            ) !== -1;

                        return {
                            ...trops,
                            id,
                            checked: isChecked
                        };
                    });
                }
            }

            draft.pbloading = false;
            draft.teachers = isRate ? data : teachers;
        });
    },

    "user/FETCH_DEPARTS_TEACHER_FAILURE": (state) => {
        return {
            ...state,
            pbloading: false
        };
    },

    "user/FETCH_DEPARTS_TEACHER_REQUEST": (state) => {
        return {
            ...state,
            pbloading: true
        };
    },

    "user/FETCH_DEPARTS_TEACHER_SUCCESS": (state, action) => {
        return {
            ...state,
            pbloading: false
        };
    }
});
