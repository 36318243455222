import { call, put, select, takeEvery } from "redux-saga/effects";
import { default as approvalAPI } from "../../api/approval";
import { encodeQuery } from "../../lib/utils";
import { fetchApprovalAsync } from "../approval";

function* fetchApproval() {
    const state = yield select();

    try {
        const school_id = state.school.myschool.id;
        const { page, limit } = state.approval;

        const response = yield call(
            approvalAPI.getApproval,
            encodeQuery({
                school_id,
                page,
                limit
            })
        );

        yield put(fetchApprovalAsync.success(response));
    } catch (error) {
        yield put(fetchApprovalAsync.failure(error.message));
    }
}

export default [takeEvery(fetchApprovalAsync.request, fetchApproval)];
