import React, { useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import { PbInput } from ".";
import timeflow from "../lib/dr.strange";
import { InputChangeEvent } from "../lib/types";
import { InputIconType } from "./PbInput";

export interface PbDatePickerProps {
    type?: "date" | "datetime-local";
    id: string;
    value?: string | number;
    disabled?: boolean;
    min?: string;
    max?: string;
    name?: string;
    placeholder?: string;
    format?: string;
    onChange?: (value: string) => void;
}

const PbDatePickerStyled = styled.div<{ mode: string }>`
    position: relative;
    width: 100%;

    input[type="datetime-local"],
    input[type="date"] {
        ${(props) =>
            props.mode === "production" &&
            // props.mode === "development" &&
            css`
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                transform: translateY(50%);
                border: none;
                borde-radius: 5px;
                outline: none;
            `}
    }

    input[type="date"]::-moz-clear {
        display: none;
    }

    input[type="date"]::-webkit-clear {
        display: none;
    }

    label {
        position: relative;
        z-index: 2;
    }
`;

const PbDatePicker: React.SFC<PbDatePickerProps> = ({
    type = "datetime-local",
    id,
    placeholder = "발송일",
    format,
    min,
    max,
    name,
    value,
    disabled,
    onChange,
}) => {
    const dateRef = useRef<HTMLInputElement>(null);

    const fm =
        type === "date" ? "yyyy-MM-DD" : format ? format : "yyyy-MM-DD H:i:s";

    const dateInputHandler = () => {
        dateRef.current.focus();
    };

    const dateChangeHandler = useCallback(
        (ev: InputChangeEvent): void => {
            onChange(ev.target.value);
        },
        [onChange]
    );

    const resetHandler = () => {
        // setTimeout(() => {
        //     dateRef.current.blur();
        // }, 0);

        dateRef.current.value = null;
        onChange(null);
    };

    const date =
        window.platform === "ios" && type === "datetime-local"
            ? timeflow(value).setHour("-9").format(fm)
            : timeflow(value).format(fm);

    const icon: InputIconType = value
        ? {
              align: "right",
              prefix: "fal",
              name: "times",
              onMouseDown: resetHandler,
          }
        : {
              align: "right",
              prefix: "fal",
              name: "calendar-alt",
          };

    return (
        <PbDatePickerStyled mode={window.CURRENT_ENVIRONMENT}>
            <input
                type={type}
                id={id}
                name={name}
                value={value || ""}
                min={min}
                max={max}
                disabled={disabled}
                ref={dateRef}
                onChange={dateChangeHandler}
            />

            <label htmlFor={id} onMouseDown={dateInputHandler}>
                <PbInput
                    placeholder={placeholder}
                    value={value && date}
                    disabled={disabled}
                    readOnly
                    icon={icon}
                    onMouseDown={dateInputHandler}
                />
            </label>
        </PbDatePickerStyled>
    );
};

export default React.memo(PbDatePicker);
