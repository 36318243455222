import React from "react";
import styled from "styled-components";
import PbRadio from "./PbRadio";

export type RadioValue = {
    value: string;
    label: string;
};

export interface PbRadioGroupProps {
    radios: Array<RadioValue>;
    name?: string;
    match?: string;
    className?: string;
    onChange?: (value: string) => void;
}

const PbRadioGroupStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.color["skyblue-0"]};

    .pb-radio {
        flex: 1;
    }
`;

const PbRadioGroup: React.SFC<PbRadioGroupProps> = ({
    radios,
    name,
    match,
    className = "",
    onChange
}) => {
    const withRadio = radios.map(({ value, label }) => {
        let isValue = match === value;

        return (
            <PbRadio
                key={value}
                name={name}
                value={value}
                checked={isValue}
                onChange={onChange}
            >
                {label}
            </PbRadio>
        );
    });

    return (
        <PbRadioGroupStyled className={`pb-radio-group ${className}`}>
            {withRadio}
        </PbRadioGroupStyled>
    );
};

export default PbRadioGroup;
