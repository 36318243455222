import React from "react";
import styled from "styled-components";
import { FaIconProps } from "../common/FaIcon";

export interface PbDropdownItemProps {
    label?: string;
    icon?: FaIconProps;
    handler?: () => void;
}

export interface PbDropdownItemStyleProps {
    icon?: FaIconProps;
    disabled?: boolean;
}

const PbDropdownItemStyled = styled.div.attrs({})<PbDropdownItemStyleProps>`
    font-size: 1.3rem;
    cursor: pointer;
    padding: 10px 12px;
    text-align: center;

    .i-zone {
        padding-right: 1rem;
    }

    ${props => props.icon && props.theme.withFlex("flex-start", "center")}
    ${props => props.disabled && props.theme.disabled}
`;

const PbDropdownItem: React.SFC<PbDropdownItemProps &
    PbDropdownItemStyleProps> = ({ children, icon, disabled, handler }) => {
    return (
        <PbDropdownItemStyled icon={icon} disabled={disabled} onClick={handler}>
            {icon}
            <div>{children}</div>
        </PbDropdownItemStyled>
    );
};

export default PbDropdownItem;
